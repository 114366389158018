<!--
 * @Author: wangwei
 * @Date: 2020-12-28 16:06:28
 * @LastEditTime: 2023-01-17 20:11:04
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: In User Settings Edit
 * @FilePath: /java-pc/src/views/member/order/List.vue
-->
<template>
  <div id="scroll-container" @scroll="handleScroll2">
    <div class="finishList" v-if="tableData.goodsList.length !== 0">
      <div
        class="finishList_item"
        v-for="(item, index) in tableData.goodsList"
        :key="index"
      >
        <div class="finishList_item_title2">
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <div>
              <div style="display: flex; align-items: center">
                <p
                  style="font-size: 14px; font-weight: bold; margin-left: 10px"
                >
                  {{ item.supplierUserName }}
                </p>
              </div>
              <div style="display: flex">
                <p class="finishList_item_title_item">
                  <span class="fontColor">计划采购商品数量:</span
                  ><span class="bule">
                    {{ item.allGoodsCount }}
                  </span>
                </p>
                <p class="finishList_item_title_item">
                  <span class="fontColor">已报价采购商品数量:</span
                  ><span class="bule">
                    {{ item.allGoodsCount }}
                  </span>
                </p>

                <p class="finishList_item_title_item" style="color: red">
                  <span class="fontColor" style="color: red"
                    >已报价总金额:</span
                  >
                  {{ item.quoteAlMoney }}
                </p>

                <!--<p
                  class="finishList_item_title_item"
                  v-if="item.isChooseMySupplier == 1"
                >
                  <span class="fontColor">我的报价:</span
                  ><span class="yellowColor"
                    >{{ item.mySupplierName }} ￥{{ item.myPrice }}</span
                  >
                </p>-->
              </div>
            </div>
            
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
              "
            >
              <p class="select" v-if="item.isChooseMySupplier == 1">已选中</p>
              <p class="tag" v-if="item.isPlaceAnOrder">已下单</p>
              <p v-if="item.ischeckedAll" class="checkedAll" @click="selectAll(item)">选择全部</p>
              <!--<el-button size="mini" @click="handleCloseItem(item.needCode)">关闭</el-button>-->
            </div>
          </div>
        </div>

        <div class="OtherRecommendations">
          <el-carousel
            ref="cardShow"
            trigger="click"
            height="410px"
            :autoplay="false"
            arrow="always"
            initial-index="0"
            :motion-blur="true"
            :interval="5000"
            indicator-position="none"
            @change="
              handleCarouselChange(item.goodsList.pageNum, item.needCode, item)
            "
            v-if="item.goodsList.data.length !== 0"
          >
            <el-carousel-item
              v-for="Items in item.goodsList.totalPage"
              :key="Items"
              style="background: #fafafaff"
            >
              <!--<h3 class="small justify-center" text="2xl">-->
              <!--<i  @click="handleCarouselChange('prev',item.goodsList.pageNum, item.needCode, item)"  class="iconfont icon-ziyuan2 arrow-left" ></i>
              <i class="iconfont icon-ziyuan11 arrow-right"  @click="handleCarouselChange('next',item.goodsList.pageNum, item.needCode, item)"></i>-->
              <div class="goodsDiv">
                <div
                  class="goodsDiv_item"
                  v-for="(goodsItem, index) in item.goodsList.data"
                  :key="index"
                >
                  <img
                    :src="goodsItem.goodsImage"
                    alt=""
                    @click="
                      goGoodsDetail(goodsItem.sellingPrice, goodsItem.skuCode)
                    "
                  />
                  <p class="checked" v-if="goodsItem.isChooseGoods">已选中</p>
                  <div
                    style="
                      padding: 0 5px 10px 5px;
                      background-color: #ffffffff;
                      line-height: 1.5;
                      color:color: #999999FF;
                    "
                  >
                    <p
                      class="goodName"
                      @click="
                        goGoodsDetail(goodsItem.sellingPrice, goodsItem.skuCode)
                      "
                    >
                      {{ goodsItem.goodsNameJoin }}
                    </p>

                    <p>
                      <span>规格型号：</span>
                      <span v-if="goodsItem.goodsSpec && goodsItem.marque"
                        >{{ goodsItem.goodsSpec }},{{ goodsItem.marque }}</span
                      >
                      <span v-if="!goodsItem.goodsSpec">{{
                        goodsItem.marque
                      }}</span>
                    </p>
                    <p>
                      <span>采购数量：</span>
                      <span
                        >{{ goodsItem.goodsNum }},{{
                          goodsItem.goodsUnit
                        }}</span
                      >
                    </p>
                    <p>
                      <span>我的报价:</span>
                      {{ goodsItem.myPrice }}元
                    </p>
                    <p>
                      <span>需求日期:</span>
                      {{ goodsItem.needDate }}
                    </p>
                     <p>
                      <span>我的供应商:</span>
                      {{ goodsItem.mySupplierName }}
                    </p>
                    
                    <div
                      style="
                        margin-top: 3px;
                        display: flex;
                        align-items: center;
                      "
                    ><p>
                      <span>平台售价:</span>
                      <span class="redColor"
                        >￥{{ goodsItem.sellingPrice }}</span
                      >
                      </p><span
                        class="expirationDate"
                        v-if="
                          goodsItem.quoteTime !== '长期有效' &&
                          goodsItem.sellingPrice.split('').length <= 5
                        "
                      >
                        {{ goodsItem.quoteTime }}前有效
                      </span>
                      <span
                        class="expirationDate"
                        v-if="
                          goodsItem.quoteTime == '长期有效' &&
                          goodsItem.sellingPrice.split('').length <= 5
                        "
                      >
                        {{ goodsItem.quoteTime }}
                      </span>
                      <p
                        class="expirationDate2"
                        v-if="
                          goodsItem.quoteTime == '长期有效' &&
                          goodsItem.sellingPrice.split('').length5
                        "
                      >
                        {{ goodsItem.quoteTime }}
                      </p>
                    </div>
                    <p style="font-size: 10px">
                      <span>平台报价时间：</span>
                      <span>{{ goodsItem.pushTime }}</span>
                    </p>
                    <div
                      v-if="!goodsItem.checked"
                      class="selectGoods"
                      @click="
                        changeGoods2(
                          true,
                          goodsItem.needCode,
                          goodsItem.skuCode,
                          item,
                          goodsItem
                        )
                      "
                    >
                      选择
                    </div>
                    <div
                      v-if="goodsItem.checked"
                      class="selectGoods"
                      @click="
                        changeGoods2(
                          false,
                          goodsItem.needCode,
                          goodsItem.skuCode,
                          item,
                          goodsItem
                        )
                      "
                    >
                      取消
                    </div>
                  </div>
                  <div
                    :style="
                      goodsItem?.select
                        ? 'position: absolute; top:0;bottom:0;left:0;right:0;background:rgba(0,0,0,0.25)'
                        : ''
                    "
                  ></div>
                </div>
              </div>
              <!--</h3>-->
            </el-carousel-item>
          </el-carousel>

          <el-empty
            description="暂无其他推荐"
            v-if="item.goodsList.data.length == 0"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, getCurrentInstance, ref, onMounted } from "vue";

import {
  ElInput,
  ElMessage,
  ElMessageBox,
  ElTimeline,
  ElTimelineItem,
} from "element-plus";
// import { Search } from '@element-plus/icons-vue'
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { apiUrl } from "../../../utils/config";
import axios from "axios";
import SldCommonEmpty from "../../../components/SldCommonEmpty";
import MemberTitle from "../../../components/MemberTitle";
import { platform } from "@/utils/config";
//import { defineProps, defineEmits } from 'vue'
import download from "downloadjs";

export default {
  name: "SupplierView",
  components: {
    ElInput,
    ElTimeline,
    ElTimelineItem,
    SldCommonEmpty,
    MemberTitle,
  },
  props: ["SupplierViewData", "multipleSelection2","pageData","nextPageLoaded"],
  //emit: ["getSupplierViewData"],
  setup(props, { emit }) {
    // const balance = require("../../assets/buy/balance.png");
    const tableData = reactive({
      data: [],
      goodsList: props.SupplierViewData,
      needCount: {},
    });
    //const emit = defineEmits(['getSupplierViewData']);
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const memberInfo = reactive({ data: store.state.memberInfo });
    const { proxy } = getCurrentInstance();
    const addressVisibleFlag = ref(false);
    const L = proxy.$getCurLanguage();
    const cardShow = ref(null);
    const prevNeedCode = ref(null);
    const prevSupplierUserId = ref(null);
    const prevSkuCode = ref(null);
    //去商品详情
    const goGoodsDetail = (e) => {
      router.push({
        name: "GoodsDetail",
        query: {
          productId: e,
        },
      });
    };

    //返回
    const goback = () => {
      router.push({
        path: "/member/procurement/procurementPlanList",
        query: {
          //planCode: code,
          //state: state,
          pageNum: route.query.pageNum,
          pageSize: route.query.pageSize,
        },
      });
    };
    const handleCarouselChange = (pageNum, needCode, item) => {
      //console.log(cardShow.value[0],type,5656);
      //if(type=="next"){
      //  cardShow.value[0].next()
      //}else{
      //   cardShow.value[0].next()
      //}
      if (
        item.goodsList.totalPage != 1 &&
        pageNum !== item.goodsList.totalPage
      ) {
        //console.log(111);
        otherGoodsList2(pageNum + 1, needCode, item);
      } else if (item.goodsList.total <= 5) {
        //console.log(222);
        return;
      } else if (
        item.goodsList.totalPage != 1 &&
        pageNum == item.goodsList.totalPage
      ) {
        //console.log(333);
        otherGoodsList2(1, needCode, item);
      } else {
        otherGoodsList2(pageNum - 1, needCode, item);
      }
    };
    const changeGoods2 = (type, needCode, skuCode, item, goodsItem, val) => {
      //console.log(item, goodsItem);
      if (type) {
        if (!goodsItem.select) {
          if (goodsItem.goodsNum != 0) {
            //if (!prevNeedCode.value) {
              prevNeedCode.value = needCode;
              for (let i = 0; i < tableData.goodsList.length; i++) {
                for (
                  let j = 0;
                  j < tableData.goodsList[i].goodsList.data1.length;
                  j++
                ) {
                  //tableData.goodsList[i].goodsList.data1[j].checked = false;
                  if (
                    tableData.goodsList[i].goodsList.data1[j]?.needCode ==
                    needCode
                  ) {
                    tableData.goodsList[i].goodsList.data1[j].select = true;
                    // tableData.goodsList[i].goodsList.data =  tableData.goodsList[i].goodsList.data1;
                    //tableData.goodsList[i].goodsList.data1[j].checked = true;
                    tableData.goodsList[i].goodsList.data = tableData.goodsList[
                      i
                    ].goodsList.data1.slice(
                      (tableData.goodsList[i].goodsList.pageNum - 1) * 5,
                      tableData.goodsList[i].goodsList.pageNum * 5
                    );
                  }
                }
              }
            //}
            // else {
            //  for (let i = 0; i < tableData.goodsList.length; i++) {
            //    for (
            //      let j = 0;
            //      j < tableData.goodsList[i].goodsList.data1.length;
            //      j++
            //    ) {
            //      tableData.goodsList[i].goodsList.data1[j].checked = false;
            //      if (
            //        tableData.goodsList[i].goodsList.data1[j]?.needCode ==
            //        needCode
            //      ) {
            //        tableData.goodsList[i].goodsList.data1[j].select = true;
            //        // tableData.goodsList[i].goodsList.data =  tableData.goodsList[i].goodsList.data1;
            //        //tableData.goodsList[i].goodsList.data1[j].checked = true;
            //        tableData.goodsList[i].goodsList.data = tableData.goodsList[
            //          i
            //        ].goodsList.data1.slice(
            //          (tableData.goodsList[i].goodsList.pageNum - 1) * 5,
            //          tableData.goodsList[i].goodsList.pageNum * 5
            //        );
            //      }
            //    }
            //  }

            //  for (let i = 0; i < tableData.goodsList.length; i++) {
            //    for (
            //      let j = 0;
            //      j < tableData.goodsList[i].goodsList.data1.length;
            //      j++
            //    ) {
            //      // console.log( tableData.goodsList[i].goodsList.data1[j]?.needCode ==
            //      //    prevNeedCode.value);
            //      if (
            //        tableData.goodsList[i].goodsList.data1[j]?.needCode ==
            //        prevNeedCode.value
            //      ) {
            //        //console.log(2222);

            //        if (item.supplierUserId == prevSupplierUserId.value) {
            //          tableData.goodsList[i].goodsList.data1[j].select = false;
            //          tableData.goodsList[i].goodsList.data1[j].checked = false;
            //          // tableData.goodsList[i].goodsList.data =  tableData.goodsList[i].goodsList.data1;
            //          //tableData.goodsList[i].goodsList.data1[j].checked = true;
            //          tableData.goodsList[i].goodsList.data =
            //            tableData.goodsList[i].goodsList.data1.slice(
            //              (tableData.goodsList[i].goodsList.pageNum - 1) * 5,
            //              tableData.goodsList[i].goodsList.pageNum * 5
            //            );
            //            // prevNeedCode.value = null;
            //            //prevSupplierUserId.value = null;
            //            //prevSkuCode.value = null;
            //        } 
            //        else {
            //          if (
            //            tableData.goodsList[i].goodsList.data1[j]?.needCode ==
            //            prevNeedCode.value
            //          ) {
            //            tableData.goodsList[i].goodsList.data1[
            //                j
            //              ].checked = false;
            //              tableData.goodsList[i].goodsList.data1[
            //                j
            //              ].select = true;
            //          }
            //           if (
            //              tableData.goodsList[i].goodsList.data1[j]?.skuCode ==
            //              prevSkuCode.value
            //            ) {
            //              //console.log(8888);
            //              tableData.goodsList[i].goodsList.data1[
            //                j
            //              ].checked = true;
            //             tableData.goodsList[i].goodsList.data1[
            //                j
            //              ].select = false;
            //              prevNeedCode.value = null;
            //            prevSupplierUserId.value = null;
            //            prevSkuCode.value = null;
            //            }
            //            //if (
            //            //  !(
            //            //    tableData.goodsList[i].goodsList.data1[j]
            //            //      ?.needCode == prevNeedCode.value
            //            //  )
            //            //) {
            //            //  tableData.goodsList[i].goodsList.data1[
            //            //    j
            //            //  ].checked = true;
            //            //  tableData.goodsList[i].goodsList.data1[
            //            //    j
            //            //  ].select = true;
            //            //}

            //            // tableData.goodsList[i].goodsList.data =  tableData.goodsList[i].goodsList.data1;
            //            //tableData.goodsList[i].goodsList.data1[j].checked = true;
            //            tableData.goodsList[i].goodsList.data =
            //              tableData.goodsList[i].goodsList.data1.slice(
            //                (tableData.goodsList[i].goodsList.pageNum - 1) * 5,
            //                tableData.goodsList[i].goodsList.pageNum * 5
            //              );
                        
                      
            //        }
            //      }
            //    }
            //  }
            //}
            goodsItem.select = false;
            goodsItem.checked = true;
        
            //if (goodsItem.isChoose) {
           
              //let arr = props.multipleSelection2.data.filter(
              //  (it) => it.supplierUserId != prevSupplierUserId.value
              //);
              //props.multipleSelection2.data = arr;
              props.multipleSelection2.data.push({ ...item, ...goodsItem });

            //}
            prevNeedCode.value = needCode;
            prevSupplierUserId.value = item.supplierUserId;
            prevSkuCode.value = goodsItem.skuCode;
          } else {
            ElMessage({
              message: "商品采购数量为0，请您核对是否已下过订单",
              type: "warning",
            });
          }
        }
      } else {
        for (let i = 0; i < tableData.goodsList.length; i++) {
          
          for (
            let j = 0;
            j < tableData.goodsList[i].goodsList.data1.length;
            j++
          ) {
            tableData.goodsList[i].goodsList.data1[j].checked = false;
            // tableData.goodsList[i].goodsList.data1[j].checked=true;
            if (
              tableData.goodsList[i].goodsList.data1[j]?.needCode == needCode
            ) {
              tableData.goodsList[i].goodsList.data1[j].select = false;
              // tableData.goodsList[i].goodsList.data =  tableData.goodsList[i].goodsList.data1;
              tableData.goodsList[i].goodsList.data = tableData.goodsList[
                i
              ].goodsList.data1.slice(
                (tableData.goodsList[i].goodsList.pageNum - 1) * 5,
                tableData.goodsList[i].goodsList.pageNum * 5
              );
            }
          }
        }
        goodsItem.checked = false;
        let arr = props.multipleSelection2.data.filter(
          (it) => it.skuCode != goodsItem.skuCode
        );
        props.multipleSelection2.data = arr;

        prevNeedCode.value = null;
      }
    };
    const otherGoodsList2 = (pageNum, needCode, item) => {
      if (item.goodsList.data1) {
        item.goodsList.data = item.goodsList.data1;
        item.goodsList.pageNum = pageNum;
        item.goodsList.pageSize = 5;

        item.goodsList.totalPage =
          item.goodsList.data1.length % 5 === 0
            ? item.goodsList.data1.length / 5
            : Math.floor(item.goodsList.data1.length / 5 + 1);
        console.log(pageNum);
        if (pageNum != 1) {
          item.goodsList.data = item.goodsList.data.slice(
            (pageNum - 1) * 5,
            pageNum * 5
          );
          console.log((pageNum - 1) * 5, pageNum * 5, item.goodsList.totalPage);
        } else {
          item.goodsList.data = item.goodsList.data.slice(0, 5);
        }
        item.goodsList.total = item.goodsList.data1.length;
        //if (res.data.total <= 5) {
        //  item.goodsList.total = 1;
        //} else {
        //  //if (Number.isInteger(res.data.total / 6)) {
        //  //  item.goodsList.total = res.data.total / 6;
        //  //} else {
        //  //  item.goodsList.total = Math.floor(res.data.total / 6);
        //  //}
        //}
      } else {
        item.goodsList.data = [];
      }
    };
    const selectAll=(item)=>{

    }
    const debounce = (fn, delay) => {
      let timeout;
      return function () {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          fn.apply(this, arguments);
        }, delay);
      };
    };
    const handleScroll2=(event)=>{
      const { scrollTop, scrollHeight, clientHeight } = event.target;
      console.log(scrollTop, scrollHeight, clientHeight);
        if (
          scrollHeight - (scrollTop + clientHeight) < 20 &&
          props.pageData.totalPage !== props.pageData.pageNum
        ) {
          if (!props.nextPageLoaded.value) {
            console.log(4545);
            console.log(!props.nextPageLoaded);
            // 将标记设置为已加载
            //props.nextPageLoaded = true;
            // 当距离底部5px以内滚动时加载
            //multipleSelection.value=[]
            props.pageData.pageNum = Number(props.pageData.pageNum) + 1;
            //let data = [];
            //activeName.value == 2
            //  ? (data = [...tableData.goodsList])
            //  : (data = [...closeData.goodsList]);
            //console.log(context.emit('getSupplierViewData'))
            console.log(getSupplierViewData);
            emit("getSupplierViewData",props.SupplierViewData)
          }
    //  proxy
    //    .$get("api/shopp/purchase/plan/planDetailSupplierShowPage", {
    //      planCode: route.query.planCode,
    //      ...props.pageData,
    //      //state: 0,
    //    })
    //    .then((res) => {
    //      if (res.code == 200) {
    //        props.nextPageLoaded = false;
    //        console.log(res.data);
    //        props.pageData.pageNum = res.data.pageNum;
    //        props.pageData.pageSize = res.data.pageSize;
    //        props.pageData.total = res.data.total;
    //        props.pageData.totalPage = res.data.totalPage;
    //        console.log(props.SupplierViewData.data);
    //         if(props.SupplierViewData.data.length!=0){
    //          props.SupplierViewData.data = [...res.data.records,...props.SupplierViewData.data];
    //        }else{
    //          props.SupplierViewData.data=res.data.records
    //        }
    //        //payData.data = res.data;
    //        console.log(props.SupplierViewData.data);
    //        props.SupplierViewData.data.forEach((item) => {
    //          item.goodsList = {
    //            data: [],
    //            data1:[],
    //            pageNum: 1,
    //            pageSize: 5,
    //            total: 1,
    //            totalPage: 1,
    //          };
    //          item.ischeckedAll=false
    //          proxy
    //            .$get(
    //              "api/shopp/purchase/plan/planDetailSupplierShowGoodsPage",
    //              {
    //                planCode: route.query.planCode,
    //                supplierId: item.supplierUserId,
    //                //pageNum: item.goodsList.pageNum,
    //                //pageSize: item.goodsList.pageSize,
    //              }
    //            )
    //            .then((res) => {
    //              if (res.code == 200) {
    //                if (res.data) {
    //                   item.goodsList.data1 = res.data;
    //                  item.goodsList.data = res.data;

    //                //  let len =  res.data.length;
    //                //  const n = 5;
    //                //  const Num =len%5 == 0 ? len/5 : Math.floor(len / 5 + 1);
    //                //  console.log(Num);
    //                //  for(let i =0;i<Num;i++){
    //                //    const arr = res.data.slice(i*n,i*n+n)
                        
    //                //    item.goodsList.data.push(arr)
                         
    //                //    }
    //                item.ischeckedAll=item.goodsList.data1.every(item=>item.isChoose==1)
    //                console.log(item.ischeckedAll);

    //                console.log(item.goodsList.data,8989);
                     
    //                  item.goodsList.pageNum =1;
    //                  item.goodsList.pageSize = 5;
    //                  item.goodsList.data=item.goodsList.data
                      
    //                  item.goodsList.totalPage = res.data1.length%5===0?res.data1.length /5 :Math.floor((res.data1.length /5)+ 1);
    //                  item.goodsList.data=item.goodsList.data1.slice(0,5)
    //                  item.goodsList.total = item.goodsList.data.length;
    //                } else {
    //                  item.goodsList.data = [];
    //                }
    //              } else {
    //                ElMessage(res.msg);
    //              }
    //            })
    //            .catch(() => {
    //              //ElMessage(res.msg);
    //            });
    //        });
    //        tableData.goodsList = [...tableData.goodsList];
    //      }
    //    });
    //};
          // console.log(emit("getSupplierViewData"));

            //if(ischeckedAll.value=true){
            //   tableData.goodsList.forEach((item) => {
            //  if(item.isChoose==true){
            //      item.ischecked ==true
            //      ischeckedAll.value = true
            //      multipleSelection.value.push(item)
            //  }

            //});
            //}

          //  if (multipleSelection.value) {
          //    let total = 0;

          //    for (const item of multipleSelection.value) {
          //      const { goodsPrice, goodsNum } = item.needSku;
          //      total +=
          //        Math.ceil((goodsPrice * 100 * (goodsNum * 10000)) / 1000) /
          //        1000;
          //    }

          //    AllMoney.value = total.toFixed(2);
          //  }
          //}
        //}
          }
       
      
    }
    onMounted(() => {
      //  getDetailData();
      //  getDetailGoodsData();
    });
    const tableHeaderColor = (data) => {
      console.log(data, 34343);
      return {
        background: "#F6F6F6FF",
        color: "#333333FF",
        fontWeight: "bold",
      };
    };
    return {
      L,
      //  param,
      //  tableData,
      //  fits,
      //  multipleTableRef,
      //  multipleSelection,
      //  descImgVisible,
      //  AllMoney,
      //  OtherList,
      //  srcList,
      //  previewImg,
      //  goback,
      //  closeOrder,
      //  handleEdit,
      //  handleCloseItem,
      //  addressVisibleFlag,
      //  addAddress,
      //  refreshAddress,
      //  close,
      //  type,
      //  show_select_address,
      //  confirmChangeAddress,
      //  selectAddress,
      //  changeAddress,
      //  tableHeaderColor,
      //  payData,
      //  orderBelongsVal,
      //  selectDate,
      //  activeIndex,
      //  not_select,
      //  selected,
      //  invoice_selected,
      //  current_address_index,
      //  changeAddress,
      //  address_list,
      //  getAddressList,
      //  getDetailData,
      //  handleChange,
      //  goGoodsDetail,
      //  //toggleSelection
      //  dialogVisible,
      //  debounce,
      //  validateNumber,
      //  rules,
      //  topTable,
      //  getDetailGoodsData,
      //  onSubmit,
      //  ruleFormRef,
      //  mySupplierNameHandleChange,
      //  route,
      //  router,
      //  platform,
      //SupplierViewData,
      //handleScroll,
      tableData,
      handleCarouselChange,
      otherGoodsList2,
      changeGoods2,
      cardShow,
      prevNeedCode,
      prevSupplierUserId,
      prevSkuCode,
      selectAll,props,
      handleScroll2,
      props,

    };
  },
};
</script>
<style lang="scss" scoped>
@import "../../../style/procuremmentPlanDetail.scss";

.clickBtn {
  color: $colorMain;
  cursor: pointer;
}
.el-dialog .el-dialog__body .desc-image__preview {
  .el-image {
    width: 148px;
    height: 148px;
  }
  .active {
    margin-left: 10px;
  }
}
.desc-clss {
  white-space: nowrap; /* 确保文本在一行内显示 */
  overflow: hidden; /* 隐藏溢出容器的文本 */
  text-overflow: ellipsis; /* 使用省略号表示文本溢出 */
  max-width: 100px; /* 设置最大宽度 */
}
.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #0e3177 !important;
  border-color: #0e3177 !important;
}
.el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #0e3177 !important;
  border-color: #0e3177 !important;
}
.sld_member_main_content[data-v-61d93b18] {
  background: #f8f8f8;
}
.sld_order_list .container {
  width: 1207px;
}
.sld_order_list {
  /*width: 1210px;*/
  width: 100%;
  background: #fff;
}
.el-pager li.active {
  color: $colorMain;
  cursor: default;
}

.el-pager li:hover {
  color: $colorMain;
}

.el-select-dropdown__item.selected {
  color: $colorMain;
}

.sld_order_list {
  color: #000;
  .sld_List {
    color: #000;
  }
  /*.el-dialog__header {
    background-color: $colorMain;
    padding: 11px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .el-dialog__title {
      color: #fff;
      font-size: 14px;
    }
    .el-dialog__headerbtn {
      top: auto;
    }
    .el-dialog__headerbtn .el-dialog__close {
      color: #fff !important;
      font-size: 18px;
    }
  }
  .el-dialog {
    overflow: hidden;
    border-radius: 4px;
  }
  .el-dialog__footer {
    display: flex;
    // justify-content: center;
    align-items: center;
  }*/
  /*.aft_btn:hover {
    background-color: rgba($color: #f30213, $alpha: 0.2);
    color: #f30213;
  }*/

  .aft_btn:focus {
    background-color: #fff;
    color: #333333;
    border-color: #dcdfe6;
  }
  .dia_btn {
    .el-button {
      width: 134px;
      height: 36px;
    }
  }
  .downLoad_contract {
    width: 150px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 1px solid #e8e8f1;
    border-radius: 5px;
    cursor: pointer;
  }
  .downLoad_contract:hover {
    border: 1px solid $colorMain;
  }
}
.container {
  .bootom-footer {
    text-align: right;
  }
  .bootom-footer button:first-child {
    margin-right: 10px;
  }
  // .el-input {
  //   width: 300px;
  // }
  .unit {
    font-size: 18px;
    margin-left: 10px;
  }
}
.demo-form-inline .el-input {
  --el-input-width: 220px;
}
.el-table thead {
  color: #5f5f60;
}
.el-table {
  color: #2b2b2c;
}
.result {
  height: 100%;
  margin-top: 30px;
  text-align: center;
  padding: 0px 20px 50px 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 16px;
  margin-bottom: 40px;
}
.child {
  width: 100%;
  display: flex;
  align-content: center;
  .goodsMassage {
    margin-left: 10px;
    display: flex;
    justify-content: space-between;
    width: 92%;
    align-items: center;
    .operationContent {
      width: 100%;
      line-height: 2;
      .goodsContent {
        display: flex;
        p {
          width: 30%;
          line-height: 2.5;
        }
      }
    }
  }
  .goodsMassages {
    margin-left: 10px;
    display: flex;
    justify-content: space-between;
    width: 90%;
    /*align-items: ;*/
    .operationContent {
      width: 100%;
      line-height: 2;
      .goodsContent {
        display: flex;
        p {
          width: 30%;
          line-height: 2.5;
        }
      }
    }
  }
}
.showContent {
  margin-top: 20px;
  padding: 10px;
  background: rgb(250, 247, 247);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
}
.receive_info {
  border: 1px solid #dddddd;
  border-radius: 2px;
  margin-bottom: 30px;
  .pre_message_title,
  .receive_info_title {
    height: 40px;
    line-height: 40px;
    font-size: 12px;
    padding-left: 20px;
    background-color: #f8f8f8;
    color: #666666;
  }
  .receive_info_content {
    height: 159px;
    padding: 0 40px;
  }

  .pre_message_info {
    padding: 10px 40px;
    .tag_pre {
      display: inline-block;
      line-height: 25px;
      text-align: right;
      font-weight: 400;
      margin-right: 10px;
      font-size: 14px;
      color: #333333;
      display: flex;
      min-width: 100px;
      max-width: 200px;
      justify-content: flex-end;
    }

    strong {
      font-size: 14px;
      color: $colorMain;
      margin-right: 3px;
    }
  }
  .content_left {
    width: 785px;
    /*border-right: 1px solid #dddddd;*/
    .member {
      font-size: 14px;
    }
    .address_detail {
      width: 700px;
    }
    span:not(:first-child) {
      font-size: 14px;
      line-height: 14px;
      margin-top: 22px;
    }
  }
  .content_right {
    width: 370px;
    font-size: 14px;
    border-left: 1px solid #dddddd;
    padding-left: 10px;
    .replace_address {
      color: $colorMain2;
      line-height: 14px;
    }
  }
  .add_address {
    width: 100px;
    height: 30px;
    background-color: $colorMain;
    color: white;
    line-height: 30px;
    text-align: center;
    margin-top: 29px;
    border-radius: 3px;
  }
  .nomartop {
    margin-top: 0;
  }
}
.select_address_width {
  width: 500px !important;
}
.overflow_ellipsis_clamp2 {
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: break-all;

  line-height: 19px;
}
.out_stock_dialog {
  width: 460px;
  margin: 0 auto;
  height: 330px;
  overflow-y: scroll;
  .good_item {
    font-size: 14px;

    img {
      width: 80px;
      height: 80px;
    }
    .good_info {
      margin-left: 10px;
    }
    .good_name {
      width: 320px;
      color: #333333;
      line-height: 14px;
      margin-top: 10px;
      display: inline-block;
    }
    .spec_num {
      margin-top: 26px;
      .good_spec {
        color: #999999;
      }
      .good_num {
        float: right;
        color: #333333;
      }
    }
  }
}
.address_con {
  height: 330px;
  overflow-y: scroll;
  scrollbar-width: none !important;
  -ms-overflow-style: none !important;
  .address_item {
    &:not(:first-child) {
      margin-top: 20px;
    }
    width: 458px;
    //    height: 104px;
    box-sizing: border-box;
    border: 1px solid #dfdfdf;
    position: relative;
    padding: 20px;
    span,
    div:not(:first-child) {
      margin-top: 12px;
    }
    .address_text {
      display: flex;
      width: 400px;
    }
    .selected {
      width: 14px;
      height: 14px;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  .select {
    border: 1px solid $colorMain2;
  }
}
.red {
  background-color: $colorMain;
  color: white;
}
.remove {
  width: 120px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 3px;
  background-color: $colorMain;
  margin-left: 10px;
  color: white;
}
.replace_address {
  color: $colorMain2;
  line-height: 14px;
}
.Submit {
  background: #0e3177;
  width: 70.5px;
  height: 38.5px;
  cursor: pointer;
  border-radius: 4px;
  text-align: center;
  color: #fff;
  margin-left: 10px;
  line-height: 38.5px;
  font-size: 14px;
}
.tabs {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  color: #fff;
  background: #fff;
  border: 1px solid #cfcfcfff;
  margin-right: 10px;
  font-size: 14px;
}
.btnActive {
  background: #0e3177;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  color: #fff;
  margin-right: 10px;
  font-size: 14px;
}
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e3e2e2;
  padding: 13px 20px 0px 15px;
  background-color: $colorMain;
  color: white;
  padding-bottom: 10px;
}
/*::v-deep .el-dialog__header {
  padding: 0px;
  padding-bottom: 0px;
}*/
.modalTitle {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  img {
    width: 27px;
    height: 24px;
    /*margin-right: 5px;*/
  }
}
.Clear {
  width: 76px;
  height: 35px;
  border-radius: 4px;
  text-align: center;
  color: #5e5858;
  line-height: 35px;
  font-size: 14px;
  border: 1px solid #ccc;
  cursor: pointer;
}
.Submit2 {
  background: #0e3177;
  width: 76px;
  height: 35px;
  border-radius: 4px;
  text-align: center;
  color: #fff;
  margin-left: 10px;
  line-height: 35px;
  font-size: 14px;
  cursor: pointer;
}
.invoice_con {
  height: 100px;
  display: flex;
  justify-content: center;
  .activeLt {
    margin-left: 20px;
  }
  .no_select_invoice {
    cursor: pointer;
    img {
      width: 18px;
      height: 18px;
    }
    span {
      font-size: 14px;
      line-height: 14px;
      margin-left: 10px;
    }
    .invoice_info {
      margin-left: 50px;
      border-left: 1px solid #dddddd;
      span {
        font-size: 14px;
        line-height: 14px;
        margin-left: 50px;
      }
      .choose {
        color: #257bfd;
        cursor: pointer;
      }
    }
    .block {
      margin-left: 10px;
      ::v-deep .el-date-editor.el-input {
        width: 160px;
      }
      .el-input__inner {
        padding-left: 40px;
      }
    }
    .date_box {
      margin-left: 10px;
      ::v-deep .el-date-editor.el-input {
        width: 160px;
      }
    }
  }
}
</style>