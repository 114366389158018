<!--
 * @Author: wangwei
 * @Date: 2020-12-28 16:06:28
 * @LastEditTime: 2023-01-17 20:11:04
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: In User Settings Edit
 * @FilePath: /java-pc/src/views/member/order/List.vue
-->
<template>
    <div>
        <div class="sld_order_list">
            <MemberTitle :memberTitle="L['采购计划详情']"></MemberTitle>
            <div class="container">
                <div style="padding: 0 20px 10px 20px; border: 1px solid #eeeeeeff">
                    <div
                        style="
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            border-bottom: 1px solid #eeeeeeff;
                            padding-bottom: 10px;
                        "
                    >
                        <h3 style="margin-top: 10px; font-size: 16px">
                            {{ route.query.type == 0 ? "单次" : "月度" }}采购计划详情
                        </h3>

                        <div style="margin-top: 12px">
                            <el-button @click="closeOrder" v-if="topTable.data.state !== 3">关闭计划</el-button>
                            <el-button @click="handleEdit" v-if="topTable.data.state !== 3 && topTable.data.state !== 4"
                                >编辑</el-button
                            >
                            <el-button @click="goback">返回</el-button>
                        </div>
                    </div>
                    <!--顶部信息start-->
                    <div class="sld_order_navs">
                        <div class="sld_order_nav_item addBorder distance">
                            <p>
                                <span class="fontColor">创建时间 :</span>
                                {{ topTable.data.createTime }}
                            </p>
                            <p class="stateStyle">
                                <!-- <span
                                    v-if="platform == 2 && topTable.data.state != 3"
                                    :class="
                                        topTable.data.state == 0
                                            ? 'yellow'
                                            : topTable.data.state == 1
                                            ? 'green'
                                            : topTable.data.state == 2
                                            ? 'bule'
                                            : topTable.data.state == 3
                                            ? 'gray'
                                            : ''
                                    "
                                    >大石集采</span
                                > -->
                                <span class="yellow" v-if="topTable.data.state == 0">待报价</span>
                                <span class="green" v-if="topTable.data.state == 1">报价中</span>
                                <span class="bule" v-if="topTable.data.state == 2">已报价</span>
                                <span class="gray" v-if="topTable.data.state == 3">已关闭</span>
                                <span class="yellow" v-if="topTable.data.state == 4">审批中</span>
                            </p>
                            <p v-if="platform == 2">
                                <span
                                    class="fontColor"
                                    v-if="
                                        topTable.data.firmQuoteTime &&
                                        store.state.memberInfo.isNeedMyPrice == 1 &&
                                        route.query.type == 0
                                    "
                                    >企业报价时间:</span
                                >{{ topTable.data.firmQuoteTime }}
                            </p>
                            <p
                                class="UncompletedQuotation"
                                v-if="
                                    !topTable.data.firmQuoteTime &&
                                    platform == 2 &&
                                    store.state.memberInfo.isNeedMyPrice == 1 &&
                                    topTable.data.state != 3 &&
                                    route.query.type == 0
                                "
                            >
                                企业未填我的报价
                            </p>
                        </div>
                        <div class="sld_order_nav_item addBorder distance3">
                            <div class="item_title">
                                <h3>基本信息</h3>
                                <div class="item_type">
                                    <p v-if="topTable.data.type == 0">单次采购</p>

                                    <p v-if="topTable.data.type == 1">月度采购</p>
                                </div>
                            </div>
                            <p>
                                <span class="fontColor">采购单号:</span><span>{{ topTable.data.memberPlanCode }}</span>
                                <img v-if="topTable.data.source == 0" src="@/assets/yongyou.png" />
                                <img v-if="topTable.data.source == 1" src="@/assets/dashiLogo.png" />

                                <img v-if="topTable.data.source == 2" src="@/assets/dashijicai@2x.png" />
                            </p>
                            <p>
                                <span class="fontColor" v-if="topTable.data.source == 0">请购单号:</span
                                ><span>{{ topTable.data.bizBillCode }}</span>
                            </p>
                            <p>
                                <span class="fontColor">创建人:</span
                                ><span style="margin-right: 10px"> {{ topTable.data.prayName }} </span
                                ><span>
                                    {{ topTable.data.operator }}
                                </span>
                            </p>
                            <p>
                                <span class="fontColor">创建人部门:</span
                                ><span v-if="topTable.data.deptName">
                                    {{ topTable.data.deptName }}
                                </span>
                                <span v-if="!topTable.data.deptName">
                                    {{ topTable.data.corpName }}
                                </span>
                            </p>
                            <p style="display: flex">
                                <span class="fontColor">备注:</span>
                                <span
                                    style="max-width: 550px; display: inline-block"
                                    v-if="topTable.data?.desc?.split('').length < 200"
                                    >{{ topTable.data.desc ? topTable.data.desc : "无" }}</span
                                >

                                <el-tooltip
                                    v-else
                                    class="box-goodsItem"
                                    effect="dark"
                                    :content="topTable.data?.desc"
                                    placement="top-start"
                                >
                                    <span class="text-ellipsisss" style="max-width: 350px">{{
                                        topTable.data?.desc
                                    }}</span>
                                </el-tooltip>
                            </p>
                        </div>
                        <div class="sld_order_nav_item distance2">
                            <div class="item_title">
                                <h3>收货信息</h3>
                                <div class="item_title_right">
                                    <span class="white" @click="changeAddress">更换地址</span>
                                    <span class="addAddress" @click="addAddress">新建地址</span>
                                </div>
                            </div>

                            <div class="content_left flex_column_between_start">
                                <p>
                                    <span class="fontColor">收货人：</span
                                    ><span>{{ topTable.data.receivingName }}</span>
                                </p>

                                <p>
                                    <span class="fontColor">{{ L["联系方式"] }}：</span
                                    >{{ topTable.data.receivingMobile }}
                                </p>
                                <p>
                                    <span class="fontColor addressStyle"> {{ L["收货地址"] }}:</span
                                    ><span>{{ topTable.data.receivingAddress }}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <!--顶部信息end-->
                </div>

                <div
                    style="
                        padding: 0 20px 15px 20px;
                        border: 1px solid #eeeeeeff;
                        margin-top: 20px;
                        margin-bottom: 24px;
                    "
                >
                    <div class="goodsList">
                        <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
                            <!--待报价商品start-->
                            <el-tab-pane :label="'待报价商品' + notQuoteCount" name="0">
                                <div id="scroll-container2" @scroll="handleScroll">
                                    <div class="waitList" v-if="tableData.data.length !== 0">
                                        <div v-for="(item, index) in tableData.data" :key="index">
                                            <!--<div
                        class="finishList_item_title"
                        v-if="item.goodsName.split('').length <= 20"
                      >
                        <div class="lt" >
                          <p
                            style="
                              margin-top: 2px;
                              font-size: 14px;
                              font-weight: bold;
                              margin-left: 10px;
                            white-space: nowrap;
                            "
                          >
                            {{ item.goodsName }}
                          </p>

                          <p
                            class="finishList_item_title_item"
                            v-if="item.goodsSpec"
                          >
                            <span class="fontColor">规格型号:</span>
                            <span
                              style="max-width: 350px; display: inline-block"
                              >{{ item.goodsSpec }},{{ item.goodsMarque }}</span
                            >
                          </p>
                          <p
                            class="finishList_item_title_item"
                            v-if="!item.goodsSpec"
                          >
                            <span
                              class="fontColor"
                              style="max-width: 200px; display: inline-block"
                              >规格型号:</span
                            >
                            <span
                              style="max-width: 300px; display: inline-block"
                              >{{ item.goodsMarque }}</span
                            >
                          </p>
                          <p class="finishList_item_title_item">
                            <span class="fontColor">采购数量:</span
                            ><span class="bule">
                              {{ item.purchaseNum }}{{ item.goodsUnit }}
                            </span>
                          </p>
                          <p class="finishList_item_title_item">
                            <span class="fontColor">需求日期:</span>
                            <span>{{ item.needDate }}</span>
                          </p>
                          <div class="finishList_item_title_item">
                            <span
                              class="fontColor"
                              style="max-width: 100px; display: inline-block"
                              >备注:</span
                            >
                            <div class="flex_row_center_center">
                              <span
                                class="desc-clss"
                                :title="item.desc"
                                style="max-width: 200px; display: inline-block"
                                >{{ item.desc ? item.desc : "无" }}</span
                              >
                              <span
                                class="imgBtn"
                                v-if="item.purchasePic"
                                @click="previewImg(item.purchasePic)"
                                >查看图片</span
                              >
                            </div>
                          </div>
                          <p
                            class="finishList_item_title_item"
                            v-if="
                              platform == 2 &&
                              item.mySupplierName &&
                              item.myPrice &&
                              store.state.memberInfo.isNeedMyPrice == 1
                            "
                          >
                            <span class="fontColor">我的报价:</span
                            ><span
                              class="bule"
                              style="max-width: 250px; display: inline-block"
                              >{{ item.mySupplierName }}
                            </span>
                            <span span class="bule">￥{{ item.myPrice }} </span>
                          </p>
                          <p
                            class="finishList_item_title_item"
                            v-if="item.choiceMemberName"
                          >
                            <span
                              class="fontColor"
                              style="color: #e99f27; margin-right: 0px"
                              >已选择：</span
                            >
                            <span
                              style="
                                max-width: 250px;
                                display: inline-block;
                                color: #e99f27;
                                margin-right: 10px;
                              "
                              >{{ item.choiceMemberName }}
                            </span>

                            <span
                              class="fontColor"
                              style="color: #e99f27; margin-right: 5px"
                              >单价</span
                            >
                            <span
                              style="
                                max-width: 250px;
                                display: inline-block;
                                color: #e99f27;
                              "
                              >{{ item.choicePrice }}
                            </span>
                          </p>
                        </div>
                        <div class="rt">
                          <p class="tag" v-if="item.isPlaceAnOrder == 1">
                            已下单
                          </p>
                          <p
                            class="viewDetailsss"
                            style="margin-right: 10px"
                            @click="getmySupplierList(item.memberNeedCode, [])"
                            v-if="
                              store.state.memberInfo.isNeedMyPrice == 0 &&
                              route.query.type == 0
                            "
                          >
                            邀请我的供应商
                          </p>

                          <el-button
                            size="mini"
                            @click="handleCloseItem(item.memberNeedCode)"
                            style="display: absolute; right: 0px;white-space: nowrap;"
                            >关闭</el-button
                          >
                        </div>
                      </div>-->
                                            <div class="finishList_item_title3">
                                                <div
                                                    class="lt"
                                                    style="display: flex; flex-wrap: wrap; margin-left: 5px"
                                                >
                                                    <p
                                                        style="
                                                            margin-top: 2px;
                                                            font-size: 14px;
                                                            font-weight: bold;

                                                            margin-right: 10px;
                                                        "
                                                    >
                                                        {{ item.goodsName }}
                                                    </p>

                                                    <div style="display: flex; flex-wrap: wrap">
                                                        <p class="finishList_item_title_item" v-if="item.goodsSpec">
                                                            <span
                                                                class="fontColor"
                                                                style="width: 55px; display: inline-block"
                                                                >规格型号:</span
                                                            >
                                                            <span style="display: inline-block"
                                                                >{{ item.goodsSpec }},{{ item.goodsMarque }}</span
                                                            >
                                                        </p>
                                                        <p class="finishList_item_title_item" v-if="!item.goodsSpec">
                                                            <span
                                                                class="fontColor"
                                                                style="width: 55px; display: inline-block"
                                                                >规格型号:</span
                                                            >
                                                            <span style="max-width: 200px; display: inline-block">{{
                                                                item.goodsMarque
                                                            }}</span>
                                                        </p>
                                                        <p class="finishList_item_title_item">
                                                            <span class="fontColor">采购数量:</span
                                                            ><span class="bule">
                                                                {{ item.purchaseNum }}{{ item.goodsUnit }}
                                                            </span>
                                                        </p>
                                                        <p class="finishList_item_title_item">
                                                            <span class="fontColor">需求日期:</span>
                                                            <span>{{ item.needDate }}</span>
                                                        </p>
                                                        <div class="finishList_item_title_item">
                                                            <span
                                                                class="fontColor"
                                                                style="max-width: 100px; display: inline-block"
                                                                >备注:</span
                                                            >
                                                            <div class="flex_row_center_center">
                                                                <span
                                                                    class="desc-clss"
                                                                    :title="item.desc"
                                                                    style="max-width: 300px; display: inline-block"
                                                                    >{{ item.desc }}</span
                                                                >
                                                                <span
                                                                    class="imgBtn"
                                                                    v-if="item.purchasePic"
                                                                    @click="previewImg(item.purchasePic)"
                                                                    >查看图片</span
                                                                >
                                                            </div>
                                                        </div>
                                                        <p
                                                            class="finishList_item_title_item"
                                                            v-if="
                                                                platform == 2 &&
                                                                item.mySupplierName &&
                                                                item.myPrice &&
                                                                store.state.memberInfo.isNeedMyPrice == 1
                                                            "
                                                        >
                                                            <span class="fontColor">我的报价:</span
                                                            ><span class="bule"
                                                                >{{ item.mySupplierName }} ￥{{ item.myPrice }}</span
                                                            >
                                                        </p>
                                                        <p
                                                            class="finishList_item_title_item"
                                                            v-if="item.choiceMemberName"
                                                        >
                                                            <span
                                                                class="fontColor"
                                                                style="color: #e99f27; margin-right: 0px"
                                                                >已选择：</span
                                                            >
                                                            <span
                                                                style="
                                                                    max-width: 250px;
                                                                    display: inline-block;
                                                                    color: #e99f27;
                                                                    margin-right: 10px;
                                                                "
                                                                >{{ item.choiceMemberName }}
                                                            </span>
                                                            <span
                                                                class="fontColor"
                                                                style="color: #e99f27; margin-right: 5px"
                                                                >单价</span
                                                            >
                                                            <span
                                                                style="
                                                                    max-width: 250px;
                                                                    display: inline-block;
                                                                    color: #e99f27;
                                                                "
                                                                >{{ item.choicePrice }}
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="rt">
                                                    <p class="tag" v-if="item.isPlaceAnOrder == 1">已下单</p>
                                                    <p
                                                        class="viewDetailsss"
                                                        style="margin-right: 10px"
                                                        @click="getmySupplierList(item.memberNeedCode, [], 2)"
                                                        v-if="
                                                            store.state.memberInfo.isNeedMyPrice == 0 &&
                                                            topTable.data.state != 4 &&
                                                            route.query.type == 0 &&
                                                            topTable.data.state != 3
                                                        "
                                                    >
                                                        邀请我的供应商
                                                    </p>
                                                    <p
                                                        v-if="
                                                            topTable.data.state !== 4 &&
                                                            ((store.state.memberInfo.isNeedMyPrice == 0 &&
                                                                topTable.data.state != 3 &&
                                                                route.query.type == 0) ||
                                                                route.query.type == 1)
                                                        "
                                                        class="viewDetailes"
                                                        style="margin-right: 10px"
                                                        @click="createOrders(topTable.data, item, 0)"
                                                    >
                                                        查看邀请供应商报价
                                                    </p>
                                                    <p
                                                        v-if="
                                                            topTable.data.state !== 4 &&
                                                            item.isChoose &&
                                                            item.supplierUserId &&
                                                            platform == 2
                                                        "
                                                        class="viewDetailes"
                                                        style="margin-right: 10px"
                                                        @click="createOrders(topTable.data, item, 1)"
                                                    >
                                                        下单
                                                    </p>

                                                    <el-button
                                                        size="mini"
                                                        @click="handleCloseItem(item.memberNeedCode)"
                                                        style="display: absolute; right: 0px"
                                                        >关闭</el-button
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <el-empty description="暂无数据" v-if="tableData.data.length == 0" />
                                <el-divider border-style="dotted" />
                                <div class="generateOrder">
                                    <div class="generateOrder_left">
                                        <span>
                                            <span
                                                ><span class="fontColor">采购计划总数:</span>
                                                <span>
                                                    {{ notQuoteCount + alreadyQuoteCount + closeQuoteCount }}
                                                </span>
                                                件
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </el-tab-pane>
                            <!--待报价商品end-->

                            <!--已报价商品start-->
                            <el-tab-pane :label="'已报价商品' + alreadyQuoteCount" name="2">
                                <!--商品视图start-->
                                <div id="scroll-container" @scroll="handleScroll" v-if="!isLook">
                                    <div class="finishList" v-if="tableData.goodsList.length !== 0">
                                        <div
                                            class="finishList_item"
                                            v-for="(item, index) in tableData.goodsList"
                                            :key="index"
                                        >
                                            <div class="finishList_item_title3">
                                                <div
                                                    style="
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: space-between;
                                                        margin-left: 5px;
                                                    "
                                                >
                                                    <div style="width: 65%">
                                                        <div style="display: flex; align-items: flex-start">
                                                            <img
                                                                src="@/assets/cart/sel.png"
                                                                alt=""
                                                                class="store_sel"
                                                                style="margin-right: 10px; margin-top: 10px"
                                                                v-if="
                                                                    item.ischecked == true &&
                                                                    item.isChoose == 1 &&
                                                                    item.needSku !== null
                                                                "
                                                                @click="checkedItem(item, index)"
                                                            />
                                                            <img
                                                                src="@/assets/cart/no_sel.png"
                                                                alt=""
                                                                class="store_sel"
                                                                style="margin-right: 10px; margin-top: 10px"
                                                                v-if="
                                                                    item.ischecked == false &&
                                                                    item.isChoose == 1 &&
                                                                    item.needSku !== null
                                                                "
                                                                @click="checkedItem(item, index)"
                                                            />
                                                            <div
                                                                style="
                                                                    display: flex;
                                                                    align-items: center;
                                                                    flex-wrap: wrap;
                                                                "
                                                            >
                                                                <p
                                                                    style="
                                                                        margin-top: 8px;
                                                                        font-size: 14px;
                                                                        font-weight: bold;
                                                                        margin-right: 10px;
                                                                    "
                                                                >
                                                                    {{ item.goodsName }}
                                                                </p>

                                                                <!--<p
                                v-if="item.isChoose == 0"
                                style="
                                  margin-top: 2px;
                                  font-size: 14px;
                                  font-weight: bold;
                                  margin-left: 10px;
                                "
                              >
                                {{ item.goodsName }}
                              </p>-->

                                                                <div
                                                                    style="
                                                                        display: flex;
                                                                        margin-top: 5px;
                                                                        flex-wrap: wrap;
                                                                    "
                                                                >
                                                                    <p
                                                                        class="finishList_item_title_item"
                                                                        v-if="item.goodsSpec"
                                                                    >
                                                                        <span
                                                                            class="fontColor"
                                                                            style="
                                                                                max-width: 100px;
                                                                                display: inline-block;
                                                                            "
                                                                            >规格型号:</span
                                                                        >
                                                                        <span style="display: inline-block"
                                                                            >{{ item.goodsSpec }},{{
                                                                                item.goodsMarque
                                                                            }}</span
                                                                        >
                                                                    </p>
                                                                    <p
                                                                        class="finishList_item_title_item"
                                                                        v-if="!item.goodsSpec"
                                                                    >
                                                                        <span
                                                                            class="fontColor"
                                                                            style="
                                                                                max-width: 100px;
                                                                                display: inline-block;
                                                                            "
                                                                            >规格型号:</span
                                                                        >
                                                                        <span
                                                                            style="
                                                                                max-width: 300px;
                                                                                display: inline-block;
                                                                            "
                                                                            >{{ item.goodsMarque }}</span
                                                                        >
                                                                    </p>
                                                                    <p class="finishList_item_title_item">
                                                                        <span class="fontColor">采购数量:</span
                                                                        ><span class="bule">
                                                                            {{ item.purchaseNum }}{{ item.goodsUnit }}
                                                                        </span>
                                                                    </p>
                                                                    <p class="finishList_item_title_item">
                                                                        <span class="fontColor">需求日期:</span>
                                                                        {{ item.needDate }}
                                                                    </p>
                                                                    <div class="finishList_item_title_item">
                                                                        <span
                                                                            class="fontColor"
                                                                            style="
                                                                                max-width: 100px;
                                                                                display: inline-block;
                                                                            "
                                                                            >备注:</span
                                                                        >
                                                                        <div class="flex_row_center_center">
                                                                            <span
                                                                                class="desc-clss"
                                                                                :title="item.desc"
                                                                                style="
                                                                                    max-width: 200px;
                                                                                    display: inline-block;
                                                                                "
                                                                                >{{ item.desc }}</span
                                                                            >
                                                                            <span
                                                                                class="imgBtn"
                                                                                v-if="item.purchasePic"
                                                                                @click="previewImg(item.purchasePic)"
                                                                                >查看图片</span
                                                                            >
                                                                        </div>
                                                                    </div>
                                                                    <!--<p
                                class="finishList_item_title_item"
                                v-if="
                                  item.isChooseMySupplier == 1 &&
                                  platform == 2 &&
                                  item.mySupplierName &&
                                  item.myPrice &&
                                  store.state.memberInfo.isNeedMyPrice == 1
                                "
                              >
                                <span class="fontColor">我的报价:</span
                                ><span class="bule" style="max-width: 200px"
                                  >{{ item.mySupplierName }}
                                </span>
                                <span class="bule"> ￥{{ item.myPrice }}</span>
                              </p>-->

                                                                    <p
                                                                        class="finishList_item_title_item"
                                                                        v-if="
                                                                            item.isChooseMySupplier == 1 &&
                                                                            platform == 2 &&
                                                                            item.mySupplierName &&
                                                                            item.myPrice &&
                                                                            store.state.memberInfo.isNeedMyPrice == 1
                                                                        "
                                                                    >
                                                                        <span class="fontColor">我的报价:</span
                                                                        ><span class="yellowColor"
                                                                            >{{ item.mySupplierName }} ￥{{
                                                                                item.myPrice
                                                                            }}</span
                                                                        >
                                                                    </p>
                                                                    <p
                                                                        class="finishList_item_title_item"
                                                                        v-if="item.choiceMemberName"
                                                                    >
                                                                        <span
                                                                            class="fontColor"
                                                                            style="color: #e99f27; margin-right: 0px"
                                                                            >已选择：</span
                                                                        >
                                                                        <span
                                                                            style="
                                                                                max-width: 250px;
                                                                                display: inline-block;
                                                                                color: #e99f27;
                                                                                margin-right: 10px;
                                                                            "
                                                                            >{{ item.choiceMemberName }}
                                                                        </span>
                                                                        <span
                                                                            class="fontColor"
                                                                            style="color: #e99f27; margin-right: 5px"
                                                                            >单价</span
                                                                        >
                                                                        <span
                                                                            style="
                                                                                max-width: 250px;
                                                                                display: inline-block;
                                                                                color: #e99f27;
                                                                            "
                                                                            >{{ item.choicePrice }}
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        style="
                                                            display: flex;
                                                            align-items: center;
                                                            justify-content: space-between;
                                                        "
                                                    >
                                                        <p
                                                            class="select"
                                                            v-if="item.isChooseMySupplier == 1 && platform == 2"
                                                        >
                                                            已选中
                                                        </p>

                                                        <p class="tag" v-if="item.isPlaceAnOrder == 1">已下单</p>
                                                        <p
                                                            class="viewDetailsss"
                                                            style="margin-right: 10px"
                                                            @click="viewRemark(item)"
                                                            v-if="item.isChoose == 1 && platform == 2"
                                                        >
                                                            查看选择原因
                                                        </p>
                                                        <p
                                                            class="viewDetailsss"
                                                            style="margin-right: 10px"
                                                            @click="getmySupplierList(item.memberNeedCode, [], 2)"
                                                            v-if="
                                                                store.state.memberInfo.isNeedMyPrice == 0 &&
                                                                route.query.type == 0
                                                            "
                                                        >
                                                            邀请我的供应商
                                                        </p>

                                                        <p
                                                            v-if="
                                                                topTable.data.state !== 4 &&
                                                                ((store.state.memberInfo.isNeedMyPrice == 0 &&
                                                                    route.query.type == 0) ||
                                                                    route.query.type == 1)
                                                            "
                                                            class="viewDetailes"
                                                            style="margin-right: 10px"
                                                            @click="createOrders(topTable.data, item, 0)"
                                                        >
                                                            查看邀请供应商报价
                                                        </p>
                                                        <p
                                                            v-if="
                                                                topTable.data.state !== 4 &&
                                                                item.isChoose &&
                                                                item.supplierUserId &&
                                                                platform == 2
                                                            "
                                                            class="viewDetailes"
                                                            style="margin-right: 10px"
                                                            @click="createOrders(topTable.data, item, 1)"
                                                        >
                                                            下单
                                                        </p>
                                                        <!--<p
                              v-if="
                                topTable.data.state !== 4 &&
                                item.isChoose &&
                                !item.supplierUserId
                              "
                              class="viewDetailes"
                              style="
                                margin-right: 10px;
                                cursor: not-allowed;
                                background: #ccc;
                              "
                            >
                              下单
                            </p>-->
                                                        <el-button
                                                            size="mini"
                                                            @click="handleCloseItem(item.memberNeedCode)"
                                                            >关闭</el-button
                                                        >
                                                    </div>
                                                </div>
                                            </div>
                                            <!--<div
                        class="finishList_item_title"
                        v-if="item.goodsName.split('').length <= 20"
                      >
                        <div style="display: flex; align-items: flex-start;">
                          <img
                            src="@/assets/cart/sel.png"
                            alt=""
                            class="store_sel"
                            style="margin-right: 10px"
                            v-if="item.ischecked == true && item.isChoose == 1&&item.needSku !== null"
                            @click="checkedItem(item, index)"
                          />
                          <img
                            src="@/assets/cart/no_sel.png"
                            alt=""
                            class="store_sel"
                            style="margin-right: 10px"
                            v-if="item.ischecked == false && item.isChoose == 1&&item.needSku !== null"
                            @click="checkedItem(item, index)"
                          />
                          <div  style="display: flex; align-items: center;flex-wrap: wrap;">
                          <p
                            v-if="item.isChoose == 1"
                            style="
                              margin-top: 2px;
                              font-size: 14px;
                              font-weight: bold;
                              white-space: nowrap;
                            "
                          >
                            {{ item.goodsName }}
                          </p>

                          <p
                            v-if="item.isChoose == 0"
                            style="
                              margin-left: 10px;
                              margin-top: 2px;
                              font-size: 14px;
                              font-weight: bold;
                            "
                          >
                            {{ item.goodsName }}
                          </p>

                          <p
                            class="finishList_item_title_item"
                            v-if="item.goodsSpec"
                          >
                            <span
                              class="fontColor"
                              style="max-width: 200px; display: inline-block;margin-left:10px"
                              >规格型号:</span
                            >
                            <span
                              style="max-width: 200px; display: inline-block"
                              >{{ item.goodsSpec }},{{ item.goodsMarque }}</span
                            >
                          </p>
                          <p
                            class="finishList_item_title_item"
                            v-if="!item.goodsSpec"
                          >
                            <span
                              class="fontColor"
                              style="max-width: 200px; display: inline-block;margin-left:10px"
                              >规格型号:</span
                            >
                            <span
                              style="max-width: 200px; display: inline-block"
                              >{{ item.goodsMarque }}</span
                            >
                          </p>
                          <p class="finishList_item_title_item">
                            <span class="fontColor">采购数量:</span
                            ><span class="bule">
                              {{ item.purchaseNum }}{{ item.goodsUnit }}
                            </span>
                          </p>
                          <p class="finishList_item_title_item">
                            <span class="fontColor">需求日期:</span>
                            {{ item.needDate }}
                          </p>
                          <div class="finishList_item_title_item">
                            <span
                              class="fontColor"
                              style="max-width: 100px; display: inline-block"
                              >备注:</span
                            >
                            <div class="flex_row_center_center">
                              <span
                                class="desc-clss"
                                :title="item.desc"
                                style="max-width: 200px; display: inline-block"
                                >{{ item.desc }}</span
                              >
                              <span
                                class="imgBtn"
                                v-if="item.purchasePic"
                                @click="previewImg(item.purchasePic)"
                                >查看图片</span
                              >
                            </div>
                          </div>
                          <p
                            class="finishList_item_title_item"
                            v-if="
                              item.isChooseMySupplier !== 1 &&
                              platform == 2 &&
                              item.mySupplierName &&
                              item.myPrice &&
                              store.state.memberInfo.isNeedMyPrice == 1
                            "
                          >
                            <span class="fontColor">我的报价:</span
                            ><span class="bule"
                              >{{ item.mySupplierName }} ￥{{
                                item.myPrice
                              }}</span
                            >
                          </p>
                          <p
                            class="finishList_item_title_item"
                            v-if="
                              item.isChooseMySupplier == 1 &&
                              platform == 2 &&
                              item.mySupplierName &&
                              item.myPrice &&
                              store.state.memberInfo.isNeedMyPrice == 1
                            "
                          >
                            <span class="fontColor">我的报价:</span
                            ><span class="yellowColor"
                              >{{ item.mySupplierName }} ￥{{
                                item.myPrice
                              }}</span
                            >
                          </p>
                          <p
                            class="finishList_item_title_item"
                            v-if="item.choiceMemberName"
                          >
                            <span
                              class="fontColor"
                              style="color: #e99f27; margin-right: 0px"
                              >已选择：</span
                            >
                            <span
                              style="
                                max-width: 250px;
                                display: inline-block;
                                color: #e99f27;
                                margin-right: 10px;
                              "
                              v-if="item.choiceMemberName.split('').length <= 3"
                              >{{ item.choiceMemberName }}
                            </span>
                            <el-tooltip
                              v-if="item.choiceMemberName.split('').length > 3"
                              class="box-goodsItem"
                              effect="dark"
                              :content="item.choiceMemberName"
                              placement="top-start"
                            >
                              <span
                                class="yellowColor text-ellipsissss"
                                style="max-width: 50px"
                                >{{ item.choiceMemberName }}</span
                              >
                            </el-tooltip>
                            <span
                              class="fontColor"
                              style="color: #e99f27; margin-right: 5px"
                              >单价</span
                            >
                            <span
                              style="
                                max-width: 250px;
                                display: inline-block;
                                color: #e99f27;
                              "
                              >{{ item.choicePrice }}
                            </span>
                          </p>
                          </div>
                        </div>
                        <div style="display: flex; align-items: center">
                          <p class="select" v-if="item.isChooseMySupplier == 1&&platform == 2">
                            已选中
                          </p>
                          <p class="tag" v-if="item.isPlaceAnOrder == 1">
                            已下单
                          </p>
                          <p
                              class="viewDetailsss"
                              style="margin-right: 10px"
                              @click="
                                viewRemark(item)
                              "
                              v-if="
                               item.isChoose==1&&platform == 2
                              "
                            >
                              查看选择原因
                            </p>
                          <p
                            class="viewDetailsss"
                            style="margin-right: 10px"
                            @click="getmySupplierList(item.memberNeedCode, [])"
                            v-if="
                              store.state.memberInfo.isNeedMyPrice == 0 &&
                              route.query.type == 0
                            "
                          >
                            邀请我的供应商
                          </p>
                          <p
                            v-if="topTable.data.state !== 4&&((store.state.memberInfo.isNeedMyPrice == 0 &&route.query.type==0)||route.query.type==1) "
                            class="viewDetailes"
                            style="margin-right: 10px"
                            @click="createOrders(topTable.data, item, 0)"
                          >
                            查看邀请供应商报价
                          </p>
                          <p
                            v-if="
                              (topTable.data.state !== 4 &&
                              item.isChoose &&
                              item.supplierUserId)&&platform == 2
                            "
                            class="viewDetailes"
                            style="margin-right: 10px"
                            @click="createOrders(topTable.data, item, 1)"
                          >
                            下单
                          </p>

                          <el-button
                            size="mini"
                            @click="handleCloseItem(item.memberNeedCode)"
                            >关闭</el-button
                          >
                        </div>
                      </div>-->
                                            <div class="finishList_GoodsDiv" v-if="item.needSku !== null">
                                                <img
                                                    :src="item.needSku.goodsImage"
                                                    alt=""
                                                    @click="goGoodsDetail(item.needSku.goodsPrice, item.recSkuCode)"
                                                />
                                                <div class="finishList_GoodsDiv_goodsInformation">
                                                    <div class="goodsInformation_left">
                                                        <div style="display: flex; align-item: center">
                                                            <h4
                                                                @click="
                                                                    goGoodsDetail(
                                                                        item.needSku.goodsPrice,
                                                                        item.recSkuCode
                                                                    )
                                                                "
                                                            >
                                                                <span
                                                                    class="mySupplierName"
                                                                    v-if="item.needSku.isMySupplier"
                                                                    >供</span
                                                                >
                                                                {{ item.needSku.goodsNameJoin }}
                                                            </h4>
                                                            <span
                                                                class="checked"
                                                                style="margin-left: 10px; margin-top: 2px"
                                                                v-if="item.needSku.isChoose"
                                                                >已选中</span
                                                            >
                                                        </div>
                                                        <div class="goodsInformation_left_div">
                                                            <p
                                                                style="margin-right: 60px; font-size: 13px"
                                                                v-if="platform == 2"
                                                            >
                                                                <span class="fontColor">供应商:</span
                                                                >{{ item.needSku.supplierUserName }}
                                                            </p>
                                                            <p>
                                                                <span class="fontColor">售价:</span>
                                                                <span class="redColor">
                                                                    ￥{{ item.needSku.goodsPrice }}
                                                                </span>
                                                            </p>
                                                            <p
                                                                class="goodsInformation_left_label"
                                                                v-if="item.needSku.quoteTime !== '长期有效'"
                                                            >
                                                                {{ item.needSku.quoteTime }}
                                                                前有效
                                                            </p>
                                                            <p
                                                                class="goodsInformation_left_label"
                                                                v-if="item.needSku.quoteTime == '长期有效'"
                                                            >
                                                                {{ item.needSku.quoteTime }}
                                                            </p>
                                                            <p style="margin-left: 50px">
                                                                <span class="fontColor">报价时间:</span>
                                                                <span style="font-size: 12px">
                                                                    {{
                                                                        item.needSku.pushTime
                                                                            ? item.needSku.pushTime
                                                                            : "暂无"
                                                                    }}
                                                                </span>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div class="goodsInformation_right">
                                                        <div
                                                            class="quantity_edit2 flex_row_start_center"
                                                            v-if="item.needSku.goodsPrice !== '***'"
                                                        >
                                                            <span
                                                                @click="
                                                                    handleChange(
                                                                        item.needSku.skuCode,
                                                                        item.needSku.goodsNum,
                                                                        'reduce',
                                                                        item.needSku
                                                                    )
                                                                "
                                                                ref="reduce"
                                                                >-</span
                                                            >
                                                            <input
                                                                type="number"
                                                                v-model="item.needSku.goodsNum"
                                                                :min="item.needSku.minNum"
                                                                @blur="
                                                                    handleChange(
                                                                        item.needSku.skuCode,
                                                                        item.needSku.goodsNum,
                                                                        'edit',
                                                                        item.needSku,
                                                                        $event
                                                                    )
                                                                "
                                                            />
                                                            <span
                                                                @click="
                                                                    handleChange(
                                                                        item.needSku.skuCode,
                                                                        item.needSku.goodsNum,
                                                                        'add',
                                                                        item.needSku
                                                                    )
                                                                "
                                                                ref="add"
                                                                >+</span
                                                            >
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="OtherRecommendations" v-if="item.needSku !== null">
                                                <!--走马灯效果start-->
                                                <el-carousel
                                                    trigger="click"
                                                    height="315px"
                                                    :autoplay="false"
                                                    arrow="always"
                                                    initial-index="0"
                                                    :motion-blur="true"
                                                    :interval="5000"
                                                    ref="carousel"
                                                    indicator-position="none"
                                                    @change="
                                                        changeGoosList(
                                                            item.goodsList.pageNum,
                                                            item.memberNeedCode,
                                                            item
                                                        )
                                                    "
                                                    v-if="item?.goodsList?.data.length !== 0"
                                                >
                                                    <el-carousel-item
                                                        v-for="Items in item.goodsList?.totalPage"
                                                        :key="Items"
                                                        style="background: #fafafaff"
                                                    >
                                                        <h3 class="small justify-center" text="2xl">
                                                            <div class="goodsDiv">
                                                                <div
                                                                    class="goodsDiv_item"
                                                                    v-for="(goodsItem, index) in item.goodsList.data"
                                                                    :key="index"
                                                                >
                                                                    <img
                                                                        :src="goodsItem.goodsImage"
                                                                        alt=""
                                                                        @click="
                                                                            goGoodsDetail(
                                                                                goodsItem.sellingPrice,
                                                                                goodsItem.skuCode
                                                                            )
                                                                        "
                                                                    />
                                                                    <p class="checked" v-if="goodsItem.isChoose">
                                                                        已选中
                                                                    </p>
                                                                    <div
                                                                        style="
                                                                            padding: 0 5px 10px 5px;
                                                                            background-color: #ffffffff;
                                                                        "
                                                                    >
                                                                        <el-tooltip
                                                                            v-if="
                                                                                goodsItem.goodsNameJoin.split('')
                                                                                    .length > 30
                                                                            "
                                                                            class="box-item"
                                                                            effect="dark"
                                                                            :content="goodsItem.goodsNameJoin"
                                                                            placement="top-start"
                                                                        >
                                                                            <p
                                                                                class="goodName"
                                                                                @click="
                                                                                    goGoodsDetail(
                                                                                        goodsItem.sellingPrice,
                                                                                        goodsItem.skuCode
                                                                                    )
                                                                                "
                                                                            >
                                                                                {{ goodsItem.goodsNameJoin }}
                                                                            </p>
                                                                        </el-tooltip>
                                                                        <p
                                                                            class="goodName"
                                                                            v-if="
                                                                                goodsItem.goodsNameJoin.split('')
                                                                                    .length <= 30
                                                                            "
                                                                            @click="
                                                                                goGoodsDetail(
                                                                                    goodsItem.sellingPrice,
                                                                                    goodsItem.skuCode
                                                                                )
                                                                            "
                                                                        >
                                                                            {{ goodsItem.goodsNameJoin }}
                                                                        </p>
                                                                        <el-tooltip
                                                                            v-if="
                                                                                goodsItem.supplierUserName.split('')
                                                                                    .length > 20 && platform == 2
                                                                            "
                                                                            class="box-item"
                                                                            effect="dark"
                                                                            :content="goodsItem.supplierUserName"
                                                                            placement="top-start"
                                                                        >
                                                                            <p class="supplierName">
                                                                                <span
                                                                                    class="mySupplierName"
                                                                                    v-if="goodsItem.isMySupplier"
                                                                                    >供</span
                                                                                ><span>{{
                                                                                    goodsItem.supplierUserName
                                                                                }}</span>
                                                                            </p>
                                                                        </el-tooltip>
                                                                        <p
                                                                            class="supplierName"
                                                                            v-if="
                                                                                goodsItem.supplierUserName.split('')
                                                                                    .length <= 20 && platform == 2
                                                                            "
                                                                        >
                                                                            <span
                                                                                class="mySupplierName"
                                                                                v-if="goodsItem.isMySupplier"
                                                                                >供</span
                                                                            ><span>{{
                                                                                goodsItem.supplierUserName
                                                                            }}</span>
                                                                        </p>
                                                                        <div
                                                                            style="
                                                                                margin-top: 3px;
                                                                                display: flex;
                                                                                align-items: center;
                                                                            "
                                                                        >
                                                                            <span class="redColor"
                                                                                >￥{{ goodsItem.sellingPrice }}</span
                                                                            ><span
                                                                                class="expirationDate"
                                                                                v-if="
                                                                                    goodsItem.quoteTime !==
                                                                                        '长期有效' &&
                                                                                    goodsItem.sellingPrice.split('')
                                                                                        .length <= 5
                                                                                "
                                                                            >
                                                                                {{ goodsItem.quoteTime }}前有效
                                                                            </span>
                                                                            <span
                                                                                class="expirationDate"
                                                                                v-if="
                                                                                    goodsItem.quoteTime == '长期有效' &&
                                                                                    goodsItem.sellingPrice.split('')
                                                                                        .length <= 5
                                                                                "
                                                                            >
                                                                                {{ goodsItem.quoteTime }}
                                                                            </span>
                                                                            <p
                                                                                class="expirationDate2"
                                                                                v-if="
                                                                                    goodsItem.quoteTime == '长期有效' &&
                                                                                    goodsItem.sellingPrice.split('')
                                                                                        .length5
                                                                                "
                                                                            >
                                                                                {{ goodsItem.quoteTime }}
                                                                            </p>
                                                                        </div>
                                                                        <div
                                                                            class="selectGoods"
                                                                            @click="
                                                                                changeGoods(
                                                                                    item.memberNeedCode,
                                                                                    goodsItem.skuCode,
                                                                                    item,
                                                                                    goodsItem
                                                                                )
                                                                            "
                                                                        >
                                                                            选择
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </h3>
                                                    </el-carousel-item>
                                                </el-carousel>

                                                <el-empty
                                                    description="暂无其他推荐"
                                                    v-if="item?.goodsList?.data.length == 0"
                                                />
                                            </div>
                                            <!--走马灯效果end-->
                                        </div>
                                    </div>
                                </div>
                                <!--供应商视图start-->
                                <div id="scroll-container" @scroll="handleScroll2" v-if="isLook">
                                    <div class="finishList" v-if="SupplierViewData.goodsList.length !== 0">
                                        <div
                                            class="finishList_item"
                                            v-for="(item, index) in SupplierViewData.goodsList"
                                            :key="index"
                                        >
                                            <div class="finishList_item_title3">
                                                <div
                                                    style="
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: space-between;
                                                    "
                                                >
                                                    <div>
                                                        <div style="display: flex; align-items: center">
                                                            <p
                                                                style="
                                                                    font-size: 14px;
                                                                    font-weight: bold;
                                                                    margin-left: 10px;
                                                                "
                                                            >
                                                                {{ item.supplierUserName }}
                                                            </p>
                                                        </div>
                                                        <div style="display: flex; margin-left: 10px">
                                                            <p class="finishList_item_title_item">
                                                                <span class="fontColor">计划采购商品数量:</span
                                                                ><span class="bule">
                                                                    {{ item.allGoodsCount }}
                                                                </span>
                                                            </p>
                                                            <p class="finishList_item_title_item">
                                                                <span class="fontColor">已报价采购商品数量:</span
                                                                ><span class="bule">
                                                                    {{ item.num.length }}
                                                                </span>
                                                            </p>

                                                            <p class="finishList_item_title_item" style="color: red">
                                                                <span class="fontColor" style="color: red"
                                                                    >已报价总金额:</span
                                                                >
                                                                {{ item.quoteAlMoney }}
                                                            </p>

                                                            <!--<p
                                class="finishList_item_title_item"
                                v-if="item.isChooseMySupplier == 1"
                              >
                                <span class="fontColor">我的报价:</span
                                ><span class="yellowColor"
                                  >{{ item.mySupplierName }} ￥{{ item.myPrice }}</span
                                >
                              </p>-->
                                                        </div>
                                                    </div>

                                                    <div
                                                        style="
                                                            display: flex;
                                                            align-items: center;
                                                            justify-content: space-between;
                                                        "
                                                    >
                                                        <p
                                                            class="select"
                                                            v-if="item.isChooseMySupplier == 1 && platform == 2"
                                                        >
                                                            已选中
                                                        </p>
                                                        <p class="tag" v-if="item.isPlaceAnOrder == 1">已下单</p>
                                                        <!--<p v-if="item.ischeckedAll" class="checkedAll" @click="selectAll(item)">选择全部</p>-->
                                                        <!--<p v-if="item.isCancel" class="checkedAll" @click="CancelAll(item)">取消全部</p>-->
                                                        <!--<el-button size="mini" @click="handleCloseItem(item.memberNeedCode)">关闭</el-button>-->
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="OtherRecommendations">
                                                <el-carousel
                                                    ref="carousel"
                                                    trigger="click"
                                                    height="430px"
                                                    :autoplay="false"
                                                    arrow="always"
                                                    initial-index="0"
                                                    :motion-blur="true"
                                                    :interval="5000"
                                                    indicator-position="none"
                                                    v-if="item?.goodsList?.data.length !== 0"
                                                >
                                                    <el-carousel-item
                                                        v-for="(Items, indes) in item?.goodsList?.data"
                                                        :key="indes"
                                                        style="background: #fafafaff"
                                                    >
                                                        <!--<h3 class="small justify-center" text="2xl">-->
                                                        <!--<i  @click="CarouselChange('prev',item.goodsList.pageNum, item.memberNeedCode, item,index)"  class="iconfont icon-ziyuan2 arrow-left" ></i>
                            <i class="iconfont icon-ziyuan11 arrow-right"  @click="CarouselChange('next',item.goodsList.pageNum, item.memberNeedCode, item,index)"></i>-->
                                                        <div class="goodsDiv carousel-inner">
                                                            <div
                                                                class="goodsDiv_item carousel-item"
                                                                v-for="(goodsItem, index) in Items"
                                                                :key="index"
                                                                :style="{
                                                                    transform: `translateX(${currentSlide}px)`
                                                                }"
                                                            >
                                                                <img
                                                                    :src="goodsItem.goodsImage"
                                                                    alt=""
                                                                    @click="
                                                                        goGoodsDetail(
                                                                            goodsItem.sellingPrice,
                                                                            goodsItem.skuCode
                                                                        )
                                                                    "
                                                                />
                                                                <p class="checked" v-if="goodsItem.isChooseGoods">
                                                                    已选中
                                                                </p>
                                                                <div
                                                                    style="
                                    padding: 0 5px 10px 5px;
                                    background-color: #ffffffff;
                                    line-height: 1.5;
                                    color:color: #999999FF;
                                  "
                                                                >
                                                                    <el-tooltip
                                                                        class="box-item"
                                                                        effect="dark"
                                                                        :content="goodsItem.goodsNameJoin"
                                                                        placement="top"
                                                                    >
                                                                        <p
                                                                            class="goodName"
                                                                            @click="
                                                                                goGoodsDetail(
                                                                                    goodsItem.sellingPrice,
                                                                                    goodsItem.skuCode
                                                                                )
                                                                            "
                                                                        >
                                                                            {{ goodsItem.goodsNameJoin }}
                                                                        </p>
                                                                    </el-tooltip>
                                                                    <p>
                                                                        <span>规格型号：</span>
                                                                        <span
                                                                            v-if="
                                                                                goodsItem.goodsSpec && goodsItem.marque
                                                                            "
                                                                            >{{ goodsItem.goodsSpec }},{{
                                                                                goodsItem.marque
                                                                            }}</span
                                                                        >
                                                                        <span v-if="!goodsItem.goodsSpec">{{
                                                                            goodsItem.marque
                                                                        }}</span>
                                                                    </p>
                                                                    <p style="display: flex">
                                                                        <span>采购数量：</span>
                                                                        <span
                                                                            >{{ goodsItem.purchaseNum
                                                                            }}{{ goodsItem.marketingUnit }}</span
                                                                        >
                                                                    </p>

                                                                    <div style="display: flex; align-items: center">
                                                                        <span>选购数量 </span>
                                                                        <!--<el-input-number v-model="goodsItem.purchaseNum2" size="small" style="width:128px" :min="goodsItem.minNum" :validate-event="true"
                              :max="Number(goodsItem.purchaseNum)" @change="PurchaseQuantityChange(goodsItem.skuCode,goodsItem.purchaseNum2)" :precision="4" :step="1"/>-->
                                                                        <!--:max="Number(goodsItem.purchaseNum)"-->
                                                                        <div
                                                                            class="quantity_edit flex_row_start_center"
                                                                        >
                                                                            <span
                                                                                @click="
                                                                                    PurchaseQuantityChange(
                                                                                        goodsItem.skuCode,
                                                                                        goodsItem.purchaseNum2,
                                                                                        'reduce',
                                                                                        goodsItem
                                                                                    )
                                                                                "
                                                                                ref="reduce"
                                                                                >-</span
                                                                            >
                                                                            <input
                                                                                type="number"
                                                                                v-model="goodsItem.purchaseNum2"
                                                                                :min="goodsItem.minNum"
                                                                                @blur="
                                                                                    PurchaseQuantityChange(
                                                                                        goodsItem.skuCode,
                                                                                        goodsItem.purchaseNum2,
                                                                                        'edit',
                                                                                        goodsItem,
                                                                                        $event
                                                                                    )
                                                                                "
                                                                            />
                                                                            <span
                                                                                @click="
                                                                                    PurchaseQuantityChange(
                                                                                        goodsItem.skuCode,
                                                                                        goodsItem.purchaseNum2,
                                                                                        'add',
                                                                                        goodsItem
                                                                                    )
                                                                                "
                                                                                ref="add"
                                                                                >+</span
                                                                            >
                                                                        </div>
                                                                    </div>

                                                                    <p>
                                                                        <span>需求日期:</span>
                                                                        {{ goodsItem.needDate }}
                                                                    </p>
                                                                    <!--<p>
                                    <span>我的供应商:</span>
                                    {{ goodsItem.mySupplierName }}
                                  </p>-->
                                                                    <!--<p>
                                    <span>我的报价:</span>
                                    {{ goodsItem.myPrice }}元
                                  </p>-->
                                                                    <div
                                                                        style="
                                                                            margin-top: 3px;
                                                                            display: flex;
                                                                            align-items: center;
                                                                        "
                                                                    >
                                                                        <p>
                                                                            <span>平台售价:</span>
                                                                            <span class="redColor"
                                                                                >￥{{ goodsItem.sellingPrice }}</span
                                                                            >
                                                                        </p>
                                                                        <span
                                                                            class="expirationDate"
                                                                            v-if="
                                                                                goodsItem.quoteTime !== '长期有效' &&
                                                                                goodsItem.sellingPrice.split('')
                                                                                    .length <= 5
                                                                            "
                                                                        >
                                                                            {{ goodsItem.quoteTime }}前有效
                                                                        </span>
                                                                        <span
                                                                            class="expirationDate"
                                                                            v-if="
                                                                                goodsItem.quoteTime == '长期有效' &&
                                                                                goodsItem.sellingPrice.split('')
                                                                                    .length <= 5
                                                                            "
                                                                        >
                                                                            {{ goodsItem.quoteTime }}
                                                                        </span>
                                                                        <p
                                                                            class="expirationDate2"
                                                                            v-if="
                                                                                goodsItem.quoteTime == '长期有效' &&
                                                                                goodsItem.sellingPrice.split('').length5
                                                                            "
                                                                        >
                                                                            {{ goodsItem.quoteTime }}
                                                                        </p>
                                                                    </div>
                                                                    <p style="font-size: 10px">
                                                                        <span>平台报价时间：</span>
                                                                        <span>{{ goodsItem.pushTime }}</span>
                                                                    </p>
                                                                    <!--<div
                                    v-if="!goodsItem.isBestGoods"
                                    class="selectGoods"
                                    @click="
                                      changeGoods2(
                                        true,
                                        goodsItem.memberNeedCode,
                                        goodsItem.skuCode,
                                        item,
                                        goodsItem
                                      )
                                    "
                                  >
                                    选择
                                  </div>-->
                                                                    <p v-if="goodsItem.isChooseGoods && platform == 2">
                                                                        <span>选择原因</span>:
                                                                        <span
                                                                            class="viewRemark"
                                                                            @click="viewRemark(goodsItem)"
                                                                            >查看</span
                                                                        >
                                                                    </p>
                                                                    <div
                                                                        v-if="!goodsItem.isBestGoods"
                                                                        class="GreyOutButton"
                                                                        @click="
                                                                            changeGoods2(
                                                                                true,
                                                                                goodsItem.memberNeedCode,
                                                                                goodsItem.skuCode,
                                                                                item,
                                                                                goodsItem
                                                                            )
                                                                        "
                                                                    >
                                                                        选择
                                                                    </div>

                                                                    <div
                                                                        :style="item.num.length == 1"
                                                                        v-if="goodsItem.isBestGoods"
                                                                        class="selectGoods"
                                                                        @click="
                                                                            changeGoods2(
                                                                                false,
                                                                                goodsItem.memberNeedCode,
                                                                                goodsItem.skuCode,
                                                                                item,
                                                                                goodsItem
                                                                            )
                                                                        "
                                                                    >
                                                                        已选择
                                                                    </div>
                                                                    <!--<div
                                  style="cursor: not-allowed;"
                                    v-if="goodsItem.checked&&(!goodsItem?.select)&&item.num.length==1"
                                    class="selectGoods"

                                  >
                                    取消
                                  </div>-->
                                                                </div>
                                                                <div></div>
                                                            </div>
                                                        </div>
                                                        <!--</h3>-->
                                                    </el-carousel-item>
                                                </el-carousel>

                                                <el-empty
                                                    description="暂无其他推荐"
                                                    v-if="item?.goodsList?.data.length == 0"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--供应商视图end-->

                                <el-empty description="暂无数据" v-if="!isLook && tableData.goodsList.length == 0" />
                                <el-empty
                                    description="暂无数据"
                                    v-if="isLook && SupplierViewData.goodsList.length == 0"
                                />
                                <el-divider
                                    border-style="dotted"
                                    v-if="isLook && SupplierViewData.goodsList.length !== 0"
                                />
                                <el-divider border-style="dotted" v-if="!isLook && tableData.goodsList.length !== 0" />
                                <div class="generateOrder" v-if="route.query.state != 3">
                                    <div class="generateOrder_left">
                                        <div
                                            style="display: flex; align-item: center; margin-right: 15px"
                                            v-if="!isLook"
                                        >
                                            <img
                                                src="@/assets/cart/no_sel.png"
                                                alt=""
                                                class="store_sel"
                                                style="margin-right: 10px"
                                                @click="checkedAll"
                                                v-if="ischeckedAll == false"
                                            />
                                            <img
                                                src="@/assets/cart/sel.png"
                                                alt=""
                                                class="store_sel"
                                                style="margin-right: 10px"
                                                @click="checkedAll(ischeckedAll)"
                                                v-if="ischeckedAll == true"
                                            />
                                            <p>全选</p>
                                        </div>
                                        <span>
                                            <span
                                                ><span class="fontColor">采购计划总数:</span>
                                                <span>{{ notQuoteCount + alreadyQuoteCount + closeQuoteCount }}</span
                                                >件</span
                                            >
                                        </span>
                                    </div>
                                    <div class="generateOrder_right">
                                        <p style="margin-right: 20px">
                                            <span class="fontColor">选择商品数量 :</span
                                            ><span v-if="!isLook">{{ multipleSelection.length }}</span>
                                            <span v-if="isLook">{{ multipleSelection2.data.length }}</span
                                            >件
                                        </p>
                                        <p style="margin-right: 20px">
                                            <span class="fontColor">总金额 :</span
                                            ><span class="redColor" v-if="!isLook">￥{{ AllMoney }}</span>
                                            <span class="redColor" v-if="isLook">￥{{ AllMoney2 }}</span>
                                        </p>

                                        <p v-if="isChoose" class="generateOrder_button" @click="selectPaymentMethod">
                                            生成订单
                                        </p>
                                        <!--<p v-if="(multipleSelection2.data==[]&&(!isLook))" class="generateOrder_button2" >
                      生成订单
                    </p>-->
                                    </div>
                                </div>
                            </el-tab-pane>
                            <!--已报价商品end-->

                            <!--已关闭商品start-->
                            <el-tab-pane :label="'已关闭商品' + closeQuoteCount" name="3">
                                <!--v-for="(orderItem, index) in tableData.data"-->
                                <!--:key="index"-->
                                <div id="scroll-container3" @scroll="handleScroll">
                                    <div class="finishList" v-if="closeData.goodsList.length !== 0">
                                        <div
                                            class="finishList_item"
                                            v-for="(item, index) in closeData.goodsList"
                                            :key="index"
                                        >
                                            <div
                                                class="finishList_item_title3"
                                                v-if="Number(item.goodsName.split('').length) > 20"
                                            >
                                                <div
                                                    style="
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: space-between;
                                                    "
                                                >
                                                    <div>
                                                        <div style="display: flex; align-items: center">
                                                            <img
                                                                src="@/assets/cart/sel.png"
                                                                alt=""
                                                                class="store_sel"
                                                                style="margin-right: 10px"
                                                                v-if="item.ischecked == true && item.isChoose == 1"
                                                                @click="checkedItem(item, index)"
                                                            />
                                                            <img
                                                                src="@/assets/cart/no_sel.png"
                                                                alt=""
                                                                class="store_sel"
                                                                style="margin-right: 10px"
                                                                v-if="item.ischecked == false && item.isChoose == 1"
                                                                @click="checkedItem(item, index)"
                                                            />
                                                            <p
                                                                v-if="item.isChoose == 1"
                                                                style="
                                                                    margin-top: 2px;
                                                                    font-size: 14px;
                                                                    font-weight: bold;
                                                                "
                                                            >
                                                                {{ item.goodsName }}
                                                            </p>

                                                            <p
                                                                v-if="item.isChoose == 0 || !item.isChoose"
                                                                style="
                                                                    margin-top: 2px;
                                                                    font-size: 14px;
                                                                    font-weight: bold;
                                                                    margin-left: 10px;
                                                                "
                                                            >
                                                                {{ item.goodsName }}
                                                            </p>
                                                        </div>
                                                        <div style="display: flex; margin-top: 5px">
                                                            <p class="finishList_item_title_item" v-if="item.goodsSpec">
                                                                <span
                                                                    class="fontColor"
                                                                    style="
                                                                        max-width: 100px;
                                                                        display: inline-block;
                                                                        margin-left: 10px;
                                                                    "
                                                                    >规格型号:</span
                                                                >
                                                                <span style="max-width: 300px; display: inline-block"
                                                                    >{{ item.goodsSpec }},{{ item.goodsMarque }}</span
                                                                >
                                                            </p>
                                                            <p
                                                                class="finishList_item_title_item"
                                                                v-if="!item.goodsSpec"
                                                            >
                                                                <span
                                                                    class="fontColor"
                                                                    style="
                                                                        max-width: 100px;
                                                                        display: inline-block;
                                                                        margin-left: 10px;
                                                                    "
                                                                    >规格型号:</span
                                                                >
                                                                <span style="max-width: 300px; display: inline-block">{{
                                                                    item.goodsMarque
                                                                }}</span>
                                                            </p>
                                                            <p class="finishList_item_title_item">
                                                                <span class="fontColor">采购数量:</span
                                                                ><span class="bule">
                                                                    {{ item.purchaseNum }}{{ item.goodsUnit }}
                                                                </span>
                                                            </p>
                                                            <p class="finishList_item_title_item">
                                                                <span class="fontColor">需求日期:</span>
                                                                {{ item.needDate }}
                                                            </p>
                                                            <div class="finishList_item_title_item">
                                                                <span
                                                                    class="fontColor"
                                                                    style="max-width: 100px; display: inline-block"
                                                                    >备注:</span
                                                                >
                                                                <div class="flex_row_center_center">
                                                                    <span
                                                                        class="desc-clss"
                                                                        :title="item.desc"
                                                                        style="max-width: 200px; display: inline-block"
                                                                        >{{ item.desc }}</span
                                                                    >
                                                                    <span
                                                                        class="imgBtn"
                                                                        v-if="item.purchasePic"
                                                                        @click="previewImg(item.purchasePic)"
                                                                        >查看图片</span
                                                                    >
                                                                </div>
                                                            </div>
                                                            <p
                                                                class="finishList_item_title_item"
                                                                v-if="
                                                                    (item.isChooseMySupplier == 0 ||
                                                                        !item.isChooseMySupplier) &&
                                                                    platform == 2 &&
                                                                    store.state.memberInfo.isNeedMyPrice == 1 &&
                                                                    route.query.type == 0
                                                                "
                                                            >
                                                                <span class="fontColor">我的报价:</span>
                                                                <span class="bule" v-if="item.state == 0">未报价</span>
                                                                <span class="bule" v-else-if="!item.myPrice"
                                                                    >暂无报价</span
                                                                >
                                                                <span class="bule" style="max-width: 200px" v-else>
                                                                    {{ item.mySupplierName }} ￥{{ item.myPrice }}</span
                                                                >
                                                            </p>

                                                            <p
                                                                class="finishList_item_title_item"
                                                                v-if="
                                                                    item.isChooseMySupplier == 1 &&
                                                                    platform == 2 &&
                                                                    store.state.memberInfo.isNeedMyPrice == 1
                                                                "
                                                            >
                                                                <span class="fontColor">我的报价:</span>
                                                                <span class="yellowColor"
                                                                    >{{ item.mySupplierName }} ￥{{
                                                                        item.myPrice
                                                                    }}</span
                                                                >
                                                            </p>
                                                            <p
                                                                class="finishList_item_title_item"
                                                                v-if="item.choiceMemberName"
                                                            >
                                                                <span
                                                                    class="fontColor"
                                                                    style="color: #e99f27; margin-right: 0px"
                                                                    >已选择：</span
                                                                >
                                                                <span
                                                                    style="
                                                                        max-width: 250px;
                                                                        display: inline-block;
                                                                        color: #e99f27;
                                                                        margin-right: 10px;
                                                                    "
                                                                    >{{ item.choiceMemberName }}
                                                                </span>
                                                                <span
                                                                    class="fontColor"
                                                                    style="color: #e99f27; margin-right: 5px"
                                                                    >单价</span
                                                                >
                                                                <span
                                                                    style="
                                                                        max-width: 250px;
                                                                        display: inline-block;
                                                                        color: #e99f27;
                                                                    "
                                                                    >{{ item.choicePrice }}
                                                                </span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div
                                                        style="
                                                            display: flex;
                                                            align-items: center;
                                                            justify-content: space-between;
                                                        "
                                                    >
                                                        <p
                                                            class="viewDetailsss"
                                                            style="margin-right: 10px"
                                                            @click="viewRemark(item)"
                                                            v-if="item.isChoose == 1 && platform == 2"
                                                        >
                                                            查看选择原因
                                                        </p>

                                                        <p
                                                            class="select"
                                                            v-if="item.isChooseMySupplier == 1 && platform == 2"
                                                        >
                                                            已选中
                                                        </p>
                                                        <p class="tag" v-if="item.isPlaceAnOrder == 1">已下单</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                class="finishList_item_title"
                                                v-if="item.goodsName.split('').length <= 20"
                                            >
                                                <div style="display: flex; align-items: flex-start">
                                                    <img
                                                        src="@/assets/cart/sel.png"
                                                        alt=""
                                                        class="store_sel"
                                                        style="margin-right: 10px"
                                                        v-if="item.ischecked == true && item.isChoose == 1"
                                                        @click="checkedItem(item, index)"
                                                    />
                                                    <img
                                                        src="@/assets/cart/no_sel.png"
                                                        alt=""
                                                        class="store_sel"
                                                        style="margin-right: 10px"
                                                        v-if="item.ischecked == false && item.isChoose == 1"
                                                        @click="checkedItem(item, index)"
                                                    />
                                                    <p
                                                        v-if="item.isChoose == 1"
                                                        style="
                                                            margin-top: 2px;
                                                            font-size: 14px;
                                                            font-weight: bold;
                                                            white-space: nowrap;
                                                        "
                                                    >
                                                        {{ item.goodsName }}
                                                    </p>

                                                    <p
                                                        v-if="item.isChoose == 0 || !item.isChoose"
                                                        style="
                                                            margin-left: 10px;
                                                            margin-top: 2px;
                                                            font-size: 14px;
                                                            font-weight: bold;
                                                        "
                                                    >
                                                        {{ item.goodsName }}
                                                    </p>

                                                    <p class="finishList_item_title_item" v-if="item.goodsSpec">
                                                        <span
                                                            class="fontColor"
                                                            style="
                                                                max-width: 200px;
                                                                display: inline-block;
                                                                margin-left: 10px;
                                                            "
                                                            >规格型号:</span
                                                        >
                                                        <span style="max-width: 200px; display: inline-block"
                                                            >{{ item.goodsSpec }},{{ item.goodsMarque }}</span
                                                        >
                                                    </p>
                                                    <p class="finishList_item_title_item" v-if="!item.goodsSpec">
                                                        <span
                                                            class="fontColor"
                                                            style="max-width: 200px; display: inline-block"
                                                            >规格型号:</span
                                                        >
                                                        <span style="max-width: 200px; display: inline-block">{{
                                                            item.goodsMarque
                                                        }}</span>
                                                    </p>
                                                    <p class="finishList_item_title_item">
                                                        <span class="fontColor">采购数量:</span
                                                        ><span class="bule">
                                                            {{ item.purchaseNum }}{{ item.goodsUnit }}
                                                        </span>
                                                    </p>
                                                    <p class="finishList_item_title_item">
                                                        <span class="fontColor">需求日期:</span>
                                                        {{ item.needDate }}
                                                    </p>
                                                    <div class="finishList_item_title_item">
                                                        <span
                                                            class="fontColor"
                                                            style="max-width: 100px; display: inline-block"
                                                            >备注:</span
                                                        >
                                                        <div class="flex_row_center_center">
                                                            <span
                                                                class="desc-clss"
                                                                :title="item.desc"
                                                                style="max-width: 200px; display: inline-block"
                                                                >{{ item.desc }}</span
                                                            >
                                                            <span
                                                                class="imgBtn"
                                                                v-if="item.purchasePic"
                                                                @click="previewImg(item.purchasePic)"
                                                                >查看图片</span
                                                            >
                                                        </div>
                                                    </div>
                                                    <p
                                                        class="finishList_item_title_item"
                                                        v-if="
                                                            (item.isChooseMySupplier == 0 ||
                                                                !item.isChooseMySupplier) &&
                                                            platform == 2 &&
                                                            store.state.memberInfo.isNeedMyPrice == 1 &&
                                                            route.query.type == 0
                                                        "
                                                    >
                                                        <span class="fontColor">我的报价:</span>
                                                        <span class="bule" v-if="item.state == 0">未报价</span>
                                                        <span class="bule" v-else-if="!item.myPrice">暂无报价</span>
                                                        <span class="bule" v-else
                                                            >{{ item.mySupplierName }} ￥{{ item.myPrice }}</span
                                                        >
                                                    </p>
                                                    <p
                                                        class="finishList_item_title_item"
                                                        v-if="
                                                            item.isChooseMySupplier == 1 &&
                                                            platform == 2 &&
                                                            store.state.memberInfo.isNeedMyPrice == 1
                                                        "
                                                    >
                                                        <span class="fontColor">我的报价:</span>
                                                        <span class="yellowColor"
                                                            >{{ item.mySupplierName }} ￥{{ item.myPrice }}</span
                                                        >
                                                    </p>
                                                    <p class="finishList_item_title_item" v-if="item.choiceMemberName">
                                                        <span
                                                            class="fontColor"
                                                            style="color: #e99f27; margin-right: 0px"
                                                            >已选择：</span
                                                        >
                                                        <span
                                                            style="
                                                                max-width: 250px;
                                                                display: inline-block;
                                                                color: #e99f27;
                                                                margin-right: 10px;
                                                            "
                                                            >{{ item.choiceMemberName }}
                                                        </span>
                                                        <span
                                                            class="fontColor"
                                                            style="color: #e99f27; margin-right: 5px"
                                                            >单价</span
                                                        >
                                                        <span
                                                            style="
                                                                max-width: 250px;
                                                                display: inline-block;
                                                                color: #e99f27;
                                                            "
                                                            >{{ item.choicePrice }}
                                                        </span>
                                                    </p>
                                                </div>
                                                <div style="display: flex">
                                                    <p
                                                        class="viewDetailsss"
                                                        style="margin-right: 10px"
                                                        @click="viewRemark(item)"
                                                        v-if="item.isChoose == 1 && platform == 2"
                                                    >
                                                        查看选择原因
                                                    </p>
                                                    <p
                                                        class="select"
                                                        v-if="item.isChooseMySupplier == 1 && platform == 2"
                                                    >
                                                        已选中
                                                    </p>
                                                    <p class="tag" v-if="item.isPlaceAnOrder == 1">已下单</p>
                                                </div>
                                            </div>
                                            <div class="finishList_GoodsDiv" v-if="item.needSku">
                                                <img
                                                    :src="item.needSku.goodsImage"
                                                    alt=""
                                                    @click="goGoodsDetail(item.needSku.goodsPrice, item.recSkuCode)"
                                                />
                                                <div class="finishList_GoodsDiv_goodsInformation">
                                                    <div class="goodsInformation_left">
                                                        <div style="display: flex; align-item: center">
                                                            <h4
                                                                @click="
                                                                    goGoodsDetail(
                                                                        item.needSku.goodsPrice,
                                                                        item.recSkuCode
                                                                    )
                                                                "
                                                            >
                                                                <span
                                                                    class="mySupplierName"
                                                                    v-if="item.needSku.isMySupplier"
                                                                    >供</span
                                                                >
                                                                {{ item.needSku.goodsNameJoin }}
                                                            </h4>
                                                            <span
                                                                class="checked"
                                                                style="margin-left: 10px; margin-top: 2px"
                                                                v-if="item.needSku.isChoose"
                                                                >已选中</span
                                                            >
                                                        </div>
                                                        <div class="goodsInformation_left_div">
                                                            <p
                                                                style="margin-right: 60px; font-size: 13px"
                                                                v-if="platform == 2"
                                                            >
                                                                <span class="fontColor">供应商:</span
                                                                >{{ item.needSku.supplierUserName }}
                                                            </p>
                                                            <p>
                                                                <span class="fontColor">售价:</span>
                                                                <span class="redColor">
                                                                    ￥{{ item.needSku.goodsPrice }}
                                                                </span>
                                                            </p>
                                                            <p
                                                                class="goodsInformation_left_label"
                                                                v-if="item.needSku.quoteTime !== '长期有效'"
                                                            >
                                                                {{ item.needSku.quoteTime }}
                                                                前有效
                                                            </p>
                                                            <p
                                                                class="goodsInformation_left_label"
                                                                v-if="item.needSku.quoteTime == '长期有效'"
                                                            >
                                                                {{ item.needSku.quoteTime }}
                                                            </p>
                                                            <p style="margin-left: 50px">
                                                                <span class="fontColor">报价时间:</span>
                                                                <span style="font-size: 12px">
                                                                    {{
                                                                        item.needSku.pushTime
                                                                            ? item.needSku.pushTime
                                                                            : "暂无"
                                                                    }}
                                                                </span>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div class="goodsInformation_right">
                                                        <el-input-number
                                                            v-if="item.needSku.goodsPrice !== '***'"
                                                            v-model="item.needSku.goodsNum"
                                                            :min="0"
                                                            @change="handleChange2(item.needSku.goodsNum)"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="OtherRecommendations">
                                                <!--走马灯效果start-->
                                                <el-carousel
                                                    trigger="click"
                                                    height="280px"
                                                    :autoplay="false"
                                                    arrow="always"
                                                    initial-index="0"
                                                    :motion-blur="true"
                                                    :interval="5000"
                                                    indicator-position="none"
                                                    ref="carousel"
                                                    @change="
                                                        changeGoosList(
                                                            item.goodsList.pageNum,
                                                            item.memberNeedCode,
                                                            item
                                                        )
                                                    "
                                                    v-if="item?.goodsList?.data.length !== 0"
                                                >
                                                    <el-carousel-item
                                                        v-for="Items in item.goodsList?.totalPage"
                                                        :key="Items"
                                                        style="background: #fafafaff"
                                                    >
                                                        <h3 class="small justify-center" text="2xl">
                                                            <div class="goodsDiv">
                                                                <div
                                                                    class="goodsDiv_item"
                                                                    v-for="(goodsItem, index) in item.goodsList.data"
                                                                    :key="index"
                                                                >
                                                                    <img
                                                                        :src="goodsItem.goodsImage"
                                                                        alt=""
                                                                        @click="
                                                                            goGoodsDetail(
                                                                                goodsItem.sellingPrice,
                                                                                goodsItem.skuCode
                                                                            )
                                                                        "
                                                                    />
                                                                    <p class="checked" v-if="goodsItem.isChoose">
                                                                        已选中
                                                                    </p>
                                                                    <div
                                                                        style="
                                                                            padding: 0 5px 10px 5px;
                                                                            background-color: #ffffffff;
                                                                        "
                                                                    >
                                                                        <el-tooltip
                                                                            v-if="
                                                                                goodsItem.goodsNameJoin.split('')
                                                                                    .length > 25
                                                                            "
                                                                            class="box-item"
                                                                            effect="dark"
                                                                            :content="goodsItem.goodsNameJoin"
                                                                            placement="top-start"
                                                                        >
                                                                            <p
                                                                                class="goodName"
                                                                                @click="
                                                                                    goGoodsDetail(
                                                                                        goodsItem.sellingPrice,
                                                                                        goodsItem.skuCode
                                                                                    )
                                                                                "
                                                                            >
                                                                                {{ goodsItem.goodsNameJoin }}
                                                                            </p>
                                                                        </el-tooltip>
                                                                        <p
                                                                            class="goodName"
                                                                            v-if="
                                                                                goodsItem.goodsNameJoin.split('')
                                                                                    .length <= 25
                                                                            "
                                                                            @click="
                                                                                goGoodsDetail(
                                                                                    goodsItem.sellingPrice,
                                                                                    goodsItem.skuCode
                                                                                )
                                                                            "
                                                                        >
                                                                            {{ goodsItem.goodsNameJoin }}
                                                                        </p>
                                                                        <el-tooltip
                                                                            v-if="
                                                                                goodsItem.supplierUserName.split('')
                                                                                    .length > 20 && platform == 2
                                                                            "
                                                                            class="box-item"
                                                                            effect="dark"
                                                                            :content="goodsItem.supplierUserName"
                                                                            placement="top-start"
                                                                        >
                                                                            <p class="supplierName">
                                                                                <span
                                                                                    class="mySupplierName"
                                                                                    v-if="goodsItem.isMySupplier"
                                                                                    >供</span
                                                                                ><span>{{
                                                                                    goodsItem.supplierUserName
                                                                                }}</span>
                                                                            </p>
                                                                        </el-tooltip>
                                                                        <p
                                                                            class="supplierName"
                                                                            v-if="
                                                                                goodsItem.supplierUserName.split('')
                                                                                    .length <= 20 && platform == 2
                                                                            "
                                                                        >
                                                                            <span
                                                                                class="mySupplierName"
                                                                                v-if="goodsItem.isMySupplier"
                                                                                >供</span
                                                                            ><span>{{
                                                                                goodsItem.supplierUserName
                                                                            }}</span>
                                                                        </p>
                                                                        <div
                                                                            style="
                                                                                margin-top: 3px;
                                                                                display: flex;
                                                                                align-items: center;
                                                                            "
                                                                        >
                                                                            <span class="redColor"
                                                                                >￥{{ goodsItem.sellingPrice }}</span
                                                                            ><span
                                                                                class="expirationDate"
                                                                                v-if="
                                                                                    goodsItem.quoteTime !==
                                                                                        '长期有效' &&
                                                                                    goodsItem.sellingPrice.split('')
                                                                                        .length <= 5
                                                                                "
                                                                            >
                                                                                {{ goodsItem.quoteTime }}前有效
                                                                            </span>

                                                                            <span
                                                                                class="expirationDate"
                                                                                v-if="
                                                                                    goodsItem.quoteTime == '长期有效' &&
                                                                                    goodsItem.sellingPrice.split('')
                                                                                        .length <= 5
                                                                                "
                                                                            >
                                                                                {{ goodsItem.quoteTime }}
                                                                            </span>
                                                                            <p
                                                                                class="expirationDate2"
                                                                                v-if="
                                                                                    goodsItem.quoteTime == '长期有效' &&
                                                                                    goodsItem.sellingPrice.split('')
                                                                                        .length > 5
                                                                                "
                                                                            >
                                                                                {{ goodsItem.quoteTime }}
                                                                            </p>
                                                                        </div>
                                                                        <div
                                                                            class="selectGoods"
                                                                            @click="
                                                                                changeGoods(
                                                                                    item.memberNeedCode,
                                                                                    goodsItem.skuCode,
                                                                                    item,
                                                                                    goodsItem
                                                                                )
                                                                            "
                                                                            v-if="activeName != 3"
                                                                        >
                                                                            选择
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </h3>
                                                    </el-carousel-item>
                                                </el-carousel>

                                                <el-empty
                                                    description="暂无其他推荐"
                                                    v-if="item?.goodsList?.data.length == 0 && item.needSku"
                                                />
                                            </div>
                                            <!--走马灯效果end-->
                                        </div>
                                    </div>
                                </div>
                                <!--<div class="loading-text" v-if="loading">加载中...</div>-->
                                <!--<div class="loading-text" v-if="finish">没有更多了</div>-->
                                <el-empty description="暂无数据" v-if="closeData.goodsList.length == 0" />
                                <el-divider border-style="dotted" v-if="closeData.goodsList.length !== 0" />
                                <div
                                    class="generateOrder"
                                    v-if="closeData.goodsList.length !== 0 && route.query.state != 3"
                                >
                                    <div class="generateOrder_left">
                                        <span>
                                            <span
                                                ><span class="fontColor">采购计划总数:</span>
                                                <span>{{ notQuoteCount + alreadyQuoteCount + closeQuoteCount }}</span
                                                >件</span
                                            >
                                        </span>
                                    </div>
                                </div>
                            </el-tab-pane>
                            <!--已关闭商品end-->
                        </el-tabs>
                        <div
                            v-if="activeName == 2 && platform == 2 && route.query.type == 0"
                            style="display: flex; position: absolute; top: 0%; left: 40%; margin-top: 10px"
                        >
                            <div class="checkDiv">
                                <img
                                    :src="!isLook ? CheckrdImg : ellipse"
                                    :v-model="isLook"
                                    alt=""
                                    @click="SwitchView(isLook, 1)"
                                />
                                <p class="checkFontStyle">按商品视图</p>
                            </div>
                            <div class="checkDiv">
                                <img
                                    :src="isLook ? CheckrdImg : ellipse"
                                    :v-model="!isLook"
                                    alt=""
                                    @click="SwitchView(isLook, 2)"
                                />
                                <p class="checkFontStyle">按供应商视图</p>
                            </div>
                        </div>
                        <div style="display: flex; position: absolute; top: 0; right: 0">
                            <div
                                class="viewDetailss"
                                @click="pushOffer"
                                v-if="activeName == 2 && platform == 2"
                                style="
                                    right: 150px;
                                    border: #0e3177 1px solid;
                                    background-color: transparent;
                                    color: #0e3177;
                                "
                            >
                                推送报价
                            </div>

                            <div
                                style="right: 150px"
                                class="viewDetailss"
                                @click="toQuotedPriceDetail"
                                v-if="activeName == 2 && platform == 2 && generalManager == true"
                            >
                                查看详细报价
                            </div>
                            <div
                                class="viewDetailss"
                                @click="getmySupplierList(null, tableData.data, 1)"
                                v-if="
                                    store.state.memberInfo.isLookOrder == 1 &&
                                    route.query.type == 0 &&
                                    activeName == 0 &&
                                    tableData.data.length !== 0 &&
                                    topTable.data.state != 3 &&
                                    topTable.data.state != 4 &&
                                    store.state.memberInfo.isNeedMyPrice == 0
                                "
                            >
                                邀请我的供应商
                            </div>
                            <div
                                class="viewDetailss"
                                @click="getmySupplierList(null, tableData.goodsList, 1)"
                                v-if="
                                    store.state.memberInfo.isLookOrder == 1 &&
                                    route.query.type == 0 &&
                                    activeName == 2 &&
                                    topTable.data.state != 3 &&
                                    tableData.goodsList.length != 0 &&
                                    store.state.memberInfo.isNeedMyPrice == 0
                                "
                            >
                                邀请我的供应商
                            </div>
                            <!--<p
                class="viewDetails"
                v-if="store.state.memberInfo.isLookOrder == 1"
                @click="getmySupplierList(null, tableData.data)"
              >
                邀请我的供应商
              </p>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="payModalStyle payModalStylehl">
            <el-dialog
                v-model="dialogVisible"
                :close-on-click-modal="false"
                top="13%"
                width="550px"
                title="选择支付方式"
            >
                <div v-loading="loading">
                    <div class="invoice_cons flex">
                        <div
                            class="no_select_invoice flex_row_center_center"
                            :class="{ activeLt: index != 0 }"
                            v-for="(item, index) in paymentList.data"
                            :key="index"
                            @click="handlePayment(index)"
                        >
                            <img :src="selected" v-if="activeIndex == index" alt />
                            <img :src="not_select" v-if="activeIndex != index && item.disabled" alt />
                            <span v-if="item.disabled" style="width: 58px">{{ item.label }}</span>
                            <!--  -->
                            <div class="date_box" v-show="index == 1 && activeIndex == 1 && orderBelongsVal">
                                <el-date-picker
                                    v-model="selectDate"
                                    type="date"
                                    format="YYYY-MM-DD"
                                    value-format="YYYY-MM-DD"
                                    placeholder="请选择日期"
                                    size="small"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <template #footer>
                    <div style="display: flex; justify-content: center">
                        <div class="Clear" @click="dialogVisible = false">取消</div>
                        <div class="Submit2" @click="createOrder" v-if="!loading">确定</div>
                        <div class="Submit3" v-if="loading">确定</div>
                    </div>
                </template>
            </el-dialog>
        </div>
        <el-dialog
            title="更换地址"
            v-model="show_select_address"
            customClass="select_address_width"
            :before-close="handleClose"
            lock-sroll="false"
            width="500px"
        >
            <div class="out_stock_dialog address_con">
                <div
                    v-for="(item, index) in address_list.data"
                    :key="index"
                    @click="selectAddress(index)"
                    :class="{
                        address_item: true,
                        flex_column_start_start: true,
                        select: current_address_index == index
                    }"
                >
                    <span>{{ L["收货人"] }}:{{ item.memberName }}</span>
                    <span>{{ L["联系方式"] }}:{{ item.telMobile }}</span>
                    <div class="address_text" :title="item.addressAll + item.detailAddress">
                        <span>{{ L["收货地址"] }}:</span>
                        <span class="overflow_ellipsis_clamp2">{{ item.addressAll }} {{ item.detailAddress }}</span>
                    </div>
                    <img v-if="current_address_index == index" class="selected" :src="invoice_selected" alt />
                </div>
            </div>
            <div class="btn_con flex_row_center_center">
                <div class="remove red" @click="confirmChangeAddress()" style="margin-top: 10px">确认</div>
            </div>
        </el-dialog>
        <SldAddressOperation
            v-if="addressVisibleFlag"
            @close="close"
            @refreshAddress="refreshAddress"
            :visibleFlag="true"
            :getDetailData="getDetailData"
            :type="type"
            :memberPlanCode="topTable.data.memberPlanCode"
        />

        <div class="ModalStyle">
            <el-dialog v-model="visible" title="操作失败" width="600px">
                <div v-for="item in msgList.data" :key="item">
                    <p style="text-indent: 2em; font-size: 15px; margin-bottom: 10px; line-height: 1.4">
                        {{ item }}
                    </p>
                </div>
                <template #footer>
                    <div class="dialog-footer">
                        <button type="primary" @click="visible = false">知道了</button>
                    </div>
                </template>
            </el-dialog>
        </div>
        <!-- 查看备注中图片弹窗 -->
        <div class="lookImgdialog">
            <el-dialog v-model="descImgVisible" title="查看图片" width="850px">
                <div class="desc-image__preview">
                    <el-image
                        v-for="(url, index) in srcList"
                        :key="index"
                        :src="url"
                        :zoom-rate="1.2"
                        :max-scale="7"
                        :min-scale="0.2"
                        :preview-src-list="srcList"
                        :initial-index="4"
                        fit="cover"
                        :class="{ active: index != 0 }"
                    />
                </div>
            </el-dialog>
        </div>
        <div class="mySupplierQuote">
            <el-dialog
                v-model="dialogTableVisible"
                title="我的供应商报价"
                :width="(MemberPurchaseGoodsParam.supplierUserId && platform == 2) || platform == 1 ? 850 : 741"
            >
                <el-table :data="supplierQuotationList.data" :border="true">
                    <el-table-column type="index" width="50" label="序号" align="center" />
                    <el-table-column property="supplierName" label="供应商名称" width="300" align="center" />
                    <el-table-column property="supplierPrice" label="报价" width="150" align="center" />
                    <el-table-column property="createTime" label="报价时间" width="200" align="center" />
                    <el-table-column
                        label="操作"
                        align="center"
                        v-if="(MemberPurchaseGoodsParam.supplierUserId && platform == 2) || platform == 1"
                    >
                        <template #default="scope">
                            <span
                                @click="SecondaryConfirmationDialog(scope.row)"
                                style="cursor: pointer; color: #0e3177ff"
                                v-if="
                                    scope.row.isPlaceAnOrder == 0 &&
                                    ((MemberPurchaseGoodsParam.supplierUserId && platform == 2) || platform == 1)
                                "
                                >下单</span
                            >
                            <span
                                style="cursor: not-allowed; color: #ccc"
                                v-if="
                                    scope.row.isPlaceAnOrder == 0 &&
                                    !MemberPurchaseGoodsParam.supplierUserId &&
                                    platform == 2
                                "
                                >/</span
                            >

                            <span style="cursor: pointer" v-if="scope.row.isPlaceAnOrder == 1">已下单</span>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    style="margin-top: 10px"
                    v-if="MemberPurchaseGoodsParam.total != 0"
                    v-model:current-page="MemberPurchaseGoodsParam.pageNum"
                    v-model:page-size="MemberPurchaseGoodsParam.pageSize"
                    :page-sizes="[5, 10, 15, 20]"
                    :small="small"
                    :disabled="disabled"
                    :background="background"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="MemberPurchaseGoodsParam.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                />
            </el-dialog>

            <el-dialog
                v-model="SupplierVisible"
                title="我的供应商"
                :width="710"
                :close-on-click-modal="false"
                :before-close="closeModal"
            >
                <div style="position: relative">
                    <el-form ref="ruleFormRef" :model="mySupplierParam">
                        <el-form-item label="供应商名称" prop="supplierName">
                            <el-input v-model="mySupplierParam.supplierName" style="width: 195px" size="small" />
                        </el-form-item>
                        <div class="searchBtn2">
                            <div class="search" @click="onSubmits()">搜索</div>
                            <el-button @click="resetForms(ruleFormRef)" size="small">重置</el-button>
                        </div>
                    </el-form>
                </div>

                <el-table
                    :data="mySupplierList.data"
                    v-model="mySupplierList.data"
                    :border="true"
                    @selection-change="handleSelectionChange"
                >
                    <el-table-column type="selection" width="40" />
                    <el-table-column type="index" width="80" label="序号" align="center" />
                    <el-table-column property="supplierName" label="供应商" width="400" align="center" />
                    <el-table-column label="操作" align="center" width="149">
                        <template #default="scope">
                            <span
                                style="color: #103276ff; margin-right: 10px; cursor: pointer"
                                @click="toSupplierHomePage(scope.row.id, scope.row.supplierName)"
                                v-if="scope.row.status >= 4"
                                >查看</span
                            >
                            <span style="color: #103276ff; cursor: pointer" @click="inviteQuote([scope.row.id])"
                                >邀请报价</span
                            >
                        </template>
                    </el-table-column>
                </el-table>
                <div style="display: flex; justify-content: center">
                    <el-pagination
                        size="small"
                        class="mt-4"
                        style="margin-top: 10px"
                        v-if="mySupplierParam.total != 0"
                        v-model:current-page="mySupplierParam.pageNum"
                        v-model:page-size="mySupplierParam.pageSize"
                        :page-sizes="[10, 20, 30, 40]"
                        :small="small"
                        :disabled="disabled"
                        :background="background"
                        :total="mySupplierParam.total"
                        @size-change="handleSizeChanges"
                        @current-change="handleCurrentChanges"
                    />
                </div>
                <template #footer>
                    <div style="display: flex; justify-content: flex-end">
                        <div style="display: flex; margin-bottom: 10px">
                            <div class="search2" @click="addSupplier()">邀请供应商</div>
                            <div class="search3" @click="inviteQuote(SupplietParam?.supplierIdList)">邀请报价</div>
                        </div>
                    </div>
                </template>
            </el-dialog>
        </div>
        <div class="SecondaryConfirmationDialog">
            <el-dialog
                v-model="SecondaryConfirmationVisible"
                :show-close="false"
                top="15%"
                :width="350"
                :close-on-click-modal="false"
            >
                <div style="border-radius: 5px">
                    <div class="my-header">
                        <div class="modalTitle">
                            <img src="../../../assets/supplier_warning.png" alt="" /><span>提示</span>
                        </div>
                        <div
                            style="font-size: 24px; color: rgb(163 158 158); cursor: pointer"
                            @click="SecondaryConfirmationVisible = false"
                        >
                            ×
                        </div>
                    </div>
                    <div v-if="code !== 200" class="result">
                        <p>是否确定下单？</p>
                        <div style="display: flex; justify-content: center" class="result_btn">
                            <div
                                class="Clear"
                                @click="SecondaryConfirmationVisible = false"
                                style="width: 63px; height: 33px; line-height: 33px"
                            >
                                取消
                            </div>
                            <div
                                class="Submit2"
                                @click="toOrder()"
                                style="width: 63px; height: 33px; line-height: 33px"
                            >
                                确定
                            </div>
                        </div>
                        <div></div>
                    </div>
                </div>
            </el-dialog>
        </div>
        <div class="remarkModal">
            <el-dialog v-model="remarkVisible" title="选择原因" :width="400" :before-close="handleClose">
                <div class="writeRemark">
                    <p>
                        <span class="fontColor">选择商品：</span
                        ><span class="FontWeight">{{ remarkParam.goodsName }}</span>
                    </p>
                    <p>
                        <span class="fontColor"> 供应商：</span
                        ><span class="FontWeight">{{ remarkParam.supplierName }}</span>
                    </p>
                    <p>
                        <span class="fontColor">选择原因：</span
                        ><span class="Remark">{{ remarkParam.chooseCause ? remarkParam.chooseCause : "无" }}</span>
                    </p>
                </div>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import { reactive, getCurrentInstance, ref, onMounted, onBeforeUnmount, onUnmounted } from "vue";
import SldAddressOperation from "../../../components/PlanAddressOperation.vue";
import SupplierView from "./SupplierView.vue";
import CheckrdImg from "../../../assets/iconImages/Checked@2x.png";
import ellipse from "../../../assets/iconImages/ellipse@2x.png";
import { platform } from "@/utils/config";
import { ElInput, ElMessage, ElMessageBox, ElTimeline, ElTimelineItem } from "element-plus";
// import { Search } from '@element-plus/icons-vue'
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { apiUrl } from "../../../utils/config";

import axios from "axios";
import SldCommonEmpty from "../../../components/SldCommonEmpty";
import MemberTitle from "../../../components/MemberTitle";

import download from "downloadjs";
export default {
    name: "order-list",
    components: {
        ElInput,
        ElTimeline,
        ElTimelineItem,
        SldCommonEmpty,
        MemberTitle,
        SldAddressOperation,
        SupplierView
    },
    setup() {
        // const balance = require("../../assets/buy/balance.png");
        const store = useStore();
        const router = useRouter();
        const route = useRoute();
        //const loading = ref(true)
        const descImgVisible = ref(false); //备注中图片弹窗
        const srcList = ref([]); //备注中图片url集合
        const nextPageLoaded = ref(false); // 是否已加载下一页
        const memberInfo = reactive({ data: store.state.memberInfo });
        const { proxy } = getCurrentInstance();
        const addressVisibleFlag = ref(false);
        const visible = ref(false);
        const L = proxy.$getCurLanguage();
        const not_select = require("../../../assets/buy/not_select.png");
        const selected = require("../../../assets/buy/selected.png");
        const invoice_selected = require("../../../assets/buy/invoice_selected.png");
        const current_state = ref(""); //当前列表订单状态
        const evaluate_state = ref(0); //是否是待评价
        const fits = ["fill", "contain", "cover", "none", "scale-down"];
        const SecondaryConfirmationVisible = ref(false);
        const ruleFormRef = ref();
        const multipleTableRef = ref();
        const isChoose = ref(false);
        const dialogVisible = ref(false);
        const AllMoney = ref(0);
        const AllMoney2 = ref(0);
        const generalManager = ref("");
        const show_select_address = ref(false);
        const address_list = reactive({ data: [] });
        const topTable = reactive({ data: [] });
        const prevNeedCode = ref(null);
        const orderBelongsVal = ref(false); //是否是三方订单
        const activeIndex = ref(0);
        const selectDate = ref("");
        const activeName = ref("0");
        const count = ref("1");
        const ischeckedAll = ref(false);
        const payData = reactive({ data: {} }); //会员拥有的支付、付款数据
        const msgList = reactive({ data: [] });
        const loading = ref(false);
        const finish = ref(false);
        const notQuoteCount = ref(0);
        const alreadyQuoteCount = ref(0);
        const closeQuoteCount = ref(0);
        const isLook = ref(false);
        const carousel = ref(null);
        const mySupplierList = reactive({ data: [] });
        const remarkVisible = ref(false);
        const SupplierVisible = ref(false);
        const isAll = ref();
        const SupplierViewData = reactive({
            data: [],
            goodsList: [],
            needCount: {}
        });
        let remarkParam = reactive({
            goodsId: "",
            memberNeedCode: "",
            chooseCause: "",
            goodsName: ""
        });
        const SupplietParam = reactive({
            extMemberOrderDetailList: [
                {
                    id: "",
                    memberNeedCode: "",
                    supplierUserId: ""
                }
            ],
            supplierIdList: [],
            memberNeedCode: []
        });
        const createOrderParam = reactive({
            memberId: store.state.memberInfo.memberId,
            purchasePlanCode: "",
            orderRemark: "",
            extMemberOrderDetailList: [
                {
                    id: "",
                    memberNeedCode: "",
                    supplierUserId: ""
                }
            ]
        });
        const mySupplierParam = reactive({
            memberId: store.state.memberInfo.memberId,
            supplierName: "",
            pageNum: 1,
            pageSize: 10,
            total: 0,
            memberNeedCode: [],
            supplierIdList: []
        });
        const dialogTableVisible = ref(false);
        const supplierQuotationList = reactive({ data: [] });
        const currentSlide = ref(0);
        const validateNumber = (rule, value, callback) => {
            return new Promise((resolve, reject) => {
                if (isNaN(value)) {
                    reject(new Error("请输入正确报价"));
                } else {
                    resolve();
                }
            });
        };
        const MemberPurchaseGoodsParam = reactive({
            memberId: store.state.memberInfo.memberId,
            memberNeedCode: "",
            isChoose: "",
            pageNum: 1,
            pageSize: 10,
            total: 0
        });
        const pushOffer = () => {
            proxy
                .$get("api/shopp/purchase/plan/sendWeChatMessageTemplate", {
                    memberPlanCode: route.query.memberPlanCode
                })
                .then((res) => {
                    if (res.data == true) ElMessage({ message: "发送成功", type: "success" });
                });
        };
        const rules = {
            myPrice: [
                { required: true, message: "请输入供应商报价", trigger: "blur" },
                {
                    validator: validateNumber,
                    message: "请输入正确报价"
                }
            ]
        };
        const paymentList = reactive({
            data: [
                //企业会员
                { label: "预付全款", value: 1, payway: "0", disabled: false },
                { label: "货到付款", value: 2, payway: "2", disabled: false },
                { label: "账期支付", value: 3, payway: "3", disabled: false }
            ]
        });
        const pageData = reactive({
            pageNum: 1,
            pageSize: 5,
            total: 0,
            totalPage: 0
        });
        const SupplierPageData = reactive({
            pageNum: 1,
            pageSize: 5,
            total: 0,
            totalPage: 0
        });
        const waitPageData = reactive({
            pageNum: 1,
            pageSize: 5,
            total: 0,
            totalPage: 0
        });
        const multipleSelection = ref([]);
        const multipleSelection2 = reactive({
            data: []
        });
        const current_address_index = ref(0);
        const type = ref("add");
        const onSubmits = () => {
            mySupplier(mySupplierParam);
        };
        //切换视图
        const SwitchView = (value, type) => {
            console.log(value, 7878);
            if (value == false && type == 1) {
                return;
            } else if (value == true && type == 2) {
                return;
            } else {
                isLook.value = !value;
                if (value == false) {
                    SupplierPageData.pageNum = 1;
                    multipleSelection2.data = [];
                    AllMoney2.value = 0;
                    getSupplierViewData([]);
                } else {
                    pageData.pageNum = 1;
                    AllMoney.value = 0;
                    multipleSelection.value = [];
                    getFinishData("", [], activeName.value);
                }

                //        isLook.value = !value;
                //getSupplierViewData([]);
                //ElMessageBox.confirm("切换后所选商品需重新选择!", "提示",  {
                //  confirmButtonText: "确定",
                //  cancelButtonText: "取消",
                //  type: "warning",
                // confirmButtonClass:'ExitConfirmButton'
                //})
                //  .then(() => {
                //    proxy
                //      .$get(
                //        "api/shopp/purchase/plan/updatePlanView",
                //        { memberPlanCode: route.query.memberPlanCode ,viewType:isLook.value==false?1:2},
                //        "json"
                //      )
                //      .then((res) => {
                //        if (res.code == 200) {
                //          if(isLook.value)

                //        } else {
                //          //ElMessage(res.msg);
                //        }
                //      });
                //  })
                //  .catch(() => {
                //    // catch error
                //  });
            }

            //console.log(isLook.value);
        };
        //获取供应商视图数据
        const getSupplierViewData = (data) => {
            proxy
                .$get("api/shopp/purchase/plan/planDetailSupplierShowPage", {
                    memberPlanCode: route.query.memberPlanCode,
                    ...SupplierPageData
                    //state: 0,
                })
                .then((res) => {
                    if (res.code == 200) {
                        nextPageLoaded.value = false;
                        console.log(12121);
                        SupplierViewData.goodsList = res.data.records;
                        SupplierPageData.pageNum = res.data.pageNum;
                        SupplierPageData.pageSize = res.data.pageSize;
                        SupplierPageData.total = res.data.total;
                        SupplierPageData.totalPage = res.data.totalPage;
                        console.log(data, 6756);

                        console.log(SupplierViewData.data);

                        //payData.data = res.data;
                        SupplierViewData.goodsList.forEach((item) => {
                            console.log(item);
                            item.goodsList = {
                                data: [],
                                data1: [],
                                pageNum: 1,
                                pageSize: 5,
                                total: 1,
                                totalPage: 1
                            };
                            item.ischeckedAll = false;
                            item.quoteAlMoney = 0;
                            item.num = [];
                            proxy
                                .$get("api/shopp/purchase/plan/planDetailSupplierShowGoodsPage", {
                                    memberPlanCode: route.query.memberPlanCode,
                                    supplierId: item.supplierUserId
                                    //pageNum: item.goodsList.pageNum,
                                    //pageSize: item.goodsList.pageSize,
                                })
                                .then((res) => {
                                    if (res.code == 200) {
                                        if (res.data) {
                                            item.goodsList.data1 = res.data;
                                            item.goodsList.data = res.data;

                                            if (item.goodsList.data1.some((it) => it.sellingPrice != "***")) {
                                                let total = 0;
                                                item.goodsList.data1.forEach((its) => {
                                                    if (its.sellingPrice != "***") {
                                                        isChoose.value = true;

                                                        total +=
                                                            Math.ceil(
                                                                (Number(its.sellingPrice) *
                                                                    100 *
                                                                    (Number(its.purchaseNum) * 10000)) /
                                                                    1000
                                                            ) / 1000;
                                                    }
                                                });
                                                item.quoteAlMoney = total.toFixed(2);
                                            } else {
                                                item.quoteAlMoney = "***";
                                            }
                                            //item.quoteAlMoney;

                                            //} else {
                                            //  item.goodsList.data1.forEach((its) => {
                                            //    its.purchaseNum2 = Number(its.purchaseNum);
                                            //    item.quoteAlMoney = "***";
                                            //  });
                                            //}
                                            item.ischeckedAll = item.goodsList.data1.every(
                                                (it) => it.sellingPrice != "***"
                                            );
                                            item.isCancel = false;

                                            item.goodsList.data1.forEach((its) => {
                                                its.purchaseNum2 = Number(its.purchaseNum);
                                                item.num.push(its.memberNeedCode);
                                                if (its.isBestGoods == 1) {
                                                    multipleSelection2.data.push({ ...item, ...its });
                                                }
                                            });
                                            item.num = [...new Set(item.num)];
                                            supplierMoney();
                                            console.log(multipleSelection2.data, 6666666);
                                            //console.log(
                                            //  (item.ischeckedAll = item.goodsList.data1.every(
                                            //    (it) => it.isChoose == 1
                                            //  )),
                                            //  4545
                                            //);

                                            item.goodsList.pageNum = 1;
                                            item.goodsList.pageSize = 5;

                                            item.goodsList.data = item.goodsList.data1;

                                            item.goodsList.totalPage =
                                                res.data.length % 5 === 0
                                                    ? res.data.length / 5
                                                    : Math.floor(res.data.length / 5 + 1);
                                            //item.goodsList.data = item.goodsList.data1.slice(0, 5);
                                            let goodsList = [];
                                            for (let i = 0; i < item.goodsList.totalPage; i++) {
                                                const arr = item.goodsList.data.slice(i * 5, i * 5 + 5);
                                                goodsList.push(arr);
                                            }
                                            item.goodsList.data = goodsList;
                                            item.goodsList.total = item.goodsList.data.length;
                                        } else {
                                            item.goodsList.data = [];
                                        }
                                    } else {
                                        ElMessage(res.msg);
                                    }
                                })
                                .catch(() => {
                                    //ElMessage(res.msg);
                                });
                        });
                        //tableData.goodsList = [...tableData.goodsList];
                        SupplierViewData.goodsList = [...data, ...SupplierViewData.goodsList];
                    }
                });
        };

        //** 查询会员拥有的支付方式和付款方式 */
        const getPayWays = () => {
            let params = { memberId: store.state.memberInfo.memberId };
            proxy.$get("api/shopp/member/order/member/pay/ways", params).then((res) => {
                if (res.code == 200) {
                    payData.data = res.data;
                }
            });
        };

        //选择支付方式
        const handlePayment = (e) => {
            activeIndex.value = e;
        };

        //初始化支付方式
        const initPayment = () => {
            let arr = [];
            if (!isLook.value) {
                arr = multipleSelection.value;
                for (let i = 0; i < arr.length; i++) {
                    // ** 检查是否是 三方订单
                    if (arr[i].needSku.orderBelongs == 2) {
                        orderBelongsVal.value = true;
                    }
                }
                let everyVal = arr.every((item) => item.needSku.orderBelongs == 1);
                if (everyVal) {
                    orderBelongsVal.value = false;
                }

                let payways = payData.data.payWays;
                for (let i = 0; i < arr.length; i++) {
                    payways = payways.filter((e) => arr[i].needSku.payWays?.includes(e));
                }
                paymentList.data.forEach((it) => {
                    console.log(payways);
                    it.disabled = payways.includes(it.payway);
                    console.log(payways.includes(it.payway));
                });
                console.log(paymentList);
            } else {
                arr = multipleSelection2.data;
                console.log(multipleSelection2.data);
                for (let i = 0; i < arr.length; i++) {
                    console.log(arr[i].orderBelongs, 4545);
                    // ** 检查是否是 三方订单
                    if (arr[i].orderBelongs == 2) {
                        orderBelongsVal.value = true;
                    }
                }
                let everyVal = arr.every((item) => item.orderBelongs == 1);
                if (everyVal) {
                    orderBelongsVal.value = false;
                }
                let payways = payData.data.payWays;
                for (let i = 0; i < arr.length; i++) {
                    console.log(arr[i].payWays);

                    payways = payways.filter((e) => arr[i].payWays.includes(e));
                }
                paymentList.data.forEach((it) => {
                    console.log(payways);
                    it.disabled = payways.includes(it.payway);
                    console.log(payways.includes(it.payway));
                });
            }
        };

        //关闭弹窗
        const close = () => {
            addressVisibleFlag.value = false;
        };
        //弹出新建地址窗口
        const addAddress = () => {
            addressVisibleFlag.value = true;
            getDetailData();
        };
        const refreshAddress = (addressInfo, addressId) => {
            topTable.data.receiverAddress = addressInfo.addressAll;
            topTable.data.receivingMobile = addressInfo.telMobile;
            topTable.data.receivingName = addressInfo.memberName;
        };

        //tab切换
        const handleClick = (tab, event) => {
            if (activeName.value == 0) {
            } else if (activeName.value == 2) {
                pageData.pageNum = 1;
                pageData.pageSize = 5;
                if (!isLook.value) {
                    getFinishData("", [], activeName.value);
                    SupplietParam.memberNeedCode = [];
                } else {
                    getSupplierViewData([]);
                    SupplietParam.memberNeedCode = [];
                }

                multipleSelection.value = [];
                ischeckedAll.value = false;
            } else if (activeName.value == 3) {
                pageData.pageNum = 1;
                pageData.pageSize = 5;
                pageData.total = 0;
                pageData.totalPage = 0;
                getFinishData("", [], activeName.value);
                multipleSelection.value = [];
                ischeckedAll.value = false;
            }
        };
        // 获取收货地址
        const getAddressList = () => {
            let params = { uscUserId: store.state.memberInfo.uscUserId, pageSize: 1000 };
            proxy
                .$get("api/shopp/member/address/list", params)
                .then((res) => {
                    if (res.code == 200) {
                        address_list.data = res.data;
                        //if (res.data.list.length > 0 && !orderAddress.data.addressId) {
                        //  var default_address = res.data.list.filter(function (item) {
                        //    return item.isDefault == 1;
                        //  });
                        //  if (default_address.length > 0) {
                        //    orderAddress.data = default_address[0];
                        //  } else {
                        //    orderAddress.data = res.data.list[0];
                        //  }
                        //  if (localStorage.getItem('addressId')) {
                        //    let addressID = localStorage.getItem('addressId')
                        //    if (res.data.list.filter(i => i.addressId == addressID)[0]) {
                        //      orderAddress.data = res.data.list.filter(i => i.addressId == addressID)[0]

                        //    }
                        //  }
                        //  for(let i=0;i<address_list.data.length;i++){
                        //    if(orderAddress.data.addressId==address_list.data[i].addressId){
                        //      current_address_index.value = i
                        //      break
                        //    }
                        //  }

                        //}

                        //confirmOrder(1);
                    } else {
                        confirmOrder(2);
                        ElMessage(res.msg);
                    }
                })
                .catch(() => {
                    //异常处理
                });
        };
        const tableData = reactive({
            data: [],
            goodsList: [],
            needCount: {}
        });
        const closeData = reactive({
            data: [],
            goodsList: [],
            needCount: {}
        });
        let param = reactive({
            memberId: store.state.memberInfo.memberId,
            memberPlanCode: "",
            source: "",
            prayName: "",
            startDate: "",
            endDate: "",
            resource: "",
            state: "",
            pageNum: 1,
            pageSize: 10
        });

        //去商品详情
        const goGoodsDetail = (sellingPrice, skuCode) => {
            if (sellingPrice !== "***") {
                router.push({
                    name: "GoodsDetail",
                    query: {
                        productId: skuCode
                    }
                });
            }
        };

        //返回
        const goback = () => {
            //router.push({
            //  path: "/member/procurement/procurementPlanList",
            //  query: {
            //    //planCode: code,
            //    //state: state,
            //    pageNum: route.query.pageNum,
            //    pageSize: route.query.pageSize,
            //  },
            //});
            window.location.href = "about:blank";
            window.close();
        };
        //获取详情头部信息
        const getDetailData = () => {
            console.log(route.query);
            proxy

                .$get("api/shopp/purchase/plan/queryPlan", {
                    memberPlanCode: route.query.memberPlanCode
                    //state: route.query.state,
                })
                .then((res) => {
                    if (res.code == 200) {
                        console.log(topTable.data.viewType);
                        isLook.value = route.query.isLook == 1 ? true : false;
                        topTable.data = res.data;
                    } else {
                        ElMessage(res.msg);
                    }
                })
                .catch(() => {
                    //异常处理
                });
        };
        //获取待报价商品数据
        const getWaitData = (item) => {
            proxy
                .$get("api/shopp/purchase/plan/queryPlanDetailGoodsShow", {
                    memberPlanCode: route.query.memberPlanCode,
                    state: 0,
                    ...waitPageData
                })
                .then((res) => {
                    if (res.code == 200) {
                        nextPageLoaded.value = false;
                        tableData.data = [...item, ...res.data.records];
                        waitPageData.pageNum = res.data.pageNum;
                        waitPageData.pageSize = res.data.pageSize;
                        waitPageData.total = res.data.total;
                        waitPageData.totalPage = res.data.totalPage;
                        console.log(tableData.data);
                    }
                })
                .catch(() => {
                    //异常处理
                });
        };
        //获取详情商品信息
        const getDetailGoodsData = () => {
            waitPageData.pageNum = 1;
            getWaitData([]);
            //getFinishData("", []);
        };
        //切换地址
        const selectAddress = (index) => {
            current_address_index.value = index;
        };
        //确认修改地址
        const confirmChangeAddress = () => {
            let param = {
                memberPlanCode: route.query.memberPlanCode,
                receivingName: address_list.data[current_address_index.value].memberName,
                receivingMobile: address_list.data[current_address_index.value].telMobile,
                receivingAddress: `${address_list.data[current_address_index.value].addressAll},${
                    address_list.data[current_address_index.value].detailAddress
                }`,
                receivingAddressCode: `${address_list.data[current_address_index.value].provinceCode},${
                    address_list.data[current_address_index.value].cityCode
                },${address_list.data[current_address_index.value].districtCode},`
            };

            proxy.$post("api/shopp/purchase/plan/updatePlan", param, "json").then((res) => {
                if (res.code == 200) {
                    show_select_address.value = false;
                    getDetailData();
                } else {
                    ElMessage(res.msg);
                }
            });

            localStorage.setItem("addressId", address_list.data[current_address_index.value].addressId);
        };

        //弹出地址选择框
        const changeAddress = () => {
            getAddressList("change");
            show_select_address.value = true;
            getDetailData();
        };

        //关闭计划
        const closeOrder = () => {
            let param = {
                memberPlanCode: route.query.memberPlanCode
            };

            proxy
                .$get("api/shopp/purchase/plan/closePurchasePlan", param)
                .then((res) => {
                    if (res.code == 200) {
                        //const index = tableData.goodsList.findIndex(
                        //  (item) => item.memberNeedCode === i
                        //);
                        //tableData.goodsList[index].isShow = false;
                        //getDetailData();

                        //console.log(tableData.goodsList);
                        ElMessage({
                            message: "操作成功",
                            type: "success"
                        });
                        window.location.href = "about:blank";
                        window.close();
                    } else {
                        ElMessage(res.msg);
                    }
                })
                .catch(() => {
                    //异常处理
                });
        };

        //编辑
        const handleEdit = () => {
            router.push({
                path: "/member/procurement/editQuotationDetail",
                query: {
                    memberPlanCode: topTable.data.memberPlanCode,
                    state: topTable.data.state,
                    goodsNum: route.query.goodsNum,
                    pageNum: 1,
                    pageSize: 10,
                    type: route.query.type,
                    editType: 1
                }
            });
        };

        //关闭
        const handleCloseItem = (memberNeedCode) => {
            proxy
                .$get("api/shopp/purchase/plan/closePurchaseNeeds", {
                    memberNeedCode,
                    memberPlanCode: topTable.data.memberPlanCode
                })
                .then((res) => {
                    if (res.code == 200) {
                        ElMessage.success(res.msg);
                        getTabsCount();
                        if (activeName.value && activeName.value != 0) {
                            getFinishData("", [], activeName.value);
                        } else {
                            getDetailGoodsData();
                        }
                    } else {
                        ElMessage(res.msg);
                    }
                })
                .catch(() => {});
        };

        //生成计划

        const createOrder = () => {
            loading.value = true;
            //console.log(!isLook);
            if (!isLook.value) {
                topTable.data.planDetails = multipleSelection.value;

                let data = topTable.data.planDetails.map((item) => {
                    return (item = { ...item, ...item.needSku });
                });
                topTable.data.planDetails = data;
                //topTable.data.planDetails.forEach((item) => {
                //  item.goodsNum = item.purchaseNum;
                //});
            } else {
                topTable.data.planDetails = multipleSelection2.data;
                topTable.data.planDetails.map((item) => {
                    item.goodsNum = item.purchaseNum2;

                    //console.log(item.sellingPrice);
                    if (item.sellingPrice != "***") {
                        item.goodsPrice = item.sellingPrice;
                    } else {
                        return ElMessage({
                            dangerouslyUseHTMLString: true,
                            message: `${item.goodsName}还未开价`,
                            type: "waring"
                        });
                    }
                });
            }
            console.log(topTable.data.planDetails);
            topTable.data.submitType = 1;
            topTable.data.memberId = store.state.memberInfo.memberId;
            topTable.data.memberName = store.state.memberInfo.memberName;
            topTable.data.orderPayType = activeIndex.value == 1 ? 2 : activeIndex.value == 2 ? 3 : 0;

            if (activeIndex.value == 1 && orderBelongsVal) {
                topTable.data.arriveDate = selectDate.value;
            }
            topTable.data.planDetails.forEach((item) => {
                item.purchaseNum = item.goodsNum;
            });
            if (Object.keys(topTable.data.planDetails).length) {
                proxy
                    .$post("api/shopp/purchase/plan/createOrder", topTable.data, "json")
                    .then((res) => {
                        if (res.code == 200) {
                            loading.value = false;
                            ElMessage({
                                message: "操作成功",
                                type: "success"
                            });
                            //** 货到付款 或者 账期支付 或者 是待审批订单
                            if (activeIndex.value == 1 || activeIndex.value == 2 || res.data[0].orderState == 1) {
                                return router.replace({
                                    path: "/member/order/list"
                                });
                            }
                            //** 预付全款 并且 不是待审批订单
                            let paySn = res.data.map((obj) => obj.paymentNumber);
                            // return console.log(paySn)
                            proxy.$sldStatEvent({ behaviorType: "buy" });
                            router.replace({
                                path: "/buy/pay",
                                query: {
                                    paySn: paySn,
                                    payFrom: 1
                                }
                            });
                        } else {
                            ElMessage(res.msg);
                        }
                    })
                    .catch(() => {
                        //异常处理
                    });
            } else {
                ElMessage({
                    message: "请选择商品",
                    type: "warning"
                });
            }
        };

        const selectPaymentMethod = () => {
            if (!isLook.value) {
                topTable.data.planDetails = multipleSelection.value;

                if (Object.keys(topTable.data.planDetails).length) {
                    initPayment();
                    activeIndex.value = 0;

                    topTable.data.planDetails = multipleSelection.value;

                    let data = topTable.data.planDetails.map((item) => {
                        return (item = { ...item, ...item.needSku });
                    });
                    topTable.data.planDetails = data;

                    topTable.data.submitType = 1;
                    topTable.data.memberId = store.state.memberInfo.memberId;
                    topTable.data.memberName = store.state.memberInfo.memberName;
                    topTable.data.orderPayType = activeIndex.value == 1 ? 2 : activeIndex.value == 2 ? 3 : 0;

                    if (activeIndex.value == 1 && orderBelongsVal) {
                        topTable.data.arriveDate = selectDate.value;
                    }
                    topTable.data.planDetails.forEach((item) => {
                        item.purchaseNum = item.goodsNum;
                    });
                    if (Object.keys(topTable.data.planDetails).length) {
                        proxy
                            .$post("api/shopp/purchase/plan/checkPlan", topTable.data, "json")
                            .then((res) => {
                                if (res.code == 200) {
                                    dialogVisible.value = true;
                                } else {
                                    //visible.value = true;
                                    //msgList.data = res.msg.split(",");
                                    //console.log(msgList);
                                    ElMessage(res.msg);
                                }
                            })
                            .catch(() => {
                                //异常处理
                            });
                    } else {
                        ElMessage({
                            message: "请选择商品",
                            type: "warning"
                        });
                    }
                } else {
                    ElMessage({
                        message: "请选择商品",
                        type: "warning"
                    });
                }
            } else {
                let arrs = [];
                if (multipleSelection2.data.length != 0) {
                    let isTrue = true;
                    multipleSelection2.data.map((item) => {
                        if (item.sellingPrice == "***") {
                            isTrue = false;
                            console.log(item);

                            arrs.push(item.goodsNameJoin);
                        }
                    });
                    if (arrs.length != 0) {
                        return ElMessage({
                            dangerouslyUseHTMLString: true,
                            message: `“${arrs.map((i) => {
                                return i;
                            })}”、商品还未开价`,
                            type: "waring"
                        });
                    }
                    if (isTrue) {
                        initPayment();
                        activeIndex.value = 0;
                        multipleSelection2.data.forEach((its) => {
                            its.goodsNum = its.purchaseNum2;
                        });

                        topTable.data.planDetails = [...multipleSelection2.data];
                        topTable.data.submitType = 1;
                        topTable.data.memberId = store.state.memberInfo.memberId;
                        topTable.data.memberName = store.state.memberInfo.memberName;
                        topTable.data.orderPayType = activeIndex.value == 1 ? 2 : activeIndex.value == 2 ? 3 : 0;

                        if (activeIndex.value == 1 && orderBelongsVal) {
                            topTable.data.arriveDate = selectDate.value;
                        }

                        if (Object.keys(topTable.data.planDetails).length) {
                            proxy
                                .$post("api/shopp/purchase/plan/checkPlan", topTable.data, "json")
                                .then((res) => {
                                    if (res.code == 200) {
                                        dialogVisible.value = true;
                                    } else {
                                        //visible.value = true;
                                        //msgList.data = res.msg.split(",");
                                        //console.log(msgList);
                                        ElMessage(res.msg);
                                    }
                                })
                                .catch(() => {
                                    //异常处理
                                });
                        } else {
                            ElMessage({
                                message: "请选择商品",
                                type: "warning"
                            });
                        }
                    }
                } else {
                    ElMessage({
                        message: "请选择商品",
                        type: "warning"
                    });
                }
            }
        };

        //单选
        const checkedItem = (item, index) => {
            if (item.ischecked == false) {
                item.ischecked = true;
                multipleSelection.value.push(item);

                tableData.goodsList.forEach((items) => {
                    if (items.isChoose == 1) {
                        if (items.ischecked == false) {
                            return (ischeckedAll.value = false);
                        } else {
                            ischeckedAll.value = true;
                        }
                    }
                });
            } else if (item.ischecked == true) {
                item.ischecked = false;
                ischeckedAll.value = false;

                let newArray = multipleSelection.value.filter((items) => items.memberNeedCode !== item.memberNeedCode);
                //.slice(0, index)
                //.concat(multipleSelection.value.slice(index + 1));

                multipleSelection.value = newArray;
            }

            if (multipleSelection.value) {
                let total = 0;

                for (const item of multipleSelection.value) {
                    const { goodsPrice, goodsNum } = item.needSku;
                    total += Math.ceil((Number(goodsPrice) * 1000 * (Number(goodsNum) * 1000)) / 1000) / 1000;
                }
                let numStr = total.toString();
                // 分割小数点前后的部分
                let parts = numStr.split(".");

                console.log(total);
                // 如果小数点后已经有两位，直接返回原数
                if (parts[1] && parts[1].length == 2) {
                    console.log(988);
                    AllMoney.value = total.toFixed(2);
                }
                // 如果小数点后只有一位，且第三位大于1，进位处理
                else if (parts[1] && parts[1].length == 3 && +parts[1][2] >= 1) {
                    console.log(987);
                    parts[1] = parts[1][0] + (parseFloat(parts[1][1]) + 1);
                    AllMoney.value = parseFloat(parts.join(".")).toFixed(2);
                } else {
                    AllMoney.value = total.toFixed(2);
                }

                //AllMoney.value = (parseFloat(total.toString()) + 0.004).toFixed(2);
            }
        };
        //全选
        const checkedAll = (ischeckedAllState) => {
            multipleSelection.value = [];

            if (ischeckedAll.value == false) {
                ischeckedAll.value = true;
                tableData.goodsList.forEach((item, index) => {
                    if (item.isChoose == 1) {
                        //if(ischeckedAll.value==true){
                        item.ischecked = true;
                        multipleSelection.value.push(item);
                        if (multipleSelection.value) {
                            let total = 0;

                            for (const items of multipleSelection.value) {
                                const { goodsPrice, goodsNum } = items.needSku;
                                total +=
                                    Math.ceil((Number(goodsPrice) * 1000 * (Number(goodsNum) * 1000)) / 1000) / 1000;
                            }

                            let numStr = total.toString();
                            // 分割小数点前后的部分
                            let parts = numStr.split(".");

                            console.log(total);
                            // 如果小数点后已经有两位，直接返回原数
                            if (parts[1] && parts[1].length == 2) {
                                console.log(988);
                                AllMoney.value = total.toFixed(2);
                            }
                            // 如果小数点后只有一位，且第三位大于1，进位处理
                            else if (parts[1] && parts[1].length == 3 && +parts[1][2] >= 1) {
                                console.log(987);
                                parts[1] = parts[1][0] + (parseFloat(parts[1][1]) + 1);
                                AllMoney.value = parseFloat(parts.join(".")).toFixed(2);
                            } else {
                                AllMoney.value = total.toFixed(2);
                            }
                        }
                    }
                });
            } else {
                ischeckedAll.value = false;
                tableData.goodsList.forEach((item, index) => {
                    //if (item.isChoose == 0) {
                    item.ischecked = false;
                    multipleSelection.value = [];
                    AllMoney.value = 0;
                    //}
                });
            }
        };

        const otherGoodsList = (pageNum, memberNeedCode, item) => {
            proxy
                .$get("api/shopp/purchase/plan/queryQuotePage", {
                    memberNeedCode: memberNeedCode,
                    pageNum: pageNum,
                    pageSize: 5
                })
                .then((res) => {
                    if (res.code == 200) {
                        if (res.data.records.length !== 0) {
                            item.goodsList.data = res.data.records;
                            item.goodsList.pageNum = res.data.pageNum;
                            if (res.data.total <= 5) {
                                item.goodsList.total = 1;
                            } else {
                                //if (Number.isInteger(res.data.total / 6)) {
                                //  item.goodsList.total = res.data.total / 6;
                                //} else {
                                //  item.goodsList.total = Math.floor(res.data.total / 6);
                                //}
                            }
                        } else {
                            item.goodsList.data = [];
                        }
                    } else {
                        ElMessage(res.msg);
                    }
                })
                .catch(() => {});
        };

        const changeGoosList = (pageNum, memberNeedCode, item) => {
            console.log(item.goodsList.total, pageNum);
            if (item.goodsList.totalPage != 1 && pageNum !== item.goodsList.totalPage) {
                otherGoodsList(pageNum + 1, memberNeedCode, item);
            } else if (item.goodsList.total <= 5) {
                return;
            } else if (item.goodsList.totalPage != 1 && pageNum == item.goodsList.totalPage) {
                console.log(1111);
                otherGoodsList(1, memberNeedCode, item);
            } else {
                otherGoodsList(pageNum - 1, memberNeedCode, item);
            }
        };
        //替换最佳推荐
        const changeGoods = (memberNeedCode, skuCode, item, goodsItem) => {
            proxy
                .$get("api/shopp/purchase/plan/editRec", {
                    memberNeedCode: memberNeedCode,
                    skuCode: skuCode
                })
                .then((res) => {
                    if (res.code == 200) {
                        console.log("2222111:", goodsItem);
                        //multipleSelection.value = [];
                        //pageData.pageSize = pageData.total;
                        //pageData.pageNum = 1;
                        //console.log(goodsItem.minNum,7878, item.needSku.minNum);
                        let payWays = goodsItem.payWays;
                        item.needSku = goodsItem;
                        item.payWays = payWays;
                        if (goodsItem.isMySupplier) {
                            orderBelongsVal.value = true;
                        } else {
                            orderBelongsVal.value = false;
                        }
                        item.needSku.goodsPrice = goodsItem.sellingPrice;
                        item.needSku.goodsNum = Number(item.purchaseNum);
                        item.needSku.goodsNum2 = Number(item.purchaseNum);
                        //getFinishData(tableData.goodsList, []);
                        proxy
                            .$get("api/shopp/purchase/plan/queryQuotePage", {
                                memberNeedCode: item.memberNeedCode,
                                pageNum: item.goodsList.pageNum,
                                pageSize: item.goodsList.pageSize
                            })
                            .then((res) => {
                                if (res.code == 200) {
                                    if (res.data.records) {
                                        item.goodsList.data = res.data.records;
                                        item.goodsList.totalPage = res.data.totalPage;
                                        item.goodsList.pageNum = res.data.pageNum;
                                        item.goodsList.pageSize = res.data.pageSize;
                                        item.goodsList.total = res.data.total;

                                        //if (res.data.total <= 6) {
                                        //  item.goodsList.total = Number(1);
                                        //} else {
                                        //  if (Number.isInteger(res.data.total / 6)) {
                                        //    item.goodsList.total = Number(res.data.total / 6);
                                        //  } else {
                                        //    item.goodsList.total = Number(
                                        //      Math.ceil(res.data.total / 6)
                                        //    );
                                        //    console.log(99999);
                                        //  }
                                        //}
                                    } else {
                                        item.goodsList.data = [];
                                    }
                                } else {
                                    ElMessage(res.msg);
                                }
                            })
                            .catch(() => {
                                ElMessage(res.msg);
                            });
                        ElMessage({
                            message: res.msg,
                            type: "success"
                        });
                        if (item.ischecked) {
                            multipleSelection.value.forEach((i, index) => {
                                if (i.memberNeedCode == item.needcode) {
                                    multipleSelection.value.slice(index, 1);
                                }
                            });

                            //  arr.push(item)
                            // multipleSelection.value=arr
                            let total = 0;

                            for (const item of multipleSelection.value) {
                                const { goodsPrice, goodsNum } = item.needSku;
                                total +=
                                    Math.ceil((Number(goodsPrice) * 1000 * (Number(goodsNum) * 1000)) / 1000) / 1000;
                            }
                            let numStr = total.toString();
                            // 分割小数点前后的部分
                            let parts = numStr.split(".");

                            console.log(total);
                            // 如果小数点后已经有两位，直接返回原数
                            if (parts[1] && parts[1].length == 2) {
                                console.log(988);
                                AllMoney.value = total.toFixed(2);
                            }
                            // 如果小数点后只有一位，且第三位大于1，进位处理
                            else if (parts[1] && parts[1].length == 3 && +parts[1][2] >= 1) {
                                console.log(987);
                                parts[1] = parts[1][0] + (parseFloat(parts[1][1]) + 1);
                                AllMoney.value = parseFloat(parts.join(".")).toFixed(2);
                            } else {
                                AllMoney.value = total.toFixed(2);
                            }
                        }
                    } else {
                        ElMessage(res.msg);
                    }
                })
                .catch(() => {
                    ElMessage(res.msg);
                });
        };

        const handleChange = (skuCode, value, type, goodsItem, e) => {
            let str = /^([1-9]\d*|0)(?:\.\d{0,4}[1-9])?$/;

            if (type == "add") {
                //if (goodsItem.goodsNum >= goodsItem.purchaseNum) {
                //  goodsItem.goodsNum = goodsItem.purchaseNum;
                //} else {
                goodsItem.goodsNum++;
                //}
            } else if (type == "edit") {
                if (goodsItem.goodsNum >= goodsItem.purchaseNum) {
                    goodsItem.goodsNum = goodsItem.purchaseNum;
                } else if (str.test(e.currentTarget.value)) {
                    console.log(e.currentTarget.value);
                    let num = String(e.currentTarget.value).replace(/^(.*\..{4}).*$/, "$1");
                    goodsItem.goodsNum = Number(num);
                } else {
                    goodsItem.goodsNum = e.currentTarget.value;
                    if (goodsItem.goodsNum <= goodsItem.minNum) {
                        goodsItem.goodsNum = goodsItem.minNum;
                    } else {
                    }
                }
                //item.goodsNum = Number(value.toFixed(4))
            } else if (type == "reduce") {
                console.log(goodsItem.minNum, 7868);
                if (goodsItem.goodsNum > 1 && goodsItem.goodsNum > goodsItem.minNum) {
                    goodsItem.goodsNum--;
                } else {
                    goodsItem.goodsNum = goodsItem.minNum;
                }
            }
            //item.goodsNum = Number(value.toFixed(4))

            let num = String(goodsItem.goodsNum).replace(/^(.*\..{4}).*$/, "$1");
            goodsItem.goodsNum = Number(num);
            if (multipleSelection.value) {
                let total = 0;

                for (const item of multipleSelection.value) {
                    const { goodsPrice, goodsNum } = item.needSku;
                    total += Math.ceil((Number(goodsPrice) * 1000 * (Number(goodsNum) * 1000)) / 1000) / 1000;
                }
                let numStr = total.toString();
                // 分割小数点前后的部分
                let parts = numStr.split(".");

                console.log(total);
                // 如果小数点后已经有两位，直接返回原数
                if (parts[1] && parts[1].length == 2) {
                    console.log(988);
                    AllMoney.value = total.toFixed(2);
                }
                // 如果小数点后只有一位，且第三位大于1，进位处理
                else if (parts[1] && parts[1].length == 3 && +parts[1][2] >= 1) {
                    console.log(987);
                    parts[1] = parts[1][0] + (parseFloat(parts[1][1]) + 1);
                    AllMoney.value = parseFloat(parts.join(".")).toFixed(2);
                } else {
                    AllMoney.value = total.toFixed(2);
                }
            }
        };
        const handleChange2 = (value) => {
            if (multipleSelection.value) {
                let total = 0;

                for (const item of multipleSelection.value) {
                    const { goodsPrice, goodsNum } = item.needSku;
                    total += Math.ceil((Number(goodsPrice) * 1000 * (Number(goodsNum) * 1000)) / 1000) / 1000;
                }
                let numStr = total.toString();
                // 分割小数点前后的部分
                let parts = numStr.split(".");

                console.log(total);
                // 如果小数点后已经有两位，直接返回原数
                if (parts[1] && parts[1].length == 2) {
                    console.log(988);
                    AllMoney.value = total.toFixed(2);
                }
                // 如果小数点后只有一位，且第三位大于1，进位处理
                else if (parts[1] && parts[1].length == 3 && +parts[1][2] >= 1) {
                    console.log(987);
                    parts[1] = parts[1][0] + (parseFloat(parts[1][1]) + 1);
                    AllMoney.value = parseFloat(parts.join(".")).toFixed(2);
                } else {
                    AllMoney.value = total.toFixed(2);
                }
            }
            //item.needSku.purchaseNum
        };
        const toQuotedPriceDetail = () => {
            router.push({
                path: "/member/procurement/quotedPriceDetail",
                query: {
                    memberPlanCode: route.query.memberPlanCode,
                    memberNeedCode: topTable.data.memberNeedCode,
                    pageNum: route.query.pageNum,
                    pageSize: route.query.pageSize,
                    isLook: isLook.value == false ? 0 : 1,
                    type: route.query.type
                }
            });
        };

        const getGeneralManager = () => {
            proxy
                .$get("api/shopp/purchase/plan/isManager", {
                    memberId: store.state.memberInfo.memberId
                })
                .then((res) => {
                    if (res.code == 200) {
                        generalManager.value = res.data;

                        //ElMessage({
                        //  message: res.msg,
                        //  type: "success",
                        //});
                    } else {
                        ElMessage(res.msg);
                    }
                })
                .catch(() => {
                    ElMessage(res.msg);
                });
        };
        ////去商品详情
        //const goGoodsDetail = (e) => {
        //  router.push({
        //    name: "GoodsDetail",
        //    query: {
        //      productId: e,
        //    },
        //  });
        //};

        //获取已报价商品数据
        const getFinishData = (checkedItem, itemData, state) => {
            proxy
                .$get("api/shopp/purchase/plan/queryPlanDetailGoodsShow", {
                    memberPlanCode: route.query.memberPlanCode,
                    state,
                    ...pageData
                })
                .then((res) => {
                    if (res.code == 200) {
                        if (activeName.value == 2) {
                            nextPageLoaded.value = false;
                            tableData.goodsList = res.data.records;
                            pageData.pageNum = res.data.pageNum;
                            pageData.pageSize = res.data.pageSize;
                            pageData.total = res.data.total;
                            pageData.totalPage = res.data.totalPage;
                            tableData.goodsList.forEach((item, index) => {
                                if (item.needSku !== null) {
                                    console.log(index, 89898);
                                    item.ischecked = false;
                                    //不等于空，就是更换最佳推荐
                                    if (ischeckedAll.value == true) {
                                        if (item.isChoose == 1) {
                                            item.ischecked = true;
                                            multipleSelection.value.push(item);
                                        }
                                    } else {
                                        if (checkedItem !== "") {
                                            checkedItem.forEach((i) => {
                                                if (i.ischecked == true) {
                                                    if (item.isChoose && item.memberNeedCode == i.memberNeedCode) {
                                                        item.ischecked = i.ischecked;
                                                        multipleSelection.value.push(item);
                                                    }
                                                }
                                            });
                                        } else {
                                            //multipleSelection.value == [];
                                            //ischeckedAll.value = false;
                                        }
                                    }

                                    if (item.isChoose) {
                                        isChoose.value = true;
                                    }
                                    item.needSku.goodsNum = Number(item.purchaseNum);
                                    item.goodsList = {
                                        data: [],
                                        pageNum: 1,
                                        pageSize: 5,
                                        total: 1,
                                        totalPage: 1
                                    };
                                    //计算总价
                                    if (multipleSelection.value) {
                                        let total = 0;
                                        for (const itemObj of multipleSelection.value) {
                                            const { goodsPrice, goodsNum } = itemObj.needSku;
                                            total +=
                                                Math.ceil(
                                                    (Number(goodsPrice) * 1000 * (Number(goodsNum) * 1000)) / 1000
                                                ) / 1000;
                                        }
                                        let numStr = total.toString();
                                        // 分割小数点前后的部分
                                        let parts = numStr.split(".");

                                        console.log(total);
                                        // 如果小数点后已经有两位，直接返回原数
                                        if (parts[1] && parts[1].length == 2) {
                                            console.log(988);
                                            AllMoney.value = total.toFixed(2);
                                        }
                                        // 如果小数点后只有一位，且第三位大于1，进位处理
                                        else if (parts[1] && parts[1].length == 3 && +parts[1][2] >= 1) {
                                            console.log(987);
                                            parts[1] = parts[1][0] + (parseFloat(parts[1][1]) + 1);
                                            AllMoney.value = parseFloat(parts.join(".")).toFixed(2);
                                        } else {
                                            AllMoney.value = total.toFixed(2);
                                        }
                                    }
                                    proxy
                                        .$get("api/shopp/purchase/plan/queryQuotePage", {
                                            memberNeedCode: item.memberNeedCode,
                                            pageNum: item.goodsList.pageNum,
                                            pageSize: item.goodsList.pageSize
                                        })
                                        .then((ress) => {
                                            if (ress.code == 200) {
                                                if (res.data.records) {
                                                    item.goodsList.data = ress.data.records;
                                                    item.goodsList.totalPage = ress.data.totalPage;
                                                    item.goodsList.pageNum = ress.data.pageNum;
                                                    item.goodsList.pageSize = ress.data.pageSize;
                                                    item.goodsList.total = ress.data.total;

                                                    //if (res.data.total <= 6) {
                                                    //  item.goodsList.total = Number(1);
                                                    //} else {
                                                    //  if (Number.isInteger(res.data.total / 6)) {
                                                    //    item.goodsList.total = Number(res.data.total / 6);
                                                    //  } else {
                                                    //    item.goodsList.total = Number(
                                                    //      Math.ceil(res.data.total / 6)
                                                    //    );
                                                    //    console.log(99999);
                                                    //  }
                                                    //}
                                                } else {
                                                    item.goodsList.data = [];
                                                }
                                            } else {
                                                ElMessage(ress.msg);
                                            }
                                        })
                                        .catch((ress) => {
                                            ElMessage(ress.msg);
                                        });
                                }
                            });
                            tableData.goodsList = [...itemData, ...tableData.goodsList];
                        } else {
                            nextPageLoaded.value = false;
                            closeData.goodsList = res.data.records;
                            pageData.pageNum = res.data.pageNum;
                            pageData.pageSize = res.data.pageSize;
                            pageData.total = res.data.total;
                            pageData.totalPage = res.data.totalPage;
                            closeData.goodsList.forEach((item) => {
                                item.ischecked = false;
                                //不等于空，就是更换最佳推荐
                                if (ischeckedAll.value == true) {
                                    if (item.isChoose == 1) {
                                        item.ischecked = true;
                                        multipleSelection.value.push(item);
                                    }
                                } else {
                                    if (checkedItem !== "") {
                                        checkedItem.forEach((i) => {
                                            if (i.ischecked == true) {
                                                if (item.isChoose && item.memberNeedCode == i.memberNeedCode) {
                                                    item.ischecked = i.ischecked;
                                                    multipleSelection.value.push(item);
                                                }
                                            }
                                        });
                                    } else {
                                        //multipleSelection.value == [];
                                        //ischeckedAll.value = false;
                                    }
                                }
                                if (item.isChoose) {
                                    isChoose.value = true;
                                }
                                if (item.needSku) {
                                    item.needSku.goodsNum = Number(item.purchaseNum);
                                }
                                item.goodsList = {
                                    data: [],
                                    pageNum: 1,
                                    pageSize: 5,
                                    total: 1,
                                    totalPage: 1
                                };
                                proxy
                                    .$get("api/shopp/purchase/plan/queryQuotePage", {
                                        memberNeedCode: item.memberNeedCode,
                                        pageNum: item.goodsList.pageNum,
                                        pageSize: item.goodsList.pageSize
                                    })
                                    .then((res) => {
                                        if (res.code == 200) {
                                            if (res.data.records) {
                                                item.goodsList.data = res.data.records;
                                                item.goodsList.totalPage = res.data.totalPage;
                                                item.goodsList.pageNum = res.data.pageNum;
                                                item.goodsList.pageSize = res.data.pageSize;
                                                item.goodsList.total = res.data.total;
                                            } else {
                                                item.goodsList.data = [];
                                            }
                                        } else {
                                            ElMessage(res.msg);
                                        }
                                    })
                                    .catch(() => {
                                        ElMessage(res.msg);
                                    });
                            });
                            closeData.goodsList = [...itemData, ...closeData.goodsList];
                        }
                    } else {
                        ElMessage(res.msg);
                    }
                })
                .catch(() => {
                    //异常处理
                    //ElMessage(res.msg);
                });
        };
        //商品视图滚动分页
        const handleScroll = (event) => {
            const { scrollTop, scrollHeight, clientHeight } = event.target;
            console.log(scrollTop, scrollHeight, clientHeight);
            if (activeName.value == 0) {
                //  let num=waitPageData.pageNum!=1?1:0
                if (scrollHeight - (scrollTop + clientHeight) < 20 && waitPageData.totalPage !== waitPageData.pageNum) {
                    if (!nextPageLoaded.value) {
                        // 将标记设置为已加载
                        nextPageLoaded.value = true;
                        // 当距离底部5px以内滚动时加载
                        waitPageData.pageNum = Number(waitPageData.pageNum) + 1;
                        let data = [...tableData.data];

                        debounce(getWaitData(data), 1000);
                    }
                }
            } else {
                if (scrollHeight - (scrollTop + clientHeight) < 20 && pageData.totalPage !== pageData.pageNum) {
                    if (!nextPageLoaded.value) {
                        // 将标记设置为已加载
                        nextPageLoaded.value = true;
                        // 当距离底部5px以内滚动时加载
                        //multipleSelection.value=[]
                        pageData.pageNum = Number(pageData.pageNum) + 1;
                        let data = [];
                        activeName.value == 2 ? (data = [...tableData.goodsList]) : (data = [...closeData.goodsList]);
                        debounce(getFinishData("", data, activeName.value), 1000);

                        //if(ischeckedAll.value=true){
                        //   tableData.goodsList.forEach((item) => {
                        //  if(item.isChoose==true){
                        //      item.ischecked ==true
                        //      ischeckedAll.value = true
                        //      multipleSelection.value.push(item)
                        //  }

                        //});
                        //}

                        if (multipleSelection.value) {
                            let total = 0;

                            for (const item of multipleSelection.value) {
                                const { goodsPrice, goodsNum } = item.needSku;
                                total +=
                                    Math.ceil((Number(goodsPrice) * 1000 * (Number(goodsNum) * 1000)) / 1000) / 1000;
                            }

                            let numStr = total.toString();
                            // 分割小数点前后的部分
                            let parts = numStr.split(".");

                            console.log(total);
                            // 如果小数点后已经有两位，直接返回原数
                            if (parts[1] && parts[1].length == 2) {
                                console.log(988);
                                AllMoney.value = total.toFixed(2);
                            }
                            // 如果小数点后只有一位，且第三位大于1，进位处理
                            else if (parts[1] && parts[1].length == 3 && +parts[1][2] >= 1) {
                                console.log(987);
                                parts[1] = parts[1][0] + (parseFloat(parts[1][1]) + 1);
                                AllMoney.value = parseFloat(parts.join(".")).toFixed(2);
                            } else {
                                AllMoney.value = total.toFixed(2);
                            }
                        }
                    }
                }
            }
        };

        const debounce = (fn, delay) => {
            let timeout;
            return function () {
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    fn.apply(this, arguments);
                }, delay);
            };
        };
        //tab数量
        const getTabsCount = () => {
            proxy
                .$get("api/shopp/purchase/plan/detailCount", {
                    memberPlanCode: route.query.memberPlanCode
                })
                .then((res) => {
                    if (res.code == 200) {
                        notQuoteCount.value = res.data.notQuote;
                        alreadyQuoteCount.value = res.data.alreadyQuote;
                        closeQuoteCount.value = res.data.closeQuote;
                    } else {
                        ElMessage(res.msg);
                    }
                })
                .catch((res) => {
                    //ElMessage(res.msg);
                });
        };

        //备注中查看图片
        const previewImg = (imgs) => {
            srcList.value = imgs.split(",");
            descImgVisible.value = true;
        };

        const changeGoods2 = (type, memberNeedCode, skuCode, item, goodsItem, val) => {
            SupplierPageData.pageNum = 1;
            multipleSelection2.data = [];
            proxy
                .$get("api/shopp/purchase/plan/editRec", {
                    memberNeedCode: memberNeedCode,
                    skuCode: skuCode
                })
                .then((res) => {
                    if (res.code == 200) {
                        getSupplierViewData([]);
                    }
                });
        };

        const otherGoodsList2 = (pageNum, memberNeedCode, item) => {
            if (item.goodsList.data1) {
                item.goodsList.data = item.goodsList.data1;
                item.goodsList.pageNum = pageNum;
                item.goodsList.pageSize = 5;

                item.goodsList.totalPage =
                    item.goodsList.data1.length % 5 === 0
                        ? item.goodsList.data1.length / 5
                        : Math.floor(item.goodsList.data1.length / 5 + 1);

                if (pageNum != 1) {
                    item.goodsList.data = item.goodsList.data.slice((pageNum - 1) * 5, pageNum * 5);
                } else {
                    item.goodsList.data = item.goodsList.data.slice(0, 5);
                }
                item.goodsList.total = item.goodsList.data1.length;
            } else {
                item.goodsList.data = [];
            }
        };
        const selectAll = (item) => {
            console.log(item);
            item.ischeckedAll = false;
            item.isCancel = true;
            item.goodsList.data1.forEach((it) => {
                for (let i = 0; i < SupplierViewData.goodsList.length; i++) {
                    for (let j = 0; j < SupplierViewData.goodsList[i].goodsList.data1.length; j++) {
                        if (SupplierViewData.goodsList[i].goodsList.data1[j]?.memberNeedCode == it.memberNeedCode) {
                            SupplierViewData.goodsList[i].goodsList.data1[j].select = true;
                            SupplierViewData.goodsList[i].goodsList.data1[j].checked = false;

                            SupplierViewData.goodsList[i].goodsList.data = SupplierViewData.goodsList[
                                i
                            ].goodsList.data1.slice(
                                (SupplierViewData.goodsList[i].goodsList.pageNum - 1) * 5,
                                SupplierViewData.goodsList[i].goodsList.pageNum * 5
                            );
                        }
                    }
                }
                it.checked = true;
                it.select = false;
            });

            supplierMoney();
        };
        //供应商视图滚动条分页
        const handleScroll2 = (event) => {
            const { scrollTop, scrollHeight, clientHeight } = event.target;
            console.log(scrollTop, scrollHeight, clientHeight);
            if (
                scrollHeight - (scrollTop + clientHeight) < 50 &&
                SupplierPageData.totalPage !== SupplierPageData.pageNum
            ) {
                if (!nextPageLoaded.value) {
                    console.log(4545);

                    // 将标记设置为已加载
                    nextPageLoaded.value = true;
                    // 当距离底部5px以内滚动时加载
                    //multipleSelection.value=[]
                    SupplierPageData.pageNum = Number(SupplierPageData.pageNum) + 1;

                    getSupplierViewData(SupplierViewData.goodsList);
                }
            }
        };
        //供应商视图分页
        const handleCarouselChange = (type, pageNum, memberNeedCode, item, index) => {
            //'next',item.goodsList.pageNum, item.memberNeedCode, item,index
            console.log(carousel.value[0].next);
            currentSlide.value = CarouselChange;
            console.log(index);
            if (type == "next") {
                if (item.goodsList.totalPage != 1 && pageNum !== item.goodsList.totalPage) {
                    console.log(carousel.value);

                    otherGoodsList2(pageNum + 1, memberNeedCode, item);
                } else if (item.goodsList.totalPage != 1 && pageNum == item.goodsList.totalPage) {
                    //console.log(333);
                    otherGoodsList2(1, memberNeedCode, item);
                }
                carousel.value[index].data.activeIndex + 1;
            } else {
                if (pageNum == 1) {
                    otherGoodsList2(item.goodsList.totalPage, memberNeedCode, item);
                } else {
                    otherGoodsList2(pageNum - 1, memberNeedCode, item);
                }
                carousel.value[index].prev();
            }
        };
        const supplierMoney = () => {
            let total = 0;
            for (const items of multipleSelection2.data) {
                if (items.sellingPrice != "***") {
                    total +=
                        Math.ceil((Number(items.sellingPrice) * 1000 * (Number(items.purchaseNum2) * 1000)) / 1000) /
                        1000;
                } else {
                    AllMoney2.value = 0;
                }
            }

            let numStr = total.toString();
            // 分割小数点前后的部分
            let parts = numStr.split(".");

            console.log(total);
            // 如果小数点后已经有两位，直接返回原数
            if (parts[1] && parts[1].length == 2) {
                console.log(988);
                AllMoney2.value = total.toFixed(2);
            }
            // 如果小数点后只有一位，且第三位大于1，进位处理
            else if (parts[1] && parts[1].length == 3 && +parts[1][2] >= 1) {
                //console.log((parts[1][0] + (parseFloat(parts[1][1])+2)),888);
                parts[1] = parts[1][0] + (parseFloat(parts[1][1]) + 1);
                AllMoney2.value = parseFloat(parts.join(".")).toFixed(2);
            } else {
                AllMoney2.value = total.toFixed(2);
            }
            //AllMoney2.value = (parseFloat(total.toString())).toFixed(2)
        };

        const PurchaseQuantityChange = (skuCode, value, type, goodsItem, e) => {
            let str = /^([1-9]\d*|0)(?:\.\d{0,4}[1-9])?$/;

            if (type == "add") {
                //if (goodsItem.purchaseNum2 >= goodsItem.purchaseNum) {
                //  goodsItem.purchaseNum2 = goodsItem.purchaseNum;
                //} else {
                goodsItem.purchaseNum2++;
                //}
            } else if (type == "edit") {
                if (goodsItem.purchaseNum2 >= goodsItem.purchaseNum) {
                    goodsItem.purchaseNum2 = goodsItem.purchaseNum;
                } else if (str.test(e.currentTarget.value)) {
                    console.log(e.currentTarget.value);
                    let num = String(e.currentTarget.value).replace(/^(.*\..{4}).*$/, "$1");
                    goodsItem.purchaseNum2 = Number(num);
                } else {
                    goodsItem.purchaseNum2 = e.currentTarget.value;
                    if (goodsItem.purchaseNum2 <= goodsItem.minNum) {
                        goodsItem.purchaseNum2 = goodsItem.minNum;
                    } else {
                    }
                }
                //item.purchaseNum2 = Number(value.toFixed(4))
            } else if (type == "reduce") {
                if (goodsItem.purchaseNum2 > 1 && goodsItem.purchaseNum2 > goodsItem.minNum) {
                    goodsItem.purchaseNum2--;
                } else {
                    goodsItem.purchaseNum2 = goodsItem.minNum;
                }
            }
            //item.purchaseNum2 = Number(value.toFixed(4))

            let num = String(goodsItem.purchaseNum2).replace(/^(.*\..{4}).*$/, "$1");
            goodsItem.purchaseNum2 = Number(num);
            multipleSelection2.data.map((item) => {
                if (item.skuCode == skuCode) {
                    item.purchaseNum2 = goodsItem.purchaseNum2;
                }
            });
            supplierMoney();
        };

        const validateInput = (value) => {
            console.log(899);
            const regex = /^(-?\d+)(\.\d{4})?$/;
            if (!regex.test(value)) {
                value = value.substring(0, value.length - 1);
            }
        };
        const CarouselChange = (type, pageNum, memberNeedCode, item, index) => {
            console.log(carousel.value[0]);
            carousel.value[0].next();
            handleCarouselChange(type, pageNum, memberNeedCode, item, index);
        };

        const createOrders = (orderItem, goodsItem, type) => {
            //createOrderParam.purchasePlanCode=orderItem.purchasePlanCode
            //createOrderParam.orderRemark=orderItem.orderRemark
            createOrderParam.extMemberOrderDetailList[0].id = goodsItem.id;
            createOrderParam.extMemberOrderDetailList[0].memberNeedCode = goodsItem.memberNeedCode;
            MemberPurchaseGoodsParam.supplierUserId = goodsItem.supplierUserId;
            MemberPurchaseGoodsParam.memberNeedCode = goodsItem.memberNeedCode;
            if (type == 0) {
                dialogTableVisible.value = true;
                getqueryMemberPurchaseGoodsList();
            } else {
                createOrderParam.extMemberOrderDetailList[0].supplierUserId = goodsItem.supplierUserId;

                SecondaryConfirmationVisible.value = true;
            }
        };
        const getqueryMemberPurchaseGoodsList = () => {
            console.log(MemberPurchaseGoodsParam);
            proxy
                .$get("api/shopp/memberPurchasePlan/queryMemberPurchaseGoodsPage", MemberPurchaseGoodsParam, "json")
                .then((res) => {
                    if (res.code == 200) {
                        supplierQuotationList.data = res.data.records;

                        //      MemberPurchaseGoodsParam.pageNum = res.data.pageNum;
                        // MemberPurchaseGoodsParam.pageSize = res.data.pageSize;
                        //  MemberPurchaseGoodsParam.total = res.data.total;
                    }
                });
        };
        const handleSizeChanges = (val) => {
            mySupplierParam.pageSize = val;
            mySupplier(mySupplierParam);
            console.log(`${val} items per page`);
        };
        const handleCurrentChanges = (val) => {
            mySupplierParam.pageNum = val;

            mySupplier(mySupplierParam);
        };
        const toOrder = () => {
            //createOrderParam.extMemberOrderDetailList[0].id = item.id;
            //createOrderParam.extMemberOrderDetailList[0].supplierUserId =
            //  item.supplierUserId;
            //ElMessageBox.confirm("是否确认下单？", L["提示"], {
            //  confirmButtonText: L["确定"],
            //  cancelButtonText: L["取消"],
            //  type: "warning",
            //  //center: true,
            //})
            //  .then(() => {
            proxy
                .$post("api/shopp/memberPurchasePlan/insertExtMemberOrder", createOrderParam, "json")
                .then((res) => {
                    if (res.code == 200) {
                        ElMessage.success("操作成功");

                        dialogTableVisible.value = false;
                        SecondaryConfirmationVisible.value = false;
                        getDetailGoodsData();
                        getFinishData("", [], activeName.value);
                        router.push("/member/order/supplierOrders");
                    } else {
                        ElMessage(res.msg);
                    }
                })
                .catch(() => {
                    //异常处理
                });
            //})
            //.catch(() => {});
        };
        const getmySupplierList = (memberNeedCode, list, type) => {
            isAll.value = type;
            mySupplier(mySupplierParam);

            setTimeout(() => {
                if (memberNeedCode !== null) {
                    SupplietParam.memberNeedCode = [memberNeedCode];
                } else {
                    list.forEach((item) => {
                        console.log(item.memberNeedCode, 99999);
                        SupplietParam.memberNeedCode.push(item.memberNeedCode);
                    });

                    SupplietParam.memberNeedCode = [...new Set(SupplietParam.memberNeedCode)];
                }
                SupplierVisible.value = true;
            }, 700);
        };
        const mySupplier = (Param) => {
            proxy.$get("api/shopp/supplier/querySupplier", Param).then((res) => {
                if (res.code == 200) {
                    console.log(res.data.records);

                    mySupplierList.data = res.data.records;

                    mySupplierParam.pageNum = res.data.pageNum;
                    mySupplierParam.pageSize = res.data.pageSize;
                    mySupplierParam.total = res.data.total;
                    return mySupplierParam;
                }
            });
        };
        const toSupplierHomePage = (id, name) => {
            console.log(id, name);
            router.push({
                path: "/goods/list",
                query: {
                    supplierId: id,
                    supplierUserName: name
                }
            });
        };
        const handleSelectionChange = (val) => {
            console.log(val);
            val.forEach((item) => {
                SupplietParam.supplierIdList.push(item.id);
            });
            SupplietParam.supplierIdList = [...new Set(SupplietParam.supplierIdList)];
            console.log(SupplietParam.supplierIdList, 2322);
        };
        const addSupplier = () => {
            router.push("/member/mySupplier/addSupplier");
        };
        const inviteQuote = (list) => {
            if (list.length !== 0) {
                if (isAll.value == 2) {
                    proxy
                        .$post(
                            "api/shopp/memberPurchasePlan/inviteMySupplier",
                            {
                                memberNeedCodeList: SupplietParam.memberNeedCode,
                                supplierIdList: list
                            },
                            "json"
                        )
                        .then((res) => {
                            if (res.code == 200) {
                                ElMessage({
                                    message: "操作成功",
                                    type: "success"
                                });
                                //SupplierVisible.value = false;
                                //SupplietParam.memberNeedCode = [];
                                SupplietParam.supplierIdList = [];
                                mySupplierParam.pageNum = 1;
                                mySupplierParam.pageSize = 10;
                            } else {
                                ElMessage.error(res.msg);
                            }
                        });
                } else if (isAll.value == 1) {
                    let supplierIdList = list.map((item, index) => item);
                    console.log(supplierIdList, 4545);
                    let arrParmas = {
                        memberPlanCode: topTable.data.memberPlanCode,
                        state: activeName.value,
                        supplierIdList: supplierIdList.join(",")
                    };
                    //arrParmas.supplierIdList=list
                    proxy.$post("api/shopp/memberPurchasePlan/inviteMySupplierInAllNeed", arrParmas).then((res) => {
                        if (res.code == 200) {
                            ElMessage({
                                message: "操作成功",
                                type: "success"
                            });
                            //SupplierVisible.value = false;
                            //SupplietParam.memberNeedCode = [];
                            SupplietParam.supplierIdList = [];
                            mySupplierParam.pageNum = 1;
                            mySupplierParam.pageSize = 10;
                        } else {
                            ElMessage.error(res.msg);
                        }
                    });
                }
            } else {
                ElMessage({
                    message: "请选择供应商",
                    type: "warning"
                });
            }
        };
        // const inviteQuoteAll = (list) => {
        //    if (list.length !== 0) {
        //        proxy
        //            .$post(
        //                "api/shopp/memberPurchasePlan/inviteMySupplier",
        //                {
        //                    memberNeedCodeList: SupplietParam.memberNeedCode,
        //                    supplierIdList: list
        //                },
        //                "json"
        //            )
        //            .then((res) => {
        //                if (res.code == 200) {
        //                    ElMessage({
        //                        message: "操作成功",
        //                        type: "success"
        //                    });
        //                    //SupplierVisible.value = false;
        //                    //SupplietParam.memberNeedCode = [];
        //                    SupplietParam.supplierIdList = [];
        //                    mySupplierParam.pageNum = 1;
        //                    mySupplierParam.pageSize = 10;
        //                } else {
        //                    ElMessage.error(res.msg);
        //                }
        //            });
        //    } else {
        //        ElMessage({
        //            message: "请选择供应商",
        //            type: "warning"
        //        });
        //    }
        //};
        const SecondaryConfirmationDialog = (item) => {
            SecondaryConfirmationVisible.value = true;
            createOrderParam.extMemberOrderDetailList[0].id = item.id;
            createOrderParam.extMemberOrderDetailList[0].supplierUserId = item.supplierUserId;
        };
        const resetForms = (formEl) => {
            if (!formEl) return;
            formEl.resetFields();
            mySupplierParam.pageNum = 1;
            mySupplierParam.time = "";
            mySupplierParam.startDate = "";
            mySupplierParam.endDate = "";
            mySupplier(mySupplierParam);
        };
        const viewRemark = (item) => {
            proxy
                .$get("api/shopp/purchase/plan/queryChooseCause", {
                    memberNeedCode: item.memberNeedCode
                })
                .then((res) => {
                    if (res.code == 200) {
                        remarkParam.goodsName = res.data.goodsName;
                        remarkParam.chooseCause = res.data.chooseCause;
                        remarkParam.supplierName = res.data.supplierName;
                        //remarkType.value = 2;
                        remarkVisible.value = true;
                    } else {
                        ElMessage(res.msg);
                    }
                })
                .catch(() => {});
        };
        onMounted(() => {
            if (route.query.orderState) {
                current_state.value = route.query.orderState;
            } else {
                current_state.value = "";
            }
            if (route.query.evaluateState) {
                evaluate_state.value = route.query.evaluateState;
            } else {
                evaluate_state.value = "";
            }
            //  getTime();
            getDetailData(); //获取头部信息
            getDetailGoodsData(); //获取列表数据
            getPayWays(); //** 查询会员拥有的支付方式和付款方式 */
            getGeneralManager(); //获取是否是总经理角色
            getTabsCount(); //获取tabs数量
            //getSupplierViewData();
            //toggleSelection()
            //  getCancelList();
            //  getAddressList();
        });

        return {
            L,
            param,
            tableData,
            closeData,
            fits,
            multipleTableRef,
            multipleSelection,
            //handleSelectionChange,
            nextPageLoaded,
            AllMoney,
            srcList,
            descImgVisible,
            previewImg,
            goback,
            closeOrder,
            handleEdit,
            handleCloseItem,
            platform,
            createOrder,
            addressVisibleFlag,
            addAddress,
            refreshAddress,
            close,
            type,
            show_select_address,
            confirmChangeAddress,
            selectAddress,
            changeAddress,
            handlePayment,
            payData,
            orderBelongsVal,
            selectDate,
            activeIndex,
            paymentList,
            not_select,
            selected,
            invoice_selected,
            current_address_index,
            changeAddress,
            address_list,
            getAddressList,
            getDetailData,

            goGoodsDetail,
            selectPaymentMethod,
            initPayment,
            //toggleSelection
            dialogVisible,
            getTabsCount,
            validateNumber,
            handleClick,
            rules,
            activeName,
            topTable,
            checkedItem,
            checkedAll,
            ischeckedAll,
            changeGoosList,
            multipleSelection,
            changeGoods,
            handleChange,
            toQuotedPriceDetail,
            route,
            getGeneralManager,
            generalManager,
            isChoose,
            visible,
            msgList,
            pushOffer,

            pageData,
            debounce,
            handleScroll,
            notQuoteCount,
            alreadyQuoteCount,
            closeQuoteCount,
            loading,
            isLook,
            CheckrdImg,
            ellipse,
            SwitchView,
            SupplierViewData,
            multipleSelection2,
            AllMoney2,
            getSupplierViewData,
            handleCarouselChange,
            otherGoodsList2,
            changeGoods2,
            carousel,
            //prevNeedCode,
            //prevSupplierUserId,
            //prevSkuCode,
            selectAll,
            //props,
            handleScroll2,
            //CancelAll,
            PurchaseQuantityChange,
            validateInput,
            handleChange2,
            CarouselChange,
            store,
            createOrders,
            dialogTableVisible,
            getqueryMemberPurchaseGoodsList,
            supplierQuotationList,
            MemberPurchaseGoodsParam,
            toOrder,
            getmySupplierList,
            mySupplier,
            mySupplierParam,
            mySupplierList,
            SupplietParam,
            SupplierVisible,
            addSupplier,
            handleSelectionChange,
            toSupplierHomePage,
            inviteQuote,
            SecondaryConfirmationVisible,
            SecondaryConfirmationDialog,
            onSubmits,
            handleSizeChanges,
            handleCurrentChanges,
            resetForms,
            ruleFormRef,
            viewRemark,
            remarkVisible,
            remarkParam,
            isAll

            // currentSlide
        };
    }
};
</script>

<style lang="scss" scoped>
@import "../../../style/procuremmentPlanDetail.scss";

.el-dialog .el-dialog__body .desc-image__preview {
    .el-image {
        width: 148px;
        height: 148px;
    }
    .active {
        margin-left: 10px;
    }
}
.desc-clss {
    white-space: nowrap; /* 确保文本在一行内显示 */
    overflow: hidden; /* 隐藏溢出容器的文本 */
    text-overflow: ellipsis; /* 使用省略号表示文本溢出 */
    max-width: 100px; /* 设置最大宽度 */
}
.imgBtn {
    color: $colorMain;
    cursor: pointer;
    margin-left: 4px;
}
.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #0e3177 !important;
    border-color: #0e3177 !important;
}
.el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #0e3177 !important;
    border-color: #0e3177 !important;
}
.sld_member_main_content[data-v-61d93b18] {
    background: #f8f8f8;
}
.sld_order_list .container {
    /*width: 1207px;*/
}
.sld_order_list {
    /*width: 1210px;*/
    width: 100%;
    background: #fff;
}
.el-pager li.active {
    color: $colorMain;
    cursor: default;
}

.el-pager li:hover {
    color: $colorMain;
}

.el-select-dropdown__item.selected {
    color: $colorMain;
}

.sld_order_list {
    color: #000;
    .sld_List {
        color: #000;
    }
    /*.el-dialog__header {
    background-color: $colorMain;
    padding: 11px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .el-dialog__title {
      color: #fff;
      font-size: 14px;
    }
    .el-dialog__headerbtn {
      top: auto;
    }
    .el-dialog__headerbtn .el-dialog__close {
      color: #fff !important;
      font-size: 18px;
    }
  }
  .el-dialog {
    overflow: hidden;
    border-radius: 4px;
  }
  .el-dialog__footer {
    display: flex;
    // justify-content: center;
    align-items: center;
  }*/
    /*.aft_btn:hover {
    background-color: rgba($color: #f30213, $alpha: 0.2);
    color: #f30213;
  }*/
    .aft_btn:focus {
        background-color: #fff;
        color: #333333;
        border-color: #dcdfe6;
    }
    .dia_btn {
        .el-button {
            width: 134px;
            height: 36px;
        }
    }
    .downLoad_contract {
        width: 150px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border: 1px solid #e8e8f1;
        border-radius: 5px;
        cursor: pointer;
    }
    .downLoad_contract:hover {
        border: 1px solid $colorMain;
    }
}
.container {
    .bootom-footer {
        text-align: right;
    }
    .bootom-footer button:first-child {
        margin-right: 10px;
    }
    // .el-input {
    //   width: 300px;
    // }
    .unit {
        font-size: 18px;
        margin-left: 10px;
    }
}

.result {
    height: 100%;
    margin-top: 30px;
    text-align: center;
    padding: 0px 20px 50px 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 16px;
    margin-bottom: 40px;
}
.child {
    width: 100%;
    display: flex;
    align-content: center;
    .goodsMassage {
        margin-left: 10px;
        display: flex;
        justify-content: space-between;
        width: 92%;
        align-items: center;
        .operationContent {
            width: 100%;
            line-height: 2;
            .goodsContent {
                display: flex;
                p {
                    width: 30%;
                    line-height: 2.5;
                }
            }
        }
    }
    .goodsMassages {
        margin-left: 10px;
        display: flex;
        justify-content: space-between;
        width: 90%;
        /*align-items: ;*/
        .operationContent {
            width: 100%;
            line-height: 2;
            .goodsContent {
                display: flex;
                p {
                    width: 30%;
                    line-height: 2.5;
                }
            }
        }
    }
}
.showContent {
    margin-top: 20px;
    padding: 10px;
    background: rgb(250, 247, 247);
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
}
.receive_info {
    border: 1px solid #dddddd;
    border-radius: 2px;
    margin-bottom: 30px;
    .pre_message_title,
    .receive_info_title {
        height: 40px;
        line-height: 40px;
        font-size: 12px;
        padding-left: 20px;
        background-color: #f8f8f8;
        color: #666666;
    }
    .receive_info_content {
        height: 159px;
        padding: 0 40px;
    }

    .pre_message_info {
        padding: 10px 40px;
        .tag_pre {
            display: inline-block;
            line-height: 25px;
            text-align: right;
            font-weight: 400;
            margin-right: 10px;
            font-size: 14px;
            color: #333333;
            display: flex;
            min-width: 100px;
            max-width: 200px;
            justify-content: flex-end;
        }

        strong {
            font-size: 14px;
            color: $colorMain;
            margin-right: 3px;
        }
    }
    .content_left {
        width: 785px;
        /*border-right: 1px solid #dddddd;*/
        .member {
            font-size: 14px;
        }
        .address_detail {
            width: 700px;
        }
        span:not(:first-child) {
            font-size: 14px;
            line-height: 14px;
            margin-top: 22px;
        }
    }
    .content_right {
        width: 370px;
        font-size: 14px;
        border-left: 1px solid #dddddd;
        padding-left: 10px;
        .replace_address {
            color: $colorMain2;
            line-height: 14px;
        }
    }
    .add_address {
        width: 100px;
        height: 30px;
        background-color: $colorMain;
        color: white;
        line-height: 30px;
        text-align: center;
        margin-top: 29px;
        border-radius: 3px;
    }
    .nomartop {
        margin-top: 0;
    }
}
.select_address_width {
    width: 500px !important;
}
.overflow_ellipsis_clamp2 {
    width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    word-break: break-all;

    line-height: 19px;
}
.out_stock_dialog {
    width: 460px;
    margin: 0 auto;
    height: 330px;
    overflow-y: scroll;
    .good_item {
        font-size: 14px;

        img {
            width: 80px;
            height: 80px;
        }
        .good_info {
            margin-left: 10px;
        }
        .good_name {
            width: 320px;
            color: #333333;
            line-height: 14px;
            margin-top: 10px;
            display: inline-block;
        }
        .spec_num {
            margin-top: 26px;
            .good_spec {
                color: #999999;
            }
            .good_num {
                float: right;
                color: #333333;
            }
        }
    }
}
.address_con {
    height: 330px;
    overflow-y: scroll;
    scrollbar-width: none !important;
    -ms-overflow-style: none !important;
    .address_item {
        &:not(:first-child) {
            margin-top: 20px;
        }
        width: 458px;
        //    height: 104px;
        box-sizing: border-box;
        border: 1px solid #dfdfdf;
        position: relative;
        padding: 20px;
        span,
        div:not(:first-child) {
            margin-top: 12px;
        }
        .address_text {
            display: flex;
            width: 400px;
        }
        .selected {
            width: 14px;
            height: 14px;
            position: absolute;
            right: 0;
            bottom: 0;
        }
    }
    .select {
        border: 1px solid $colorMain2;
    }
}
.red {
    background-color: $colorMain;
    color: white;
}
.remove {
    width: 120px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 3px;
    background-color: $colorMain;
    margin-left: 10px;
    color: white;
}
.replace_address {
    color: $colorMain2;
    line-height: 14px;
}
.Submit {
    background: #0e3177;
    width: 98px;
    height: 40px;
    cursor: pointer;
    border-radius: 4px;
    text-align: center;
    color: #fff;
    margin-left: 10px;
    line-height: 40px;
    font-size: 14px;
}
.tabs {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    color: #fff;
    background: #fff;
    border: 1px solid #cfcfcfff;
    margin-right: 10px;
    font-size: 14px;
}
.btnActive {
    background: #0e3177;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    color: #fff;
    margin-right: 10px;
    font-size: 14px;
}
.my-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e3e2e2;
    padding: 13px 20px 0px 15px;
    background-color: $colorMain;
    color: white;
    padding-bottom: 10px;
}
/*::v-deep .el-dialog__header {
  padding: 0px;
  padding-bottom: 0px;
}*/
.modalTitle {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    img {
        width: 27px;
        height: 24px;
        /*margin-right: 5px;*/
    }
}
/*::v-deep .el-dialog__body {
  padding-top: 0px;
  padding-bottom: 0px !important;
  padding-left: 0px;
  padding-right: 0px;
  /*margin:13px 10px 30px 10px;*/
/*}*/
.Clear {
    width: 76px;
    height: 35px;
    border-radius: 4px;
    text-align: center;
    color: #5e5858;
    line-height: 35px;
    font-size: 14px;
    border: 1px solid #ccc;
    cursor: pointer;
}
.Submit2 {
    background: #0e3177;
    width: 76px;
    height: 35px;
    border-radius: 4px;
    text-align: center;
    color: #fff;
    margin-left: 10px;
    line-height: 35px;
    font-size: 14px;
    cursor: pointer;
}
.Submit3 {
    background: #d0d7e6;
    width: 76px;
    height: 35px;
    border-radius: 4px;
    text-align: center;
    color: #fff;
    margin-left: 10px;
    line-height: 35px;
    font-size: 14px;
    cursor: pointer;
}
.invoice_cons {
    display: flex;
    justify-content: center;
    height: 50px;
    .activeLt {
        margin-left: 20px;
    }
    .no_select_invoice {
        cursor: pointer;
        img {
            width: 18px;
            height: 18px;
        }
        span {
            font-size: 14px;
            line-height: 14px;
            margin-left: 10px;
        }
        .invoice_info {
            margin-left: 50px;
            border-left: 1px solid #dddddd;
            span {
                font-size: 14px;
                line-height: 14px;
                margin-left: 50px;
            }
            .choose {
                color: #257bfd;
                cursor: pointer;
            }
        }
        .block {
            margin-left: 10px;
            ::v-deep .el-date-editor.el-input {
                width: 160px;
            }
            .el-input__inner {
                padding-left: 40px;
            }
        }
        .date_box {
            margin-left: 10px;
            ::v-deep .el-date-editor.el-input {
                width: 160px;
            }
        }
    }
}
/*.mySupplierQuote {
  .el-dialog__header {
    background-color: #ccc;
  }
}*/
.mySupplierName {
    background-color: #faf7f2;
    color: #e99f27;
    border: 1px solid #e8c48b;
    border-radius: 2px;
    font-size: 10px;
    width: 15px;
    height: 16px;
    margin-right: 3px;
    line-height: 16px;
    cursor: pointer;
    display: inline-block;
    text-align: center;
}
.searchBtn2 {
    cursor: pointer;
    display: flex;
    /*width: 200px;*/
    position: absolute;
    top: 3%;
    right: 0%;
    .search {
        width: 56px;
        height: 32px;
        line-height: 32px;
        background: #0e3177;
        cursor: pointer;
        text-align: center;
        border-radius: 3px;
        color: #fff;
        margin-right: 10px;
    }
}
.search2 {
    width: 100px;
    height: 32px;
    line-height: 32px;
    background: #0e3177;
    cursor: pointer;
    text-align: center;
    border-radius: 3px;
    color: #fff;
    margin-right: 10px;
}
.search3 {
    width: 100px;
    height: 32px;
    line-height: 32px;
    background: #fff;
    border: 1px solid #0e3177;
    cursor: pointer;
    text-align: center;
    border-radius: 3px;
    color: #0e3177;
    margin-right: 10px;
}
::v-deep.el-message-box__header {
    border-bottom: 1px solid #e5e7ec;
}
</style>
<style lang="scss">
.payModalStylehl {
    .el-dialog__body {
        padding: 20px 0 16px;
    }
}
</style>
